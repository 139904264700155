import gql from 'graphql-tag';
import { useState } from 'react';
import client from '../service/getAccessTokenFromRefreshToken';
import ProfileService from '../service/ProfileService';
import User from '../service/User';
import { ACTIVE_CIRCLE_LOADER_KEY, FIREBASE_EVENT_NAMES } from '../utils/constants';
import FirebaseUtils from '../utils/FirebaseUtils';
import {
  calculateAgeFromDOB,
  getUserName,
  removeComponentLink,
  setLastActiveTime,
} from '../utils/helper';
import { logUserEvent } from '../utils/UserEvent';

const PROFILE_QUERY = gql`
  query {
    fetchProfileDetails {
      basicProfileDetails {
        firstName
        lastName
        userId
        preferredName
        dob
        gender
        bloodGroup
        avatarUrl
      }
      contactProfileDetails {
        addressList {
          city
          state
          zipCode
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

export const GET_ACCESS_TOKEN_WITH_REFRESH_TOKEN = gql`
  query getRefreshTokenForConsumer {
    getRefreshTokenForConsumer {
      access_token
      refresh_token
      statusCode
      expires_in
      errorMessage
      userId
      phoneNumber
      refresh_expires_in
      scope
      session_state
      token_type
    }
  }
`;
export const GET_ONE_TIME_ACCESS_TOKEN_WITH_REFRESH_TOKEN = gql`
  query getOneTimeAccessRefreshToken($realm: String) {
    getOneTimeAccessRefreshToken(realm: $realm) {
      access_token
      refresh_token
      statusCode
      expires_in
      errorMessage
      userId
      phoneNumber
      refresh_expires_in
      scope
      session_state
      token_type
    }
  }
`;
export default class Auth {
  accessToken;
  //idToken is refreshToken
  idToken;
  expiresAt;
  name;
  sub;

  constructor() {
    this.scheduleRenewal();
  }

  getAccessToken = () => {
    if (!this.accessToken) {
      this.accessToken = localStorage.getItem('access_token');
    }
    return this.accessToken;
  };

  getIdToken = () => {
    if (!this.idToken) {
      this.idToken = localStorage.getItem('refresh_token');
    }
    return this.idToken;
  };

  getExpireIn = () => {
    return this.expiresAt;
  };

  getName = () => {
    return this.name;
  };

  setReferralPromoCode = (referralPromoCode) => {
    localStorage.setItem('referralPromoCode', JSON.stringify(referralPromoCode));
  };

  getReferralPromoCode = () => {
    return JSON.parse(localStorage.getItem('referralPromoCode')) || null;
  };
  socialLogin = (componentLink = 'home', clientId = process.env.SMILE_CLIENT_ID, screenType) => {
    const smileIdpRoot = process.env.SMILE_IDP_ROOT + '/auth';
    const responseType = 'id_token%20token';
    const scope = process.env.SMILE_SCOPE;
    let callbackUri;
    const DEMO_CALLBACK_URL = "https://demo.mpoweredhealth.com/callback";
    if (window.location.hostname.startsWith('demo.mpowered')) {
      callbackUri = DEMO_CALLBACK_URL;
    } else {
      callbackUri = process.env.SMILE_CALLBACK_URL;
    }
    const authorizeUrl =
      smileIdpRoot +
      '?response_type=' +
      responseType +
      '&scope=' +
      scope +
      '&client_id=' +
      clientId +
      '&nonce=8bb7d889-74cd-49c6-9e40-e3f7fc6131a6' +
      '&redirect_uri=' +
      callbackUri +
      '?link=' +
      componentLink;
    this.clearStorage();
    localStorage.setItem('socialSignupFrom', screenType);
    localStorage.setItem('isSocialLogin', clientId);
    window.location.href = authorizeUrl;
  };
  login = () => {
    let authorizeUrl;
    const DEMO_CALLBACK_URL = "https://demo.mpoweredhealth.com/callback"
    if (window.location.hostname.startsWith('demo.mpowered')) {
      authorizeUrl = removeComponentLink(DEMO_CALLBACK_URL) + 'login';
      } else  {
        authorizeUrl = removeComponentLink(process.env.SMILE_CALLBACK_URL) + 'login';
      }

    window.location.href = authorizeUrl;
  };
  loginWithMpoweredUI = async (username = '', password = '') => {
    this.clearStorage();
    let result = {};
    if (username && password) {
      try {
        const reqBody = {
          username,
          password,
          grant_type: 'password',
          client_id: process.env.SMILE_CLIENT_ID,
        };

        result = await User.loginWithCredentials(reqBody);
        const authResult = {
          access_token: result.data.userLogin.access_token,
          id_token: result.data.userLogin.refresh_token,
          expires_in: result.data.userLogin.expires_in,
        };
        logUserEvent(FIREBASE_EVENT_NAMES.LOGIN_PASSED);
        setLastActiveTime();
        this.setSmartechIdentity(authResult); //Setting up primary key for the smartech panel
        localStorage.setItem('access_token', authResult.access_token);
        localStorage.setItem('refresh_token', authResult.id_token);

        const expiresAt = authResult.expires_in * 1000 + new Date().getTime();
        this.accessToken = authResult.access_token;
        this.idToken = authResult.refresh_token;
        this.expiresAt = expiresAt;
        return result;
      } catch (err) {
        throw new Error(err.message);
      }
    }
  };
  loginWithOneTimeAccess = async (loginRequest, requestId) => {
    let result = {};
    try {
      result = await User.oneTimeAccessUserLogin(loginRequest);
      logUserEvent(FIREBASE_EVENT_NAMES.ONE_TIME_LOGIN_PASSED);
      // setLastActiveTime();
      // this.setSmartechIdentity(authResult); //Setting up primary key for the smartech panel
      localStorage.setItem('access_token', result.access_token);
      localStorage.setItem('refresh_token', result.refresh_token);
      sessionStorage.setItem(
        'oneTimeUserStatus',
        JSON.stringify({ hasAccess: true, isExpired: false, requestId: requestId }),
      );
      localStorage.setItem(
        'oneTimeUserStatus',
        JSON.stringify({ hasAccess: true, isExpired: false, requestId: requestId }),
      );
      const expiresAt = result.expires_in * 1000 + new Date().getTime();
      this.accessToken = result.access_token;
      this.idToken = result.refresh_token;
      this.expiresAt = expiresAt;
      return result;
    } catch (err) {
      throw new Error(err.message);
    }
  };
  getAccessFromRefresh = () => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_ACCESS_TOKEN_WITH_REFRESH_TOKEN,
        })
        .then((res) => {
          resolve(res.data.getRefreshTokenForConsumer);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  checkIfUserAlreadyLoggedIn = async (isLoggedInValue, isOneTimeUser = false) => {
    const accessToken = this.getAccessToken();
    const isSocialLogin = localStorage.getItem('isSocialLogin');
    if (accessToken) {
      if (isLoggedInValue || isSocialLogin) {
        return;
      }
      const refreshToken = this.getIdToken();
      if (refreshToken) {
        // If we fail to refresh we will fall through to logout.
        try {
          const newTokens = isOneTimeUser
            ? await this.getOneTimeAccessFromRefresh()
            : await this.getAccessFromRefresh();
          // set new session
          localStorage.setItem('access_token', newTokens.access_token);
          localStorage.setItem('refresh_token', newTokens.refresh_token);

          const expiresAt = newTokens.expires_in * 1000 + new Date().getTime();
          this.accessToken = newTokens.access_token;
          this.idToken = newTokens.refresh_token;
          this.expiresAt = expiresAt;
        } catch (error) {
          console.warn('Error Refreshing Login', error);
        }
      } else {
        this.logout();
      }
    }
    console.log('no access token');
  };

  handleAuthenticationForMpoweredUI = (authResult) => {
    if (authResult && authResult.access_token && authResult.id_token) {
      logUserEvent(FIREBASE_EVENT_NAMES.LOGIN_PASSED);
      localStorage.setItem('isLoggedIn', true);
      setLastActiveTime();
      this.setSmartechIdentity(authResult); //Setting up primary key for the smartech panel
      this.setSession(authResult);
      return true;
    } else {
      logUserEvent(FIREBASE_EVENT_NAMES.LOGIN_PASSED);
    }
    return false;
  };

  populateUserObjectAndFireHanselEvents = (userDetails) => {
    try {
      let userAddressLine1,
        userAddressLine2,
        birthDate,
        fullName,
        userAge,
        userGender,
        userCity,
        userState,
        userZipCode;
      userAddressLine1 =
        userAddressLine2 =
        birthDate =
        fullName =
        userAge =
        userGender =
        userCity =
        userState =
        userZipCode =
          String(null);
      const userInfo = userDetails.basicProfileDetails;
      if (userDetails.contactProfileDetails.addressList.length > 0) {
        const { city, state, zipCode, addressLine1, addressLine2 } =
          userDetails.contactProfileDetails.addressList[0];
        userCity = city;
        userState = state;
        userZipCode = zipCode;
        userAddressLine1 = addressLine1;
        userAddressLine2 = addressLine2;
      }
      fullName =
        userInfo?.firstName?.[0] && userInfo?.lastName
          ? getUserName(userInfo?.firstName?.[0], userInfo?.lastName)
          : String(null);
      userAge = userInfo?.dob ? calculateAgeFromDOB(userInfo?.dob) : String(null);
      birthDate = userInfo?.dob ? new Date(userInfo?.dob).toLocaleDateString('en-US') : String(null);
      userGender = userInfo?.gender ? userInfo?.gender : String(null);
      Hansel.getUser().putAttribute('userName', Boolean(fullName));
      Hansel.getUser().putAttribute('userAge', Boolean(userAge));
      Hansel.getUser().putAttribute('userGender', Boolean(userGender));
      Hansel.getUser().putAttribute('userCity', Boolean(userCity));
      Hansel.getUser().putAttribute('userState', Boolean(userState));
      Hansel.getUser().putAttribute('userZipCode', Boolean(userZipCode));
      Hansel.getUser().putAttribute('addressLine1', Boolean(userAddressLine1));
      Hansel.getUser().putAttribute('addressLine2', Boolean(userAddressLine2));
      Hansel.getUser().putAttribute('birthDate', Boolean(birthDate));
    } catch (e) {
      console.log('Error while populating user object', e);
    }
  };

  handleAuthentication = async (authResultObj = null, readFromLocalStorage = false) => {
    const authResult = authResultObj ?? this.parseParms(document.location.hash.substring(1));
    const isSocialLogin = localStorage.getItem('isSocialLogin');
    if ((authResult && authResult.access_token && authResult.id_token) || readFromLocalStorage) {
      logUserEvent(FIREBASE_EVENT_NAMES.LOGIN_PASSED);
      if (!readFromLocalStorage) {
        sessionStorage.setItem('isLoggedIn', true);
        localStorage.setItem('isLoggedIn', true);
        this.setSmartechIdentity(authResult); //Setting up primary key for the smartech panel
        if (isSocialLogin) {
          localStorage.setItem('access_token', authResult.access_token);
          localStorage.setItem('refresh_token', authResult.id_token);
        }
      }

      setLastActiveTime();
      const sessionToSet = readFromLocalStorage
        ? {
            access_token: localStorage.getItem('access_token'),
            id_token: localStorage.getItem('refresh_token'),
          }
        : authResult;
      this.setSession(sessionToSet);
      const profileDetails = await ProfileService.fetchProfileDetails(PROFILE_QUERY);
      this.populateUserObjectAndFireHanselEvents(profileDetails);
      return true;
    } else {
      logUserEvent(FIREBASE_EVENT_NAMES.LOGIN_PASSED);
    }
    return false;
  };

  parseParms = (str) => {
    const pieces = str.split('&'),
      data = {};
    let i, parts;
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split('=');
      if (parts.length < 2) {
        parts.push('');
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  };

  setSmartechIdentity = (authResult) => {
    try {
      smartech('identify', authResult.patient);
    } catch (e) {
      console.warn('Smartech Error : ', e);
    }
  };

  setSession = (authResult) => {
    const expiresAt = authResult.expires_in * 1000 + new Date().getTime();
    this.accessToken = authResult.access_token;
    this.idToken = authResult.id_token;
    this.expiresAt = expiresAt;
  };

  clearStorage = () => {
    localStorage.removeItem('isLoggedIn');
    // if (!autoLogout) {
    localStorage.removeItem('EAMCode');
    localStorage.removeItem('EAMState');
    localStorage.removeItem(ACTIVE_CIRCLE_LOADER_KEY);
    localStorage.removeItem('partnerToBeAdded');
    localStorage.removeItem('partnerInfo');
    localStorage.removeItem('socialLogin');

    localStorage.removeItem('partnerToBeAdded');
    localStorage.removeItem('circleIdToBeAdded');
    localStorage.removeItem('partnerRefreshToken');
    localStorage.removeItem('isFindCareEnabled');
    localStorage.removeItem('oneTimeUserStatus');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('referralPromoCode');
    localStorage.removeItem('consentUserDetails');
    localStorage.removeItem('isSocialLogin');
    localStorage.removeItem('dependentFhirId');
    localStorage.removeItem('userId');
    localStorage.removeItem('socialSignupFrom');
    // localStorage.clear();
    // }
    sessionStorage.removeItem('isLoggedIn');

    // Avoid clearing the entire localStorage as we need few keys to perform nudging to users
    sessionStorage.clear();
  };

  // logout = (autoLogout = false) => {
  //   const checkOneTimeStatus = this.checkOneTimeStatus();
  //   const logoutBase = process.env.SMILE_CALLBACK_URL + '/logout';
  //   const redirectPath = this.getBaseUrl(process.env.SMILE_CALLBACK_URL);
  //   const redirectURI = checkOneTimeStatus?.requestId
  //     ? `${redirectPath}/oneTimeAccess?requestId=${checkOneTimeStatus.requestId}`
  //     : redirectPath;
  //   const logoutURL = logoutBase + '?redirect_uri=' + redirectURI;
  //   FirebaseUtils.clearToken();
  //   this.clearStorage();
  //   // localStorage.setItem(lastActiveTimeKey, null); // TODO: don't clear this key if possible. Make auto-login procedure, depending on the last user activity and refresh token
  //   console.log('redirectURI', redirectURI);
  //   window.location.replace(redirectURI);
  // };

  logout = (autoLogout = false) => {
    const DEMO_CALLBACK_URL = "https://demo.mpoweredhealth.com/callback"
    if (window.location.hostname.startsWith('demo.mpowered')) {
        const checkOneTimeStatus = this.checkOneTimeStatus();
        const logoutBase = DEMO_CALLBACK_URL + '/logout';
        const redirectPath = this.getBaseUrl(DEMO_CALLBACK_URL);
        const redirectURI = checkOneTimeStatus?.requestId
          ? `${redirectPath}/oneTimeAccess?requestId=${checkOneTimeStatus.requestId}`
          : redirectPath;
        const logoutURL = logoutBase + '?redirect_uri=' + redirectURI;
        FirebaseUtils.clearToken();
        this.clearStorage();
        // localStorage.setItem(lastActiveTimeKey, null); // TODO: don't clear this key if possible. Make auto-login procedure, depending on the last user activity and refresh token
        console.log('redirectURI', redirectURI);
        window.location.replace(redirectURI);
      } else  {
        const checkOneTimeStatus = this.checkOneTimeStatus();
        const logoutBase = process.env.SMILE_CALLBACK_URL + '/logout';
        const redirectPath = this.getBaseUrl(process.env.SMILE_CALLBACK_URL);
        const redirectURI = checkOneTimeStatus?.requestId
          ? `${redirectPath}/oneTimeAccess?requestId=${checkOneTimeStatus.requestId}`
          : redirectPath;
        const logoutURL = logoutBase + '?redirect_uri=' + redirectURI;
        FirebaseUtils.clearToken();
        this.clearStorage();
        // localStorage.setItem(lastActiveTimeKey, null); // TODO: don't clear this key if possible. Make auto-login procedure, depending on the last user activity and refresh token
        console.log('redirectURI', redirectURI);
        window.location.replace(redirectURI);
      }
    // }
  };

  getBaseUrl = (callbackURL) => {
    const index = callbackURL.length - callbackURL.lastIndexOf('/');
    return callbackURL.slice(0, -index);
  };

  isLoggedIn = () => {
    if (localStorage.getItem('isLoggedIn')) {
      if (!this.accessToken) {
        return {
          status: 'fetch',
        };
      }

      return {
        status: 'true',
      };
    } else {
      return {
        status: 'false',
      };
    }
  };

  isAuthenticated = () => {
    const expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  };

  scheduleRenewal = () => {
    const expiresAt = this.expiresAt;
    const timeout = expiresAt - Date.now();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.login();
      }, timeout);
    }
  };

  //One time access methods

  checkOneTimeStatus = () => {
    return JSON.parse(localStorage.getItem('oneTimeUserStatus')) || null;
  };
  getOneTimeAccessFromRefresh = () => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_ONE_TIME_ACCESS_TOKEN_WITH_REFRESH_TOKEN,
          variables: {
            realm: 'one_time_access',
          },
        })
        .then((res) => {
          resolve(res.data.getOneTimeAccessRefreshToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
