import { format } from 'date-fns';
import {
  AGO_TIME,
  ALLERGY_DETAIL_LABELS,
  AWARD_POINT_PAY,
  CASE_STATES,
  CMS_CLAIMS_TYPE_TEXTS,
  CONDITION_DETAIL_LABELS,
  COVERAGE_PLANS_FULL_NAMES,
  DATA_SOURCE,
  DATE_FORMAT,
  DATE_TO_BE_REPLACED_FOR_RECORDED_ON,
  DEFAULT_NA,
  DEFAULT_NULL_REPLACEMENT,
  DOCPANEL_CASE,
  DOCPANEL_FAQ,
  D_SOURCE,
  GIFT_CARD,
  HOME as topServices,
  MAX_SERVICES_ON_HOME_COUNT,
  MEDICATION_DETAIL_LABELS,
  months,
  MPOW_POINTS_EARNED,
  NO_BILL_STATE,
  PASSWORD_STRENGTH_TITLE,
  PASS_RESET_SENT_TXT,
  PAYMENT_FAIL_MSG,
  PAYMENT_STATUS,
  PENDING_CASE_STATES,
  RATING_CATEGORY,
  regex,
  SECOND_OPINION,
  SELF_REPORTING_TEXTS,
  STATUS_COLOR_LIST,
  THUMBNAIL_URL,
  TRANSACTION_FAILURE_MESSAGE,
  VITAL_FHIR_CODES,
  VITAL_REVERSE_LOOKUP_CODES,
  VITAL_TYPES,
  weekDays,
  ACCOUNT_TYPE,
  DUMMY_DATE_STUB,
} from './constants';

import {
  CONDITION_CATEGORY,
  findConditionCategoryLabel,
  findImmunizationRouteLabel,
  getAllergyCriticalityLabel,
  getMedicationFrequencyLabel,
  SELF_REPORTED_VALUE_KEYS,
} from './selfReportingUtils';

import UserCircle from '../assets/images/defaultAvatarBlack.svg';
import { lastActiveTimeKey } from '../AutoLogout';
import { TABLE_HEADINGS, TABLE_KEYS } from './labels/tableHeadings';

import { formatInTimeZone } from 'date-fns-tz';
import { CONSOLIDATED_EVENTS } from '../constants/analytics';
import { DEVICE_TYPE, SIGNUP_SCREEN } from '../features/landingFlow/constants';
import objectPath from 'object-path';
import { isAndroid, isIOS as IOS, browserVersion } from 'react-device-detect';

export const hidePartialEmail = (email = '') => {
  if (!email) {
    return email;
  }

  const split = email.split('@');
  const leftContent = split[0];
  const rightContent = `@${split[1]}`;
  let numberOfCharVisible;
  if (leftContent.length > 3) {
    numberOfCharVisible = 3;
  } else if (leftContent.length > 1) {
    numberOfCharVisible = 1;
  } else {
    numberOfCharVisible = 0;
  }
  return leftContent
    .slice(`-${numberOfCharVisible}`)
    .padStart(leftContent.length, 'x')
    .concat(rightContent);
};

export const hidePartialPhone = (phone = '') => {
  if (!phone) {
    return phone;
  }
  const last4Digit = phone.slice(-4);
  return 'xxx-xxx-'.concat(last4Digit);
};

export const createResetPassMessage = (email = '') => {
  return replace(PASS_RESET_SENT_TXT, 'EMAIL-ID', email);
};

export const getPasswordStrengthColor = (passwordStrength = '') => {
  switch (passwordStrength) {
    case 1:
      return '#FF0000';
    case 2:
      return '#FFFF00';
    case 3:
      return '#0000FF';
    case 4:
      return '#008000';
    default:
      return '#D3D3D3';
  }
};

export const getPasswordStrength = (password = '') => {
  //using IIFE here
  //later can be made as an module
  const score = ((regex, password) => {
    const {
      passwordUpper: UPPER_CASE,
      passowrdLower: LOWER_CASE,
      passwordNumber: NUMBER_CASE,
      passwordSpecialChar: SPECIAL_CHAR_CASE,
    } = regex;
    const minLength = 8;

    if (password && password.length < minLength) {
      return 0;
    }

    let score = 0;

    score += UPPER_CASE.test(password) ? 1 : 0;
    score += LOWER_CASE.test(password) ? 1 : 0;
    score += NUMBER_CASE.test(password) ? 1 : 0;
    score += SPECIAL_CHAR_CASE.test(password) ? 1 : 0;

    return score;
  })(regex, password);

  return score;
};

export const getPasswordStrengthTitle = (passwordStrength) =>
  PASSWORD_STRENGTH_TITLE[passwordStrength];

export const rankPassword = (password) => {
  const upper = regex.passwordUpper,
    lower = regex.passowrdLower,
    number = regex.passwordNumber,
    special = regex.passwordSpecialChar,
    minLength = 8;
  let score = 0;

  if (password.length < minLength) {
    return 0;
  }

  if (upper.test(password)) {
    score++;
  }
  if (lower.test(password)) {
    score++;
  }
  if (number.test(password)) {
    score++;
  }
  if (special.test(password)) {
    score++;
  }

  return score;
};

export const renderTextorImage = (obj, text) => {
  if (obj.type === 'text') {
    return objectPath.ensureExists(text, obj?.key, '');
  } else {
    return obj;
  }
};

export const rankPasswordForNewFlow = (password) => {
  const upper = regex.passwordUpper,
    lower = regex.passowrdLower,
    number = regex.passwordNumber,
    special = regex.passwordSpecialChar,
    minLength = 8;
  let message = SIGNUP_SCREEN.LEN_ERR_8;
  const arr = [' an upper case', 'lower case', 'number', 'symbol'];
  let score = 0;

  if (password.length < minLength) {
    return { score: 0, message };
  }

  if (upper.test(password)) {
    score++;
    arr.splice(0, 1);
  }
  if (lower.test(password)) {
    score++;
    arr.splice(arr.indexOf('lower case'), 1);
  }
  if (number.test(password)) {
    score++;
    arr.splice(arr.indexOf('number'), 1);
  }
  if (special.test(password)) {
    score++;
    arr.splice(arr.length - 1, 1);
  }
  if (score === 4) {
    message = SIGNUP_SCREEN.LOOKS_GOOD;
  } else {
    if (arr.length === 1) {
      message = arr[0] !== ' an upper case' ? `Must include a ${arr[0]}` : `Must include ${arr[0]}`;
    } else {
      const jn = ` and ${arr.pop()}`;
      arr.unshift(jn);
      message = `Must include ${arr.join(', ').replace(`${jn}, `, '') + jn}`;
    }
  }
  return { score, message };
};

export const replace = (value = '', toReplace = '', replaceWith = '') => {
  return value.replace(toReplace, replaceWith);
};

export const isNumber = (val) => {
  return val && val.match(/[0-9]/);
};

export const isKeyValid = (event) => {
  const key = event.keyCode || event.which;

  //keycode=8 (backspace)
  //keycode=46 (delete)

  //keycode =>48-57 (0-9 numbers on top of keypad)
  //keycode =>96-105 (0-9 numbers on numpad)
  if (event.keyCode === 8 || event.keyCode === 46) {
    return true;
  } else if ((key < 48 || key > 57) && (key < 96 || key > 105)) {
    return false;
  }

  return true;
};

export const generateRewardText = (rewardPoints) => {
  if (rewardPoints === undefined || rewardPoints === null) {
    return '';
  }

  return replace(MPOW_POINTS_EARNED, '<Points>', rewardPoints);
};

export const generateFailureMessage = (payee, cardNumber) => {
  if (!payee || !cardNumber) {
    return TRANSACTION_FAILURE_MESSAGE;
  }

  const payeeReplaced = replace(PAYMENT_FAIL_MSG, '<Payee>', payee);

  return replace(payeeReplaced, '<Card-No>', cardNumber);
};

export const generatePaymentMethods = (paymentMethods) => {
  if (paymentMethods && paymentMethods.length) {
    const cards = paymentMethods.map((paymentMethod) => {
      const expiryYearIn2Digits = paymentMethod.expiryYear.toString().slice(-2);
      return {
        nickName: paymentMethod.nickName,
        id: paymentMethod.paymentSystemId,
        isDefault: paymentMethod.isDefault,
        cardNumber: paymentMethod.last4,
        expiryDate: `${paymentMethod.expiryMonth}/${expiryYearIn2Digits}`,
        owner: `${paymentMethod.name}`,
      };
    });
    return setDefaultCardAtTop(cards);
  } else {
    return new Array(0);
  }
};
export const setDefaultCardAtTop = (cards) => {
  let defaultCardIndex = 0;
  let updatedArray;
  cards.forEach((card, index) => {
    if (card.isDefault) {
      defaultCardIndex = index;
    }
  });

  //extract default card and put it in top of cards Array when pos is not 0
  if (defaultCardIndex) {
    updatedArray = cards.splice(defaultCardIndex, 1).concat(cards);
  } else {
    updatedArray = cards;
  }

  return updatedArray;
};

export const replaceImageProperty = (
  arr,
  imagesArr,
  pwaImagesArr,
  inactiveImagesArr = [],
  pwaInactiveImagesArr = [],
) => {
  if (!arr || !imagesArr) {
    return [];
  }

  const clonedArray = [...arr];

  for (let i = 0; i < imagesArr.length; i++) {
    clonedArray[i].image = imagesArr[i];
  }
  for (let i = 0; i < pwaImagesArr.length; i++) {
    clonedArray[i].pwaImage = pwaImagesArr[i];
  }
  for (let i = 0; i < inactiveImagesArr.length; i++) {
    clonedArray[i].inactiveImage = inactiveImagesArr[i];
  }
  for (let i = 0; i < pwaInactiveImagesArr.length; i++) {
    clonedArray[i].pwaInactiveImage = pwaInactiveImagesArr[i];
  }
  return clonedArray;
};

export const replaceImagePropertyV2 = (arr, imagesArr) => {
  if (!arr || !imagesArr) {
    return [];
  }

  const clonedArray = [...arr];

  for (let i = 0; i < imagesArr.length; i++) {
    clonedArray[i].image = imagesArr[i];
  }

  return clonedArray;
};

export const replaceImagePropertyWithObject = (object, imagesArr) => {
  const objectKeysArray = Object.keys(object);

  if (!objectKeysArray || !imagesArr) {
    return {};
  }

  const resultObject = {};

  for (let i = 0; i < objectKeysArray.length; i++) {
    resultObject[objectKeysArray[i]] = imagesArr[i];
  }
  return resultObject;
};

export const getMonth = (month) => {
  if (month >= 1 && month <= 12) {
    return months[month - 1];
  }
  return '';
};

export const isEmpty = (value = '') => {
  return !value;
};

export const getBillsText = (pastDue, paid) => {
  if (pastDue) {
    return NO_BILL_STATE.PAST_DUE_BILLS;
  } else if (paid) {
    return NO_BILL_STATE.PAID_BILLS;
  }
  return NO_BILL_STATE.DUE_BILLS;
};

export const isIOS = () => !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export const getFileAsDataURL = (file, onSuccess) => {
  const reader = new FileReader();

  reader.onload = () => {
    onSuccess(file.name, reader.result);
  };

  // Read in the image file as a data URL.
  reader.readAsDataURL(file);
};

export const getActiveStep = (addNewCard) => {
  if (addNewCard) {
    return 4;
  }
  return 3;
};
export const createUuidv4 = () => {
  let uuid;

  return (
    window.localStorage.getItem('uuid') ||
    window.localStorage.setItem('uuid', (uuid = uuidv4())) ||
    uuid
  );
};

/**
 * @description: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
};

export const getDeviceType = () =>
  `${navigator.whoami} ${navigator.userAgent.match(/\(([^)]+)\)/)[1]}`;
export const convertTimeStampToDateTimeString = (timestamp) => {
  //timestamp="2019-12-09T13:04:00Z"
  const creationDateTime = new Date(timestamp);
  const creationTime = creationDateTime.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const creationDate = `${
    months[creationDateTime.getUTCMonth()]
  } ${creationDateTime.getUTCDate()} ,${creationDateTime.getUTCFullYear()}`;
  const creationDay = weekDays[creationDateTime.getDay()];
  return { date: creationDate, time: creationTime.toLowerCase(), day: creationDay };
};
export const getDocName = (practitionerInfo) => {
  return `${practitionerInfo.prefix} ${practitionerInfo.givenName} ${practitionerInfo.familyName}`;
};

export const getActionOfPendingCases = (currentServiceRequest, procedureCTA, occurrence) => {
  let action = null;
  let procedure = null;
  if (currentServiceRequest.status === CASE_STATES.Draft) {
    action = PENDING_CASE_STATES.RESUME_CASE;
    procedure = `${SECOND_OPINION.CREATED_ON} ${occurrence}`;
  } else if (currentServiceRequest.status === CASE_STATES.OnHold) {
    if (currentServiceRequest.paymentStatus === PAYMENT_STATUS.PENDING) {
      action = PENDING_CASE_STATES.PENDING_PAYMENT;
    } else {
      action = PENDING_CASE_STATES.MAKE_PAYMENT;
    }
    procedure = procedureCTA;
  }
  const pendingState = {
    action: action,
    radiologistDetails: procedure,
  };
  return pendingState;
};

export const getPrimaryImagingStudy = (imagingStudyList) => {
  let returningImagingStudy = null;
  imagingStudyList.forEach((imagingStudyObj) => {
    imagingStudyObj.extension.forEach((extensionObj) => {
      if (extensionObj.valueString === 'Primary' && extensionObj.url === 'StudyType') {
        returningImagingStudy = imagingStudyObj;
      }
    });
  });
  return returningImagingStudy;
};

export const getSubheadingOnRedeemAwardPoint = (redeemAwardpoint, availableAwardPoints) => {
  return `${AWARD_POINT_PAY.YOU_WILL_REDEEM_TEXT_PREFIX} ${redeemAwardpoint} ${
    AWARD_POINT_PAY.YOU_WILL_REDEEM_TEXT_MIDDLE
  } ${availableAwardPoints - redeemAwardpoint} ${AWARD_POINT_PAY.YOU_WILL_REDEEM_TEXT_POSTFIX}`;
};

/**
 *
 * @param {Object[]} list List of objects
 * @param {String} key Name of object property
 * @param {Any} value Value to find in @param list
 * @returns {Object} Object found if @param value matched using @param key in @param list or null if not found
 */
export const searchValue = (list, key, value) => {
  if (!list || !key || !value) {
    return null;
  }
  const item = list.find((listItem) => listItem[key] === value);

  return item ? item : null;
};
export const setArrayAtIndex = (array, index, value) => {
  const updatedArray = [...array];
  updatedArray[index] = value;

  return updatedArray;
};
export const getTextFieldLabel = (
  minAwardPointCanRedeem,
  maxValue,
  onedollarToPointsFactor,
  maxValueForFixedCard,
) => {
  const minAmountCanRedeem =
    onedollarToPointsFactor > 0 ? minAwardPointCanRedeem / onedollarToPointsFactor : 0;
  if (maxValueForFixedCard !== 0) {
    return `${GIFT_CARD.SELECT_AMOUNT_LABEL_PREFIX} $${minAmountCanRedeem} - $${maxValueForFixedCard})`;
  }
  return `${GIFT_CARD.ENTER_AMOUNT_LABEL_PREFIX} $${minAmountCanRedeem} - $${maxValue})`;
};
export const getUSFormatPhoneNo = (phoneno, oldValue = '') => {
  if (phoneno && phoneno.length < oldValue.length) {
    return phoneno;
  }

  const digits = (phoneno?.match(/\d/g) || []).join('');

  if (digits.length === 0) {
    return '';
  }
  if (digits.length < 3) {
    return `(${digits}`;
  }
  if (digits.length < 6) {
    return `(${digits.substr(0, 3)}) ${digits.substr(3)}`;
  }

  return `(${digits.substr(0, 3)}) ${digits.substr(3, 3)} ${digits.substr(6)}`;
};

export const formatDateInMMDDYYYY = (dateString) => {
  const date = new Date(dateString);
  if (!date) {
    return '';
  }

  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day} ${year}`;
};

// Formated date example - 08/18/2000
export const formatDateInSlashMMDDYYYY = (dateString) => {
  const date = new Date(dateString);
  if (!date) {
    return '';
  }

  const month = date.getUTCMonth() > 12 ? date.getUTCMonth() : `0${date.getUTCMonth()}`;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

// feb 04, 1990
export const formatDateInMMDDYYYYWithComma = (dateString) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);
  if (!date) {
    return '';
  }

  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};
export const formatDateInMMDDYYYYWithCommaTwoDigits = (dateString) => {
  if (!dateString || dateString === '-') {
    return '-';
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return '-';
  }

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const dy = day < 10 ? `0${day}` : day;
  return `${month} ${dy}, ${year}`;
};

export const formatDateInMMMDDYYYYHHMMPP = (dateString) => {
  if (dateString) {
    const fixedDateFormat = fixFormat(dateString);
    const date = new Date(fixedDateFormat);
    if (!date || isNaN(date)) {
      return '';
    }

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours === 0 ? 12 : hours;

    const month = MONTHS[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    return `${month} ${day}, ${year}, ${hours.toString().length === 1 ? `0${hours}` : hours}:${
      minutes.toString().length === 1 ? `0${minutes}` : minutes
    }${period}`;
  }
  return dateString;
};

/**
 *
 * @param {Date} date ( Fri Apr 24 2020 16:09:28 GMT+0530 )
 * @returns  24 Apr, 04:09 PM
 */

export const formatDateInDDMMMHHMMPP = (dateString) => {
  if (dateString) {
    const fixedDateFormat = fixFormat(dateString);
    const date = new Date(fixedDateFormat);
    if (!date || isNaN(date)) {
      return '';
    }

    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours === 0 ? 12 : hours;

    const month = MONTHS[date.getUTCMonth()];
    const day = date.getUTCDate();

    return `${day} ${month}, ${hours.toString().length === 1 ? `0${hours}` : hours}:${
      minutes.toString().length === 1 ? `0${minutes}` : minutes
    } ${period}`;
  }
  return dateString;
};

export const formatDateLocalInDDMMMHHMMPP = (dateString) => {
  if (dateString) {
    const fixedDateFormat = fixFormat(dateString);
    const date = new Date(fixedDateFormat);
    if (!date || isNaN(date)) {
      return '';
    }
    const locatDate = new Date(date);
    let hours = locatDate.getHours();
    const minutes = locatDate.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours === 0 ? 12 : hours;

    const month = MONTHS[locatDate.getMonth()];
    const day = locatDate.getDate();

    return `${day} ${month}, ${hours.toString().length === 1 ? `0${hours}` : hours}:${
      minutes.toString().length === 1 ? `0${minutes}` : minutes
    } ${period}`;
  }
  return dateString;
};

export const formatDateInMMMDD = (dateString) => {
  const date = new Date(dateString);
  if (!date) {
    return '';
  }

  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();

  return `${month} ${day}`;
};

export const dateParse = (date, utc = true) => {
  if (!date || (typeof date === 'string' && date.includes(DUMMY_DATE_STUB))) {
    return '-';
  }
  const resultDate = new Date(date);

  let dateInString = '';

  if (resultDate instanceof Date && !isNaN(resultDate.getTime())) {
    if (utc) {
      dateInString = replace(DATE_FORMAT, '<MMM>', months[resultDate.getUTCMonth()]);
      dateInString = replace(dateInString, '<DD>', resultDate.getUTCDate());
      dateInString = replace(dateInString, '<YYYY>', resultDate.getUTCFullYear());
    } else {
      dateInString = replace(DATE_FORMAT, '<MMM>', months[resultDate.getMonth()]);
      dateInString = replace(dateInString, '<DD>', resultDate.getDate());
      dateInString = replace(dateInString, '<YYYY>', resultDate.getFullYear());
    }
  }

  return dateInString;
};

export const isCurrentYear = (dateString) => {
  const date = new Date(dateString);
  if (!date) {
    return false;
  }
  const currentDate = new Date();
  return date.getUTCFullYear() === currentDate.getUTCFullYear();
};

export const getPhoneno = (USPhoneno) => {
  let convertPhoneno = USPhoneno.split(' ').join('');
  convertPhoneno = convertPhoneno.replace('(', '');
  convertPhoneno = convertPhoneno.replace(')', '');

  return convertPhoneno;
};

export const getComponentLink = (link) => {
  let redirectLink = '';
  const linkArray = link.split('/');
  for (let i = 3; i < linkArray.length - 1; i++) {
    redirectLink += linkArray[i] + '/';
  }
  return redirectLink + linkArray[linkArray.length - 1];
};
export const removeComponentLink = (link) => {
  let redirectLink = '';
  const linkArray = link.split('/');
  for (let i = 0; i < linkArray.length - 1; i++) {
    redirectLink += linkArray[i] + '/';
  }
  return redirectLink;
};

export const getEditedCardNumber = (last4) => {
  return `XXXX-${last4}`;
};

export const getCardExpiry = (expiryMonth, expiryYear) => {
  return `${expiryMonth}/${expiryYear % 100}`;
};

export const convertRatingCategoryName = (name) => {
  return RATING_CATEGORY[name].name;
};

export const getRadiologistFromPractitionerRole = (practitionerRole) => {
  return practitionerRole.practitioner && practitionerRole.practitioner.name[0].text;
};

export const getCaseTitleFromImagingStd = (imagingStudy) => {
  const primaryImagingStudy = getPrimaryImagingStudy(imagingStudy);
  if (primaryImagingStudy && primaryImagingStudy.modality) {
    return `${primaryImagingStudy.modality[0].code} ${primaryImagingStudy.description}`;
  }
};

export const getObservationDetails = (observationList, loincCode) => {
  if (observationList) {
    for (let i = 0; i < observationList.length; i++) {
      const observation = observationList[i];
      if (observation.code.coding[0].code === loincCode && observation.valueString) {
        return observation.valueString;
      }
    }
  }
};

export const getScanUrl = (imagingStudy) => {
  const imagingStudyPrimary = getPrimaryImagingStudy(imagingStudy);
  let scanUrl = null;
  if (
    imagingStudyPrimary !== null &&
    imagingStudyPrimary.extension !== null &&
    imagingStudyPrimary.extension.length !== 0
  ) {
    imagingStudyPrimary.extension.forEach((extensionObj) => {
      if (extensionObj.url === THUMBNAIL_URL) {
        scanUrl = extensionObj.valueString;
      }
    });
  }
  return scanUrl;
};

export const getDownloadableReportUrl = (diagnosticReportObservations) => {
  if (diagnosticReportObservations && diagnosticReportObservations.mediaForDiagnosticReport) {
    return diagnosticReportObservations.mediaForDiagnosticReport.content.url;
  }
  return null;
};

export const convertToUSFormatPhoneList = (phoneList) => {
  const usFormatPhoneList = phoneList.map((phoneObj) => {
    phoneObj.phoneNumber = getUSFormatPhoneNo(phoneObj.phoneNumber);
    return Object.create(phoneObj);
  });
  return usFormatPhoneList;
};
export const convertToNormalPhoneList = (usFormatPhoneList) => {
  const normalPhoneList = usFormatPhoneList.map((phoneObj) => {
    phoneObj.phoneNumber = getPhoneno(phoneObj.phoneNumber);
    return Object.create(phoneObj);
  });
  return normalPhoneList;
};

export const getAddressListWithWrappedAddressProperties = (addList) => {
  const updatedList = addList.map((obj) => {
    const addressObject = Object.create({
      nickName: obj.nickName,
      addressLine1: obj.addressLine1,
      addressLine2: obj.addressLine2,
      city: obj.city,
      state: obj.state,
      zipCode: obj.zipCode,
    });
    return { address: addressObject, primary: obj.primary, active: obj.active };
  });
  return updatedList;
};

export const getAddressListWithOutWrappedAddressProperties = (addList) => {
  const updatedList = addList.map((obj) => {
    return { ...obj.address, primary: obj.primary, deactive: obj.deactive };
  });
  return updatedList;
};

export const isObjEquivalent = (a, b) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};
export const calculateSince = (datetime) => {
  const formattedDate = formatInTimeZone(new Date(datetime), 'UTC', 'MMMM dd, yyyy hh:mm aaa');
  const formattedCurrentTime = formatInTimeZone(new Date(), 'UTC', 'MMMM dd, yyyy hh:mm aaa');
  const timeDiff = new Date(formattedCurrentTime).getTime() - new Date(formattedDate).getTime();
  let since;
  const sinceSec = Math.floor(timeDiff / 1000);
  const sinceMin = Math.floor(sinceSec / 60);
  const sinceHr = Math.floor(sinceMin / 60);
  const sinceDay = Math.floor(sinceHr / 24);
  switch (true) {
    case sinceSec < 60:
      since = `${sinceSec}sec ago`;
      break;
    case sinceMin < 60:
      since = `${sinceMin}min ago`;
      break;
    case sinceHr < 24:
      since = `${sinceHr}h ago`;
      break;
    default:
      since = `${sinceDay}d ago`;
      break;
  }
  return since;
};

function dayDiff(date) {
  const oneDay = 24 * 60 * 60 * 1000;
  const currentDate = new Date();
  const alertDate = new Date(date);
  return Math.ceil(Math.abs(currentDate.getTime() - alertDate.getTime()) / oneDay);
}

export const alertModifier = (array) => {
  array.forEach((alert) => {
    const day = dayDiff(new Date(alert.createdAt));
    if (day === 1) {
      alert['category'] = AGO_TIME.TODAY;
    } else if (day === 2) {
      alert['category'] = AGO_TIME.YESTERDAY;
    } else if (day <= 7) {
      alert['category'] = AGO_TIME.WEEK;
    } else if (day > 7 && day <= 30) {
      alert['category'] = AGO_TIME.MONTH;
    } else {
      alert['category'] = AGO_TIME.EARLIER;
    }
  });
  return array;
};
export const getCommaSeperatedNumberFormat = (number) => {
  const num = new Number(number);
  const commaFormat = num.toLocaleString('en-US');
  return commaFormat;
};
export const isLarge = (width) => {
  return width === 'lg' || width === 'xl';
};

export const fetchAndStoreCaseId = (url) => {
  if (url.includes('secondopinion/callback')) {
    const encodedUrl = new URL(url);
    const searchParams = encodedUrl.searchParams;
    const docpanelObj = {
      status: searchParams.get(DOCPANEL_CASE.STATUS),
      guid: searchParams.get(DOCPANEL_CASE.GUID),
    };
    sessionStorage.setItem(DOCPANEL_CASE.DOCPANEL_CASE_INFO, JSON.stringify(docpanelObj));
  }
};

export const removeTypeNameFromObjectArray = (array) => {
  return array.map((object) => {
    delete object.__typename;
    return object;
  });
};

export const getIdentifierFromArray = (identifierArray, system) => {
  if (identifierArray) {
    const identifierFiltered = identifierArray.filter((identifer) => identifer.system === system);
    if (Array.isArray(identifierFiltered) && identifierFiltered.length !== 0) {
      return identifierFiltered[0].value;
    }
  }
  return '-';
};

export const getContactDocpanel = () => {
  let contactDocpanel = DOCPANEL_FAQ.QUESTIONS;
  contactDocpanel = replace(contactDocpanel, '<EMAIL>', DOCPANEL_FAQ.MAIL);
  contactDocpanel = replace(contactDocpanel, '<PHONE>', DOCPANEL_FAQ.PHONE);
  return contactDocpanel;
};

/**
 *
 * @param {Object[]} arrayList List of objects
 * @param {String} field Name of object property(timestamp)
 * @param {String} sortType 'Asc' Sort on basis of Oldest first and 'Desc' Sort on basis of Newest first
 * @returns {Object[]} Updated List of Object after sort on basis of @param field (time stamp)in @param arrayList
 */
export const sortList = (arrayList, field, sortType) => {
  const updatedList = [...arrayList];
  updatedList.sort((obj1, obj2) =>
    sortType === 'Desc'
      ? new Date(obj2[field]) - new Date(obj1[field])
      : new Date(obj1[field]) - new Date(obj2[field]),
  );
  return updatedList;
};

export const isNullAndUndefinedEmpty = (val) =>
  val === null || val === undefined || val === 'undefined' || val === 'null' || val === '';

export const mailTo = (recipientMailId) => {
  window.open(`mailto:${recipientMailId}`);
};

/**
 *
 * @param {Date} date ( Fri Apr 24 2020 16:09:28 GMT+0530 )
 * @param {Number} days 5 (Number of days given date should be extended)
 * returns Wed Apr 29 2020 16:09:28 GMT+053
 */
export const getExtendedDate = (date, days) => {
  const extendedDateEpoch = date.setDate(date.getUTCDate() + days);
  return new Date(extendedDateEpoch);
};

/**
 *
 * @param {Date} date (Wed Apr 29 2020 16:09:28 GMT+053)
 * returns formatted date string Apr 29,2020
 */
export const getDateFormatInString = (date) => {
  let dateInString = '';

  dateInString = replace(DATE_FORMAT, '<MMM>', months[date.getUTCMonth()]);
  dateInString = replace(dateInString, '<DD>', date.getUTCDate());
  dateInString = replace(dateInString, '<YYYY>', date.getUTCFullYear());

  return dateInString;
};

export const getValidThroughDate = (from, to) => {
  let validThrough = DOCPANEL_FAQ.VALID_THROUGH;
  validThrough = validThrough.replace('<FROM>', from);
  validThrough = validThrough.replace('<TO>', to);
  return validThrough;
};

export const getUserName = (firstName, lastName) => {
  return `${firstName} ${lastName}`;
};

export const getIndexOfObjInList = (list, key, value) => {
  let index = -1;
  if (list) {
    index = list.findIndex((obj) => obj[key] === value);
  }
  return index;
};

export const getProcessedServices = (servicesList = []) => {
  return servicesList.map((arrayItem) => {
    if (arrayItem.service.serviceName === 'Family Member History') {
      arrayItem.service.serviceName = 'Family history';
    }
    if (arrayItem.service) {
      const resourceUrl = arrayItem.service.webIconUrl;
      arrayItem.service.icon = arrayItem.service.mobIconUrl;
      arrayItem.service.thumbnail = arrayItem.service.webIconUrl || arrayItem.service.iconUrl;
    }
    return arrayItem;
  });
};

export const getAllHomeServices = (recentServicesList = []) => {
  if (recentServicesList.length < MAX_SERVICES_ON_HOME_COUNT) {
    const serviceType = [];
    const servicesListToShow = [...recentServicesList];

    recentServicesList.forEach((item) => {
      serviceType.push(item.service.serviceType);
    });
    topServices.forEach((item) => {
      const { service } = item;
      if (servicesListToShow.length === MAX_SERVICES_ON_HOME_COUNT) {
        return;
      } else if (!serviceType.includes(service.serviceType)) {
        servicesListToShow.push(item);
      }
    });
    return servicesListToShow;
  }
  return recentServicesList;
};

export const getMessageFromGraphqlError = (graphqlError) => {
  if (
    graphqlError &&
    graphqlError.graphQLErrors &&
    graphqlError.graphQLErrors[0] &&
    graphqlError.graphQLErrors[0].message
  ) {
    return graphqlError.graphQLErrors[0].message;
  }
  return graphqlError.message;
};
export const getIndexOfObjInNestesObjList = (list, key, value, nestedObj) => {
  let index = -1;
  if (list) {
    index = list.findIndex((obj) => obj[nestedObj][key] === value);
  }
  return index;
};

export const reasonCodeFun = (reasonCode) => {
  if (reasonCode) {
    return reasonCode.coding && reasonCode.coding[0]
      ? reasonCode.coding[0].display ||
          reasonCode.text ||
          reasonCode.coding[0].code ||
          DEFAULT_NULL_REPLACEMENT
      : reasonCode.text || DEFAULT_NULL_REPLACEMENT;
  }
  return DEFAULT_NULL_REPLACEMENT;
};

export const buildSimplifiedConditionDetails = (conditionDetails) => {
  const { id, abatementDateTime, code, severity, bodySite, category, recordedDate } =
    conditionDetails;

  const { CATEGORY, SEVERITY, BODYSITE, FRDATE, ABAT_DATE } = CONDITION_DETAIL_LABELS;

  return {
    id,
    condition: code && (code.coding[0].display || code.text || code.coding[0].code),
    [CATEGORY]: category && (category[0].coding[0].display || category[0].text),
    [SEVERITY]: severity && (severity.coding[0].display || severity.text),
    [BODYSITE]: bodySite && (bodySite[0].coding[0].display || bodySite[0].text),
    [FRDATE]: dateParseV2(recordedDate),
    [ABAT_DATE]: dateParseV2(abatementDateTime),
  };
};

export const buildSimplifiedMedicationDetails = (medicationDetails) => {
  const { id, requester, note, authoredOn, medicationCodeableConcept, medicationReference } =
    medicationDetails;

  let { dosageInstruction } = medicationDetails;
  dosageInstruction = dosageInstruction || [];

  const notes = note && note.map((currNote) => currNote.text).join(', ');
  const { PRES_DATE, DOSAGE, DUR, DOS_ROUTE, DOS_METHOD, NOTES, REQTR } = MEDICATION_DETAIL_LABELS;

  return {
    id,
    medicine:
      (medicationCodeableConcept && medicationCodeableConcept.text) ||
      (medicationCodeableConcept &&
        medicationCodeableConcept.coding.length &&
        medicationCodeableConcept.coding[0].display) ||
      (medicationReference && medicationReference.display) ||
      (medicationCodeableConcept &&
        medicationCodeableConcept.coding.length &&
        medicationCodeableConcept.coding[0].code) ||
      DEFAULT_NULL_REPLACEMENT,
    [PRES_DATE]: dateParse(authoredOn),
    [DOSAGE]:
      dosageInstruction.length &&
      (dosageInstruction[0].text || dosageInstruction[0].timing.code.text),
    [DUR]: null,
    [DOS_ROUTE]:
      dosageInstruction.length &&
      dosageInstruction[0].route &&
      (dosageInstruction[0].route.coding[0].display || dosageInstruction[0].route.text),
    [DOS_METHOD]:
      dosageInstruction.length && dosageInstruction[0].method && dosageInstruction[0].method.text,
    [REQTR]: requester && requester.display,
    [NOTES]: notes,
  };
};

export const buildSimplifiedAllergyDetails = (allergyDetails) => {
  if (!allergyDetails) {
    return null;
  }

  const { id, type, code, criticality, category, recordedDate, reaction } = allergyDetails;

  const { SUBSTANCE, TYPE, CATEGORY, CRITICALITY, FRDATE, REACTION } = ALLERGY_DETAIL_LABELS;

  const simplified = {
    id,
    allergy: code.coding[0].display || code.text,
    [SUBSTANCE]: code.coding[0].display || code.text,
    [TYPE]: type,
    [CATEGORY]: category && category.length && category[0],
    [CRITICALITY]: criticality,
    [FRDATE]: dateParseV2(recordedDate),
    [REACTION]:
      reaction &&
      reaction.length &&
      (reaction[0].manifestation[0].coding[0].display || reaction[0].manifestation[0].text),
  };

  return simplified;
};

export const filterProcedure = (procedure) => {
  const { id, code, bodySite, performedPeriod, performedDateTime, performer, note } = procedure;

  return {
    id,
    title: code.text || code.coding[0].display || DEFAULT_NULL_REPLACEMENT,
    bodySite:
      (bodySite && (bodySite[0].coding[0].display || bodySite[0].text)) || DEFAULT_NULL_REPLACEMENT,
    performer:
      (performer && performer[0] && performer[0].actor.display) || DEFAULT_NULL_REPLACEMENT,
    date:
      performedDateTime || (performedPeriod && performedPeriod.start) || DEFAULT_NULL_REPLACEMENT,
    provider:
      (performer && performer[0] && performer[0].onBehalfOf.display) || DEFAULT_NULL_REPLACEMENT,
    note: (note && note[0].text) || DEFAULT_NULL_REPLACEMENT,
  };
};

export const getTotalWords = (word) => {
  const words = word.split(/(\s+)/).filter((e) => e.trim().length > 0);
  return words.length;
};

export const getShort = (word) => {
  const wordArray = word.split(/(\s+)/).filter((e) => e.trim().length > 0);
  if (wordArray.length <= 7) {
    return word;
  }
  return `${wordArray.slice(0, 7).join(' ')}...`;
};

export const dateParseProcedure = (date) => {
  const resultDate = new Date(date);
  const day = `0${resultDate.getUTCDate()}`.slice(-2);

  if (resultDate instanceof Date && !isNaN(resultDate.getTime())) {
    return `${MONTHS[resultDate.getUTCMonth()]} ${day}, ${resultDate.getUTCFullYear()}`;
  }
  return DEFAULT_NULL_REPLACEMENT;
};

export const dateParseV2 = (date) => {
  if (!date) {
    return null;
  }

  const newDate = fixFormat(date);
  const resultDate = new Date(newDate);
  const day = `0${resultDate.getUTCDate()}`.slice(-2);

  if (resultDate instanceof Date && !isNaN(resultDate.getTime())) {
    return `${MONTHS[resultDate.getUTCMonth()]} ${day}, ${resultDate.getUTCFullYear()}`;
  }
  return DEFAULT_NULL_REPLACEMENT;
};

export const fixFormat = (date) => {
  if (!date) {
    return '';
  }
  return date;
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const simplifyClaimsServiceList = (serviceList) =>
  serviceList.map((service) => ({
    service: service.display,
    qty: service.quantity.value || DEFAULT_NULL_REPLACEMENT,
    insurerPaid:
      service.cost.paymentByMedicare !== null ? `$${service.cost.paymentByMedicare}` : DEFAULT_NA,
    patientPaid:
      service.cost.patientAmount !== null ? `$${service.cost.patientAmount}` : DEFAULT_NA,
  }));

export const filterClaim = (claim) => {
  if (!claim) {
    return null;
  }
  const {
    claimNumber,
    claimType: type,
    claimToDate,
    claimFromDate,
    serviceList: service,
    paymentByMedicare: insurerPaid,
    patientAmount: patientPaid,
    claimProviderNumber: providerNumber,
    paidToProvider,
    identifier,
    condition,
    procedure,
    meta,
  } = claim;

  const getServicesList = (listObj) => {
    if (!listObj?.length) {
      return [];
    }
    const result = {
      codingList: [],
      textList: [],
    };
    for (const listItem of listObj) {
      if (listItem?.coding?.length) {
        for (const codingItem of listItem?.coding) {
          if (codingItem?.display) {
            result.codingList.push(codingItem?.display);
          }
        }
      }
      if (listItem?.text) {
        result.textList.push(listItem?.text);
      }
    }
    const resultantList = result?.codingList?.length ? result.codingList : result.textList;
    return resultantList;
  };

  return {
    claimNumber,
    type,
    providerNumber: providerNumber ?? DEFAULT_NULL_REPLACEMENT,
    insurerPaid: insurerPaid !== null ? `$${insurerPaid}` : DEFAULT_NA,
    patientPaid: patientPaid !== null ? `$${patientPaid}` : DEFAULT_NA,
    serviceDate: `${dateParseV2(claimFromDate)} - ${dateParseV2(claimToDate)}`,
    // services: simplifyClaimsServiceList(service),
    claimFromDate: claimFromDate,
    claimToDate: claimToDate,
    paidToProvider:
      paidToProvider !== 0 && paidToProvider !== null && paidToProvider !== undefined
        ? `$${paidToProvider}`
        : DEFAULT_NULL_REPLACEMENT,
    identifier,
    meta,
    condition: getServicesList(condition),
    procedure: getServicesList(procedure),
  };
};

export const capitalizeFirstLetter = (input) => {
  if (typeof input !== 'string') {
    return;
  }
  const capitalizeFirst = input && input.trim();
  if (capitalizeFirst) {
    if (capitalizeFirst.length === 1) {
      return capitalizeFirst.toUpperCase();
    } else if (capitalizeFirst.length >= 2) {
      return capitalizeFirst.charAt(0).toUpperCase() + capitalizeFirst.slice(1).toLowerCase();
    }
  }
  return '';
};

/**
 * @description : This function logs hansel event in the hansel console
 * @param {String} eventName Name of event you want to log into the hansel console
 * @param {Object} properties Properties you want to associate with the logged event
 */
export const fireHanselEvent = (eventName, properties = {}) => {
  if (!isDependentProfileActiveFromCache() && 'Hansel' in window) {
    Hansel.logEvent(eventName, 'hsl', properties);
  } else if (!isDependentProfileActiveFromCache()) {
    window.addEventListener('pxSdkReady', () => {
      Hansel.logEvent(eventName, 'hsl', properties);
    });
  }
};

const hanselEventsListener = (eventName, eventData) => {
  smartech('dispatch', eventName, eventData);
};

export const registerHanselEventListener = () => {
  if ('Hansel' in window) {
    Hansel.registerListener(hanselEventsListener);
  } else {
    window.addEventListener('pxSdkReady', () => {
      Hansel.registerListener(hanselEventsListener);
    });
  }
};

/**
 * @description : This function calculates Age from Date of Birth
 * @param {String} dateString dateString in UTC format
 */
export const calculateAgeFromDOB = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
  const m = today.getUTCMonth() - birthDate.getUTCMonth();
  if (m < 0 || (m === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
    age--;
  }
  return age;
};

export const handleTimeFilter = (filterVal, totalData, keyName = 'date') => {
  let data = [];
  let dif = 0;
  if (filterVal === 'all') {
    data = totalData;
    return data;
  } else if (filterVal === '6 months') {
    dif = 6 * 30 * 24 * 3600 * 1000;
  } else if (filterVal === '3 months') {
    // check time dif between the current and the record to be within 3 months
    dif = 3 * 30 * 24 * 3600 * 1000;
  } else if (filterVal === '1 month') {
    dif = 1 * 30 * 24 * 3600 * 1000;
  } else if (filterVal === '7 days') {
    dif = 7 * 24 * 3600 * 1000;
  } else if (filterVal === '15 days') {
    dif = 15 * 24 * 3600 * 1000;
  } else if (filterVal === '30 days') {
    dif = 30 * 24 * 3600 * 1000;
  }

  data = totalData.filter((obj) => {
    const timeDif = new Date().getTime() - new Date(obj[keyName]).getTime() || 0;
    if (timeDif > 0 && timeDif <= dif) {
      return true;
    }
    return false;
  });
  return data;
};

export const handleStatusFilter = (filter, dataList, optionsList) => {
  let data = [];
  if (!filter) {
    return data;
  }

  const filterVal = filter && filter.toLowerCase();

  if (filterVal === 'all') {
    data = dataList;
  } else if (filterVal === 'others') {
    if (!optionsList) {
      return [];
    }
    const nonOtherList = [];
    optionsList.forEach((obj) => {
      if (obj.value !== 'all' && obj.value !== 'others') {
        nonOtherList.push(obj.value);
      }
    });
    return dataList.filter(
      (data) => !nonOtherList.includes(data.status ? data.status.toLowerCase() : ''),
    );
  } else {
    data = dataList.filter((obj) => {
      return obj.status.toLowerCase() === filterVal;
    });
  }
  return data;
};

export const handleMedicationStatusFilter = (filter, dataList, optionsList) => {
  let data = [];
  if (!filter) {
    return data;
  }

  const filterVal = filter && filter.toLowerCase();

  if (filterVal === 'all') {
    data = dataList;
  } else if (filterVal === 'inactive') {
    return dataList.filter(
      (data) =>
        data.status === 'inactive' ||
        data.status === 'cancelled' ||
        data.status === 'stopped' ||
        data.status === 'unknown',
    );
  } else if (filterVal === 'others') {
    return dataList.filter(
      (data) =>
        data.status === 'on-hold' ||
        data.status === 'on hold' ||
        data.status === 'completed' ||
        data.status === 'draft' ||
        data.status === 'entered in error' ||
        data.status === 'entered-in-error',
    );
  } else {
    data = dataList.filter((obj) => {
      return obj.status.toLowerCase() === filterVal;
    });
  }
  return data;
};

export const handleClaimTypeFilter = (filterVal, totalData) => {
  let data = [];
  if (filterVal.toLowerCase() === 'all') {
    data = totalData;
    return data;
  }
  const claimLabel = CMS_CLAIMS_TYPE_TEXTS[filterVal];
  data = totalData.filter((obj) => {
    if (claimLabel === CMS_CLAIMS_TYPE_TEXTS['CARRIER']) {
      // professional services
      return obj.claimType === 'CARRIER' || obj.claimType === 'professional';
    }
    return obj.claimType === filterVal;
  });

  return data;
};

export const handleVisitTypeFilter = (filterVal, totalData) => {
  let data = [];
  if (filterVal.toLowerCase() === 'all') {
    data = totalData;
    return data;
  }
  data = totalData.filter((obj) => {
    return obj.typeOfVisitCode === filterVal;
  });

  return data;
};

export const DEFAULT_BP_VALUE = 'N.A';

export const filterByVitalType = (vitalType, items, optionsList) => {
  if (vitalType === 'ALL') {
    return items;
  } else if (vitalType === 'others') {
    if (!optionsList) {
      return [];
    }
    const nonOtherList = [];
    optionsList.forEach((obj) => {
      if (obj.value !== 'All' && obj.value !== 'others') {
        nonOtherList.push(obj.value);
      }
    });

    const newItems = items.map((data) => {
      const newData = { ...data };
      delete newData.date;
      const keys = Object.keys(newData);
      keys.forEach((val) => {
        if (nonOtherList.includes(val)) {
          delete newData[val];
        }
      });
      if (Object.keys(newData).length > 0) {
        newData.date = data.date;
      }
      return newData;
    });

    const returnItems = newItems.filter((obj) => Object.keys(obj).length);

    return returnItems;
  }

  const filtered = [];

  for (const item of items) {
    const matched = item[vitalType];

    if (matched) {
      filtered.push({
        date: item.date,
        [vitalType]: matched,
      });
    }
  }

  return filtered;
};

const getBPValues = (component = [], time) => {
  if (!component) {
    return {
      low: DEFAULT_BP_VALUE,
      high: DEFAULT_BP_VALUE,
      time: time,
    };
  }
  const [firstItem, secondItem] = component;
  const firstVal = firstItem && firstItem.valueQuantity && firstItem.valueQuantity.value;
  const secondVal = secondItem && secondItem.valueQuantity && secondItem.valueQuantity.value;
  const isFirstItemLow =
    firstItem &&
    firstItem.code &&
    firstItem.code.coding.length &&
    firstItem.code.coding[0].code &&
    firstItem.code.coding[0].code === VITAL_FHIR_CODES.DIASTOLIC_BLOOD_PRESSURE;

  return {
    low: isFirstItemLow ? firstVal : secondVal || DEFAULT_BP_VALUE,
    high: isFirstItemLow ? secondVal : firstVal || DEFAULT_BP_VALUE,
    time,
  };
};

const findVitalType = (coding = []) => {
  const found = coding.find((item) => Boolean(VITAL_REVERSE_LOOKUP_CODES[item.code]));

  const vitalType = coding.find((item) => item.code || item.display) || {};

  return (found && VITAL_REVERSE_LOOKUP_CODES[found.code]) || vitalType.display || '';
};

export const buildSimplifiedVitalsData = (vitals) => {
  const simplified = [];

  if (!vitals) {
    return simplified;
  }

  for (const vital of vitals) {
    const { collectedDate, vitalsList } = vital;
    const date = new Date(collectedDate);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const time = `${hours}:${minutes}`;
    const parsedDate = dateParse(collectedDate, false);
    const toPush = {};

    for (const currentVital of vitalsList) {
      const {
        id,
        code: { coding },
        valueQuantity,
        valueString,
        component,
      } = currentVital;
      const vitalType = findVitalType(coding);
      const valueStringToArray = (valueString && valueString.split(':')) || [];
      const extractedValueFromString =
        valueStringToArray.length > 0 &&
        valueStringToArray[0].replaceAll('[', '').replaceAll(']', '');
      const extractedUnitFromString =
        valueStringToArray.length > 1 &&
        valueStringToArray[1].replaceAll('[', '').replaceAll(']', '');

      // if there are two items of same `vitalType` then use only one
      if (vitalType && !toPush[vitalType]) {
        const value =
          vitalType === VITAL_TYPES.BLOOD_PRESSURE ||
          vitalType === VITAL_TYPES.BLOOD_PRESSURE_CHILDREN
            ? getBPValues(component, time)
            : valueQuantity && valueQuantity.value;
        if (value || extractedValueFromString) {
          if (!toPush['date']) {
            toPush['date'] = parsedDate;
          }
        } else {
          continue;
        }
        const unit =
          vitalType === VITAL_TYPES.BLOOD_PRESSURE ||
          vitalType === VITAL_TYPES.BLOOD_PRESSURE_CHILDREN
            ? component &&
              component.length &&
              component[0].valueQuantity &&
              component[0].valueQuantity.unit
            : valueQuantity && valueQuantity.unit;

        const item = {
          value: value || extractedValueFromString,
          unit: unit || extractedUnitFromString || '-',
          id,
          time,
        };

        toPush[vitalType] = item;
      }
    }

    if (toPush.date) {
      simplified.push(toPush);
    }
  }
  return simplified;
};

export const fetchStatusObj = (statusValue, color = null) => {
  const formattedStatus = formatStatus(statusValue);
  const statusObj = STATUS_COLOR_LIST.find(
    (obj) => obj.key?.toLowerCase() === formattedStatus?.toLowerCase(),
  );
  const res = {
    color: statusObj?.color || color,
    status: statusObj?.value || statusObj?.key || convertStrToTitleCase(formattedStatus) || '-',
  };
  return res;
};

export const statusIconColor = (statusValue) => {
  const formattedStatus = formatStatus(statusValue);
  const statusObj = STATUS_COLOR_LIST.find(
    (obj) => obj.key.toLowerCase() === formattedStatus.toLowerCase(),
  );
  if (statusObj && statusObj.color) {
    return statusObj.color;
  }
  return '#FFFFFF';
};
export const displayStatusText = (statusValue) => {
  const formattedStatus = formatStatus(statusValue);
  const statusObj = STATUS_COLOR_LIST.find(
    (obj) => obj.key.toLowerCase() === formattedStatus.toLowerCase(),
  );
  if (statusObj && statusObj.key) {
    return statusObj.key;
  }
  return '-';
};

export const findDataSource = (identifierArray = []) => {
  if (!identifierArray) {
    return '';
  }
  const systemUrlArray = [];
  let dataSource = '';
  identifierArray.forEach((obj) => systemUrlArray.push(obj.system));
  if (!systemUrlArray.length) {
    return '';
  }

  const dataSourceKeys = Object.keys(DATA_SOURCE);

  // systemUrlArray.map(url => {
  //   if(url && !dataSource){
  //     return dataSourceKeys.forEach((partnerName) => {
  //       if(DATA_SOURCE[partnerName].includes(url)){
  //         dataSource = partnerName;
  //       }
  //     });
  //   }
  // });

  identifierArray &&
    Array.isArray(identifierArray) &&
    identifierArray.forEach((obj) => {
      if (obj.system && !dataSource) {
        return dataSourceKeys.forEach((partnerName) => {
          if (DATA_SOURCE[partnerName].includes(obj.system)) {
            dataSource = partnerName;
          } else if (obj.system === 'data_source') {
            dataSource = obj.value;
          }
        });
      }
    });

  return dataSource.toUpperCase();
};

export const searchDataByKeyForCoverage = (data, key, text) => {
  if (!key || !text) {
    return data;
  } else {
    const listofObj = Object.entries(COVERAGE_PLANS_FULL_NAMES).filter((obj) =>
      obj[1].toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );
    const finaldata = data.filter((obj, i) => listofObj.find((val) => val[0] === obj.planType));
    return finaldata;
  }
};

export const searchDataByKey = (data, key, text) => {
  const txt = text.toLocaleLowerCase();
  if (data && txt && !key) {
    return data.filter((obj) => obj.facility.toLocaleLowerCase().includes(txt));
  }
  if (!key || !txt) {
    return data;
  }
  return data.filter((obj) => {
    if (obj[key]) {
      return obj[key].toLocaleLowerCase().includes(txt);
    } else {
      return false;
    }
  });
};

export const searchDataByKeyInConsent = (data, key, text) => {
  const txt = text.toLocaleLowerCase();
  if (data && txt && !key) {
    return data.filter((obj) => obj[key].Name.toLocaleLowerCase().includes(txt));
  }
  if (!key || !txt) {
    return data;
  }
  return data.filter((obj) => {
    if (obj[key]) {
      return obj[key].Name.toLocaleLowerCase().includes(txt);
    } else {
      return false;
    }
  });
};

export const getJsonInfoFromReference = (str) => {
  if (!str) {
    return '';
  }
  try {
    const res = JSON.parse(str);
    return res;
  } catch (err) {
    return {};
  }
};

export const getPartnerName = (urlValue) => {
  if (!urlValue) {
    return '';
  }
  return urlValue.replaceAll('-', ' ');
};

export const getPartnerValue = (name) => {
  if (!name) {
    return '';
  }
  return name.replaceAll(' ', '-');
};

export const convertStrToTitleCase = (str) => {
  if (!str) {
    return '-';
  }
  const newStr = str
    ?.split(' ')
    .map((w) => {
      const word = w?.trim();
      if (word?.length > 1) {
        return word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase();
      } else if (word?.length === 1) {
        return word[0]?.toUpperCase();
      }
      return '';
    })
    .join(' ');
  return newStr;
};

export const extractValueFromCoding = (item) => {
  if (item) {
    return Array.isArray(item.coding) && item.coding.length
      ? item.coding[0].display || item.text || item.coding[0].code
      : item.text || null;
  }
  return item;
};
export const extractOnlyNameFromCode = (item) => {
  if (item) {
    return Array.isArray(item.coding) && item.coding.length
      ? item.coding[0].display || item.text
      : item.text || null;
  }
  return item;
};
export const extractOnlyCodeFromCodeObj = (item) => {
  if (item) {
    return Array.isArray(item.coding) && item.coding.length ? item.coding[0].code : null;
  }
  return item;
};

export const extractValueFromReference = (item) => {
  if (item) {
    return item?.display;
  }
  return null;
};

export const extractCode = (item) =>
  item && Array.isArray(item.coding) && item.coding.length ? item.coding[0].code : null;

export const extractValueFromRefDisplayJson = (data, key) => {
  if (!data || !data[key]) {
    return '';
  }
  const val = data[key].replaceAll('[', '').replaceAll(']', '') || '';
  if (val === 'null') {
    return '';
  }
  return val;
};

export const formatStatus = (value) => {
  if (!value) {
    return value;
  }
  return value.replaceAll('-', ' ').replaceAll('_', ' ');
};

export const removeBrackets = (value) => {
  if (!value) {
    return value;
  }
  return value.toString().replaceAll('[', '').replaceAll(']', '');
};

export const calculateMedicationEndDate = (authoredOn, quantityDispensed) => {
  if (!authoredOn || !quantityDispensed) {
    return null;
  }
  const authoredOnTs = new Date(authoredOn).getTime();
  const dispensedDaysTs = quantityDispensed * 24 * 3600 * 1000;
  const finalTs = authoredOnTs + dispensedDaysTs;
  const endDate = new Date(finalTs);
  return endDate;
};

const codeMedication = (code, medication) => {
  if (code && medication && code !== 'undefined' && medication !== 'undefined') {
    return code + DEFAULT_NULL_REPLACEMENT + medication;
  } else if (medication && medication !== 'undefined') {
    return medication;
  } else if (code) {
    return code;
  } else {
    return SELF_REPORTING_TEXTS.MEDICATION;
  }
};

export const medicationArrayConverter = (array) => {
  const formattedRes =
    array &&
    array.map((arrayItem) => {
      const extractedData = extractMedicationData(arrayItem);
      const {
        id,
        recordedOn,
        medication,
        status,
        frequency,
        dosage,
        dataSource,
        resourceType,
        code,
      } = extractedData;

      return {
        id,
        recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
        medication: medication || DEFAULT_NULL_REPLACEMENT,
        status: (status && formatStatus(status)) || DEFAULT_NULL_REPLACEMENT,
        frequency:
          (frequency && getMedicationFrequencyLabel(frequency)) || DEFAULT_NULL_REPLACEMENT,
        dosage: (dosage && dosage?.replace(/[{}]/g, '')) || DEFAULT_NULL_REPLACEMENT,
        dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
        resourceType: resourceType,
        [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.MEDICATION)]: codeMedication(code, medication),
        code: code,
      };
    });
  return formattedRes?.filter((item) => item && item);
};

export const historicalRecordCardDataFormatter = (template, data) => {
  const list = [];
  for (const listItem of data) {
    const resObj = {
      id: listItem?.id,
    };
    for (const headerItem of template) {
      // resObj[headerItem?.objectKeyForUI] = getValueFromSourceList(listItem, headerItem);
      resObj[headerItem?.objectKeyForUI] = getListValuesFromSource(listItem, headerItem);
    }
    list.push(resObj);
  }
  return list?.filter((val) => val && val);
};

export const getValueObjFromExtension = (extensionArr, url) => {
  if (!extensionArr || !Array.isArray(extensionArr) || !url) {
    return '';
  }
  const resObj = extensionArr.find((obj) => obj.url === url);
  return resObj || {};
};

export const extractMedicationData = (item) => {
  const medicationRefJson = item.medicationReference
    ? getJsonInfoFromReference(extractValueFromReference(item.medicationReference))
    : {};

  const dataSource = findDataSource(item.identifier);

  const checkIfAhk = dataSource && dataSource.toLowerCase().includes('apple');

  const dosage =
    item.dosage && Array.isArray(item.dosage) && item.dosage.length
      ? item.dosage[0]
      : item.dosageInstruction &&
        Array.isArray(item.dosageInstruction) &&
        item.dosageInstruction.length
      ? item.dosageInstruction[0]
      : {};
  const dosageExtension =
    dosage && dosage.extension
      ? getValueObjFromExtension(
          dosage.extension,
          'https://open.epic.com/fhir/extensions/admin-amount',
        ).valueQuantity
        ? getValueObjFromExtension(
            dosage.extension,
            'https://open.epic.com/fhir/extensions/admin-amount',
          )
        : getValueObjFromExtension(
            dosage.extension,
            'https://open.epic.com/fhir/extensions/ordered-dose',
          )
      : {};

  const typeOfMedication =
    dosageExtension && dosageExtension.valueQuantity ? dosageExtension.valueQuantity.unit : null;

  return {
    id: item.id,
    recordedOn: item.authoredOn || (item.effectivePeriod && item.effectivePeriod.start),
    dataSource: dataSource,
    medication:
      extractOnlyNameFromCode(item.medicationCodeableConcept) ||
      extractValueFromRefDisplayJson(medicationRefJson, 'name') ||
      (checkIfAhk && extractValueFromReference(item.medicationReference)),
    status: item.status,
    prescriber:
      (checkIfAhk && extractValueFromReference(item.informationSource)) ||
      (item.recorder && extractValueFromReference(item.recorder)) ||
      (item.performer &&
        item.performer.length &&
        extractValueFromReference(item.performer[0].actor)),
    pharmacy: item.encounter
      ? extractValueFromReference(item.encounter)
      : item.context
      ? extractValueFromReference(item.context)
      : item.location && extractValueFromReference(item.location),
    dosage:
      item.dosageInstruction && item.dosageInstruction.length
        ? item.dosageInstruction[0].doseAndRate &&
          item.dosageInstruction[0].doseAndRate.length &&
          item.dosageInstruction[0].doseAndRate[0].doseQuantity &&
          item.dosageInstruction[0].doseAndRate[0].doseQuantity.value
          ? `${item.dosageInstruction[0].doseAndRate[0].doseQuantity.value} ${
              item.dosageInstruction[0].doseAndRate[0].doseQuantity.unit || ''
            }`
          : item.dosageInstruction[0].text ||
            extractValueFromCoding(item.dosageInstruction[0].method)
        : dosage.text,
    recordedBy:
      item.performer && item.performer.display
        ? extractValueFromReference(item.performer)
        : item.performer && item.performer.actor
        ? extractValueFromReference(item.performer.actor)
        : extractValueFromReference(item.informationSource),
    startDate:
      (item.dosageInstruction &&
        item.dosageInstruction.length &&
        item.dosageInstruction[0].timing &&
        item.dosageInstruction[0].timing.repeat &&
        item.dosageInstruction[0].timing.repeat.boundsPeriod &&
        item.dosageInstruction[0].timing.repeat.boundsPeriod.start) ||
      (item.effectivePeriod && item.effectivePeriod.start) ||
      item.authoredOn,
    endDate:
      (item.authoredOn &&
        item.dispenseRequest &&
        item.dispenseRequest.quantity &&
        item.dispenseRequest.quantity.value &&
        calculateMedicationEndDate(item.authoredOn, item.dispenseRequest.quantity.value)) ||
      (item.effectivePeriod && item.effectivePeriod.end) ||
      item.authoredOn,
    code:
      extractCode(item.medicationCodeableConcept) ||
      extractValueFromRefDisplayJson(medicationRefJson, 'code'),
    recordedBy:
      (checkIfAhk && extractValueFromReference(item.informationSource)) ||
      (item.performer && item.performer.display && extractValueFromReference(item.performer)) ||
      (item.receiver && item.receiver.length && extractValueFromReference(item.receiver[0])) ||
      extractValueFromReference(item.informationSource),
    manufacturer:
      removeBrackets(medicationRefJson['manufacturer']) ||
      getValueFromExtension(item.extension, 'manufacturer'),
    ingredients:
      removeBrackets(medicationRefJson['ingredient']) ||
      removeBrackets(getValueFromExtension(item.extension, 'ingredients')),
    note: extractNote(item.note),
    frequency:
      (dosage.timing && dosage.timing.repeat && dosage.timing.repeat.frequency) ||
      (dosage.timing && dosage.timing.code && dosage.timing.code.text) ||
      null,
    requestedBy:
      (checkIfAhk && extractValueFromReference(item.informationSource)) ||
      (item.requester && extractValueFromReference(item.requester)) ||
      (item.receiver && item.receiver.length > 0 && extractValueFromReference(item.receiver[0])) ||
      extractValueFromReference(item.informationSource),
    type:
      extractValueFromRefDisplayJson(medicationRefJson, 'form') ||
      getValueFromExtension(item.extension, 'form') ||
      typeOfMedication ||
      null,
    resourceType: item.resourceType || item.__typename,
    originalObject: item,
    restricted:
      item.meta && item.meta.security && item.meta.security.length
        ? extractRestricted(item.meta.security)
        : false,
    documents: item.docArn,
    documentReferenceId: item.documentReferenceId,
  };
};

export const medicationDetailsFormatter = (medicationObj, hideDataSource = false) => {
  const {
    id,
    medication,
    type,
    dataSource,
    status,
    recordedOn,
    dosage,
    recordedBy,
    prescriber,
    code,
    startDate,
    endDate,
    ingredients,
    frequency,
    manufacturer,
    requestedBy,
    restricted,
    documents,
    documentReferenceId,
  } = extractMedicationData(medicationObj);
  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.MEDICATION_NAME_KEY]: medication,
    [SELF_REPORTED_VALUE_KEYS.MEDICATION_TYPE_KEY]: type,
    [SELF_REPORTED_VALUE_KEYS.FREQUENCY_KEY]: frequency,
    [SELF_REPORTED_VALUE_KEYS.DOSAGE_KEY]: (dosage && dosage.replace(/[{}]/g, '')) || '',
    [SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY]: recordedOn,
    [SELF_REPORTED_VALUE_KEYS.START_DATE_KEY]: startDate,
    [SELF_REPORTED_VALUE_KEYS.END_DATE_KEY]: endDate,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy,
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status,
    [SELF_REPORTED_VALUE_KEYS.PRESCRIBER_KEY]: prescriber,
    [SELF_REPORTED_VALUE_KEYS.MANUFACTURER_KEY]: manufacturer,
    [SELF_REPORTED_VALUE_KEYS.INGREDIENTS_KEY]: ingredients,
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
    code: code === 'undefined' || code ? code : '',
    documentReferenceId: documentReferenceId,
  };

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
      show_status_icon: true,
    },
    {
      column_name: 'Recorded on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || null,
    },
    {
      column_name: 'Type of medication',
      value: (type && capitalizeFirstLetter(type)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Dosage',
      value: (dosage && dosage.replace(/[{}]/g, '')) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: '',
      value: '',
    },
    {
      column_name: 'Recorded by',
      value: recordedBy || '',
    },
    {
      column_name: 'Prescriber',
      value: prescriber || '',
    },
    {
      column_name: 'Code',
      value: code || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Start date',
      value: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'End date',
      value: (endDate && getCommaSeparatedDate(endDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Ingredients',
      value: ingredients || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Frequency',
      value: (frequency && getMedicationFrequencyLabel(frequency)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Manufacturer',
      value: manufacturer || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Requested by',
      value: requestedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
  ];

  return {
    modalData: modalData,
    editData: editData,
  };
};

export const extractPlanTypeFromClassTypeArray = (coding) => {
  if (!coding || coding.length === 0) {
    return '';
  }
  const typeObj = coding.find((obj) => {
    if (obj.type) {
      return obj;
    } else {
      return null;
    }
  });
  const typeValue = typeObj && typeObj.type;
  return typeValue || null;
};
export const extractPlanNameFromClassTypeArray = (coding, name) => {
  if (!coding || coding.length === 0) {
    return '';
  }

  const typeObj =
    name === 'EHEALTH' &&
    coding.find((obj) => {
      if (obj.type && obj.type !== null && obj.type.text) {
        return obj;
      } else {
        return null;
      }
    });
  const text = typeObj && typeObj.type && typeObj.type.text;
  const nameObj =
    name !== 'EHEALTH' &&
    coding.find((obj) => {
      if (obj && obj.name) {
        return obj;
      } else {
        return null;
      }
    });
  const nm = nameObj && nameObj.name;

  return text || nm || null;
};

export const getValueFromIdentifierArray = (identifierArr, key) => {
  if (!identifierArr || !Array.isArray(identifierArr) || !key) {
    return '';
  }
  const resObj = identifierArr.find((obj) => obj.system === key);
  return (resObj && resObj.value) || '';
};

export const getValueFromMoneyArr = (arr) => {
  if (!arr || !Array.isArray(arr) || arr.length === 0) {
    return '';
  }

  if (arr[0] && arr[0]['valueMoney']) {
    return arr[0]['valueMoney']['value'];
  }
  if (arr[0] && arr[0]['valueQuantity']) {
    return arr[0]['valueQuantity']['value'];
  }
  return '';
};

export const getValueFromExtension = (extensionArr, url) => {
  if (!extensionArr || !Array.isArray(extensionArr) || !url) {
    return '';
  }
  const resObj = extensionArr.find((obj) => obj.url === url);
  return (resObj && resObj.valueString) || '';
};

export const extractCoverageData = (coverageObj) => {
  const policyHolder =
    coverageObj.policyHolder &&
    getJsonInfoFromReference(extractValueFromReference(coverageObj.policyHolder));
  const planType = extractPlanTypeFromClassTypeArray(coverageObj.class);
  const coverageType = extractValueFromCoding(coverageObj.type);
  const coverageSource = findDataSource(coverageObj.identifier);
  const planName = extractPlanNameFromClassTypeArray(coverageObj.class, coverageSource);

  const benifitsFlyoutData = {};
  if (coverageObj?.extension) {
    for (const extensionObj of coverageObj?.extension) {
      if (extensionObj?.url === 'plan_benifits') {
        benifitsFlyoutData[extensionObj?.url] = JSON.parse(extensionObj?.valueString);
      } else {
        benifitsFlyoutData[extensionObj?.url] = extensionObj?.valueString;
      }
    }
  }
  return {
    id: coverageObj.id,
    coverage: COVERAGE_PLANS_FULL_NAMES[planName] || planName,
    status: coverageObj.status,
    recordedOn: coverageObj.period && coverageObj.period.start,
    policyNumber: getValueFromIdentifierArray(coverageObj.identifier, 'Policy-Number'), // get from identifier
    subscriberId: coverageObj.subscriberId,
    planType:
      planType &&
      ((planType.coding ? planType.coding[0].display || planType.coding[0].code : null) ||
        planType.text),
    planName: planName,
    coverageType: (coverageObj.type && coverageObj.type.text) || coverageType,
    startDate: coverageObj.period && coverageObj.period.start,
    expiryDate: coverageObj.period && coverageObj.period.end,
    totalCost: getValueFromMoneyArr(coverageObj.costToBeneficiary),
    contactName: policyHolder && policyHolder['contact-name'],
    contactPhoneNumber: policyHolder && policyHolder['contact-phone'],
    contactAddress: policyHolder && policyHolder['contact-address'],
    issuerOrganization:
      coverageObj.payor &&
      coverageObj.payor.length > 0 &&
      extractValueFromReference(coverageObj.payor[0]),
    planId: getValueFromIdentifierArray(coverageObj.identifier, 'Plan-Id'),
    subscriber: coverageObj.subscriber && extractValueFromReference(coverageObj.subscriber),
    relationship:
      coverageObj.relationship &&
      (extractValueFromCoding(coverageObj.relationship) || coverageObj.relationship.text),
    note: getValueFromExtension(
      coverageObj.extension,
      'http://hl7.org/fhir/StructureDefinition/valueset-concept-comments',
    ),
    hotLink: getValueFromExtension(coverageObj.extension, 'hotlink'),
    restricted:
      coverageObj.meta && coverageObj.meta.security && coverageObj.meta.security.length
        ? extractRestricted(coverageObj.meta.security)
        : false,
    documents: coverageObj.docArn,
    documentReferenceId: coverageObj.documentReferenceId,
    dataSource: coverageSource,
    benifitsFlyoutData,
  };
};

export const coveragesArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const {
      id,
      coverage,
      policyNumber,
      status,
      startDate,
      expiryDate,
      dataSource,
      hotLink,
      benifitsFlyoutData,
    } = extractCoverageData(arrayItem);
    return {
      id,
      coverage: coverage || DEFAULT_NULL_REPLACEMENT,
      policyNumber: policyNumber || DEFAULT_NULL_REPLACEMENT,
      status: status
        ? status.toLocaleLowerCase() === 'draft' &&
          dataSource &&
          dataSource.toLowerCase() === 'ehealth'
          ? 'pending'
          : status
        : DEFAULT_NULL_REPLACEMENT,
      startDate: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
      expiryDate: (expiryDate && getCommaSeparatedDate(expiryDate)) || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
      hideViewDetails: (dataSource && dataSource.toLocaleLowerCase() === 'ehealth') || false,
      startDateTs: startDate,
      expiryDateTs: expiryDate,
      hotLink,
      benifitsFlyoutData,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const coverageDetailsDataFormatter = (result, hideDataSource = false) => {
  const {
    id,
    status,
    recordedOn,
    policyNumber,
    planId,
    planType,
    coverageType,
    relationship,
    startDate,
    expiryDate,
    planName,
    subscriberId,
    subscriber,
    totalCost,
    contactName,
    contactPhoneNumber,
    contactAddress,
    issuerOrganization,
    note,
    documents,
    restricted,
    dataSource,
    documentReferenceId,
  } = extractCoverageData(result);
  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
    },
    // {
    //   column_name: 'Coverage Details',
    //   showSubtitle: true,
    // },
    {
      column_name: 'Policy number',
      value: policyNumber || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Subscriber id',
      value: subscriberId || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Subscriber name',
      value: subscriber || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Relationship',
      value: relationship || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Plan type',
      value: planType || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Plan name',
      value: planName || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Plan id',
      value: planId || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Coverage type',
      value: coverageType || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Issuer organization',
      value: issuerOrganization || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Start date',
      value: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'End date',
      value: (expiryDate && getCommaSeparatedDate(expiryDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Total amount',
      value: (totalCost && `$${totalCost}`) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Contact name',
      value: contactName || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Contact phone number',
      value: contactPhoneNumber || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Contact address',
      value: formatAddressString(contactAddress) || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
  ];

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.POLICY_NUMBER_KEY]: policyNumber || '',
    [SELF_REPORTED_VALUE_KEYS.PLAN_TYPE_KEY]: planType || '',
    [SELF_REPORTED_VALUE_KEYS.PLAN_ID_KEY]: planId || '',
    [SELF_REPORTED_VALUE_KEYS.COVERAGE_TYPE_KEY]: coverageType || '',
    [SELF_REPORTED_VALUE_KEYS.RELATIONSHIP_KEY]: relationship || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: (status && status.toUpperCase()) || '',
    [SELF_REPORTED_VALUE_KEYS.SUBSCRIBER_ID_KEY]: subscriberId,
    [SELF_REPORTED_VALUE_KEYS.START_DATE_KEY]: startDate || null,
    [SELF_REPORTED_VALUE_KEYS.END_DATE_KEY]: expiryDate || null,
    [SELF_REPORTED_VALUE_KEYS.HEALTH_PLAN_NAME_KEY]: planName || '',
    [SELF_REPORTED_VALUE_KEYS.ISSUER_ORGANIZATION_KEY]: issuerOrganization || '',
    [SELF_REPORTED_VALUE_KEYS.TOTAL_AMOUNT_KEY]: totalCost || '',
    [SELF_REPORTED_VALUE_KEYS.SUBSCRIBER_KEY]: subscriber || '',
    [SELF_REPORTED_VALUE_KEYS.CONTACT_NAME_KEY]: contactName || '',
    [SELF_REPORTED_VALUE_KEYS.CONTACT_PHONE_NUMBER_KEY]: contactPhoneNumber || '',
    [SELF_REPORTED_VALUE_KEYS.CONTACT_ADDRESS_KEY]: contactAddress || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note || '',
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };
  return {
    modalData: modalData,
    editData: editData,
  };
};

export const conditionsArrayConverter = (array) => {
  const formattedRes = array?.map((arrayItem) => {
    const { id, recordedOn, condition, status, severity, dataSource, code } =
      extractConditionData(arrayItem);

    return {
      id,
      recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
      condition: condition || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.CONDITION)]:
        code && condition ? `${code} - ${condition}` : condition || code,
      status: status || DEFAULT_NULL_REPLACEMENT,
      severity: (severity && convertStrToTitleCase(severity)) || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
      id: arrayItem.id,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const extractConditionData = (conditionObj) => {
  return {
    id: conditionObj.id,
    recordedOn: conditionObj.recordedDate || undefined,
    condition: extractValueFromCoding(conditionObj.code),
    status: formatStatus(extractValueFromCoding(conditionObj.clinicalStatus)),
    recordedBy: extractValueFromReference(conditionObj.asserter || conditionObj.recorder),
    facility: extractValueFromReference(conditionObj.encounter),
    category:
      (conditionObj.category &&
        conditionObj.category.length &&
        extractValueFromCoding(conditionObj.category[0])) ||
      '',
    code: extractCode(conditionObj.code),
    severity: extractValueFromCoding(conditionObj.severity),
    note: extractNote(conditionObj.note),
    dataSource: findDataSource(conditionObj.identifier),
    summary:
      conditionObj && conditionObj.stage && conditionObj.stage.length
        ? extractValueFromCoding(conditionObj.stage[0].summary) ||
          extractValueFromReference(conditionObj.stage[0].assessment)
        : null,
    restricted:
      conditionObj.meta && conditionObj.meta.security && conditionObj.meta.security.length
        ? extractRestricted(conditionObj.meta.security)
        : false,
    documents: conditionObj.docArn,
    documentReferenceId: conditionObj.documentReferenceId,
  };
};

export const conditionDetailsDataFormatter = (result, hideDataSource = false) => {
  const {
    id,
    condition,
    recordedOn,
    note,
    status,
    recordedBy,
    dataSource,
    facility,
    category,
    code,
    severity,
    summary,
    documents,
    restricted,
    documentReferenceId,
  } = extractConditionData(result);

  const getFormattedCategoryForEditSelectionBox = (value) => {
    if (!value) {
      return null;
    }
    const category = CONDITION_CATEGORY.find(
      (val) => val.value?.toLowerCase() === value?.toLowerCase()?.replaceAll('_', ' '),
    );
    if (category?.label) {
      return category.label;
    }
  };

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded by',
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Facility',
      value: (facility && removeBrackets(facility)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Category',
      value:
        (category && getFormattedCategoryForEditSelectionBox(category)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Code',
      value: code || DEFAULT_NULL_REPLACEMENT,
    },

    {
      column_name: 'Severity',
      value: (severity && convertStrToTitleCase(severity)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Summary',
      value: summary || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
  ];

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.CONDITION_NAME_KEY]: condition || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status,
    [SELF_REPORTED_VALUE_KEYS.FACILITY_KEY]: facility,
    [SELF_REPORTED_VALUE_KEYS.CATEGORY_KEY]: getFormattedCategoryForEditSelectionBox(category),
    [SELF_REPORTED_VALUE_KEYS.CODE_KEY]: code,
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note,
    [SELF_REPORTED_VALUE_KEYS.SEVERITY_KEY]: severity,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY]: recordedOn || null,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };
  return {
    modalData: modalData,
    editData: editData,
  };
};

export const allergiesArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const { id, recordedOn, allergicTo, status, dataSource, criticality, code, startTime } =
      extractAllergyData(arrayItem);

    return {
      id,
      recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
      allergicTo: allergicTo || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.ALLERGIC_TO)]:
        code && allergicTo ? `${code} - ${allergicTo}` : allergicTo || code,
      status: status || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
      criticality:
        (criticality && extractAllergyCriticality(criticality)) || DEFAULT_NULL_REPLACEMENT,
      startTime,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const removeDuplicateItem = (previousData, data) => {
  return data?.filter((item) => item.id !== previousData?.filter((val) => val).id);
};

export const extractAllergyData = (allergyObj) => {
  return {
    id: allergyObj.id,
    dataSource: findDataSource(allergyObj.identifier),
    recordedOn: allergyObj.recordedDate,
    allergicTo: extractValueFromCoding(allergyObj.code),
    recordedBy: extractValueFromReference(allergyObj.recorder),
    status: formatStatus(
      extractValueFromCoding(allergyObj.clinicalStatus || allergyObj.verificationStatus),
    ),
    code: extractCode(allergyObj.code),
    criticality: allergyObj.criticality,
    category: allergyObj.category && allergyObj.category.length && allergyObj.category[0],
    route:
      allergyObj.reaction &&
      allergyObj.reaction.length &&
      extractValueFromCoding(allergyObj.reaction[0].exposureRoute),
    facility:
      extractValueFromReference(allergyObj.facility) ||
      extractValueFromReference(allergyObj.encounter),
    note: extractNote(allergyObj.note),
    reaction: createReactionsArray(allergyObj.reaction),
    restricted:
      allergyObj.meta && allergyObj.meta.security && allergyObj.meta.security.length
        ? extractRestricted(allergyObj.meta.security)
        : false,
    documents: allergyObj.docArn,
    documentReferenceId: allergyObj.documentReferenceId,
    startTime: allergyObj.onsetDateTime
      ? allergyObj.onsetDateTime
      : allergyObj.onsetPeriod && allergyObj.onsetPeriod.start
      ? allergyObj.onsetPeriod.start
      : allergyObj.onsetRange && allergyObj.onsetRange.low
      ? allergyObj.onsetRange.low
      : allergyObj.onsetString
      ? allergyObj.onsetString
      : null,
  };
};

const removeSquareBrackets = (data) => {
  return data?.split('[').join('').split(']').join('');
};

export const allergyDetailsFormatter = (allergyObj, hideDataSource = false) => {
  const {
    id,
    recordedOn,
    allergicTo,
    status,
    recordedBy,
    dataSource,
    facility,
    code,
    criticality,
    category,
    reaction,
    route,
    note,
    restricted,
    documents,
    documentReferenceId,
    startTime,
  } = extractAllergyData(allergyObj);

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded by',
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Facility',
      value: removeSquareBrackets(facility) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Code',
      value: code || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Start time',
      value: (startTime && getCommaSeparatedDate(startTime)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Criticality',
      value: criticality ? extractAllergyCriticality(criticality) : DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Category',
      value: category || DEFAULT_NULL_REPLACEMENT,
      capitalize: true,
    },
    {
      column_name: 'Route',
      value: route || DEFAULT_NULL_REPLACEMENT,
      capitalize: true,
    },
    {
      column_name: 'Reaction',
      value: reaction || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
      capitalize: true,
    },
  ];

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.ALLERGIC_TO_KEY]: allergicTo || '',
    [SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY]: recordedOn || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status || '',
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy || '',
    [SELF_REPORTED_VALUE_KEYS.START_TIME_KEY]: startTime || '',
    [SELF_REPORTED_VALUE_KEYS.FACILITY_KEY]: facility || '',
    [SELF_REPORTED_VALUE_KEYS.CODE_KEY]: code || '',
    [SELF_REPORTED_VALUE_KEYS.CRITICALITY_KEY]: criticality || '',
    [SELF_REPORTED_VALUE_KEYS.CATEGORY_KEY]: category || '',
    [SELF_REPORTED_VALUE_KEYS.ROUTE_KEY]: route || '',
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note || '',
    [SELF_REPORTED_VALUE_KEYS.REACTION_KEY]: reaction || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };

  return {
    modalData: modalData,
    editData: editData,
  };
};

export const extractAllergyCriticality = (val) => {
  if (!val) {
    return null;
  }
  return getAllergyCriticalityLabel(val) || val;
};

export const createReactionsArray = (list) => {
  const res = [];

  if (Array.isArray(list) && list.length) {
    list.forEach((obj) => {
      if (obj && obj.manifestation && obj.manifestation.length) {
        const value = extractValueFromCoding(obj.manifestation[0]);
        if (value) {
          res.push(value);
        }
      } else if (obj && obj.description) {
        res.push(obj.description);
      }
    });

    return res.length > 0 ? res : '';
  }
  return '';
};

export const extractImmunizationReactions = (list) => {
  const resList = [];
  if (Array.isArray(list) && list.length) {
    list.forEach((item) => {
      if (item.detail) {
        const value = extractValueFromReference(item.detail);
        if (value) {
          resList.push(value);
        }
      }
    });
  }
  return resList.length > 0 ? resList : null;
};

export const immunizationsArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const { id, recordedOn, immunization, status, dosage, dataSource, ndcCode } =
      extractImmunizationData(arrayItem);

    return {
      id,
      recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
      immunization: immunization || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.IMMUNIZATION)]:
        ndcCode && immunization && ndcCode !== immunization
          ? `${ndcCode} - ${immunization}`
          : immunization || ndcCode,
      status: status || DEFAULT_NULL_REPLACEMENT,
      dosage: dosage || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const extractImmunizationData = (immunizationObj) => {
  return {
    id: immunizationObj.id,
    recordedOn: immunizationObj.occurrenceDateTime || undefined,
    immunization: extractValueFromCoding(immunizationObj.vaccineCode),
    status: immunizationObj.status,
    recordedBy:
      Array.isArray(immunizationObj.performer) &&
      immunizationObj.performer.length &&
      immunizationObj.performer[0].actor
        ? extractValueFromReference(immunizationObj.performer[0].actor)
        : null,
    facility: immunizationObj.location
      ? extractValueFromReference(immunizationObj.location)
      : immunizationObj.encounter &&
        removeBrackets(extractValueFromReference(immunizationObj.encounter)),
    dataSource: findDataSource(immunizationObj.identifier),
    bodySite: extractValueFromCoding(immunizationObj.site),
    route: extractValueFromCoding(immunizationObj.route),
    dosage:
      immunizationObj.doseQuantity && immunizationObj.doseQuantity.value
        ? `${immunizationObj.doseQuantity.value}${
            immunizationObj.doseQuantity.unit ? immunizationObj.doseQuantity.unit : ''
          }`
        : immunizationObj.doseQuantity && immunizationObj.doseQuantity.code,
    ndcCode: extractCode(immunizationObj.vaccineCode),
    reason:
      immunizationObj.reasonCode && immunizationObj.reasonCode.length
        ? extractValueFromCoding(immunizationObj.reasonCode[0])
        : extractValueFromCoding(immunizationObj.statusReason),
    reaction:
      immunizationObj.reaction && immunizationObj.reaction.length
        ? extractImmunizationReactions(immunizationObj.reaction)
        : null,
    note: extractNote(immunizationObj.note),
    lotNumber: immunizationObj.lotNumber,
    manufacturer: extractValueFromReference(immunizationObj.manufacturer),
    doseNumber:
      immunizationObj.protocolApplied && immunizationObj.protocolApplied.length
        ? immunizationObj.protocolApplied[0].doseNumberPositiveInt ||
          immunizationObj.protocolApplied[0].seriesDosesPositiveInt
        : null,
    expirationDate: immunizationObj.expirationDate || null,
    restricted:
      immunizationObj.meta && immunizationObj.meta.security && immunizationObj.meta.security.length
        ? extractRestricted(immunizationObj.meta.security)
        : false,
    documents: immunizationObj.docArn,
    documentReferenceId: immunizationObj.documentReferenceId,
  };
};

export const immunizationDetailsDataFormatter = (immunizationObj, hideDataSource = false) => {
  const extractedDataById = extractImmunizationData(immunizationObj);

  const {
    id,
    immunization,
    dataSource,
    status,
    recordedOn,
    recordedBy,
    bodySite,
    route,
    dosage,
    ndcCode,
    lotNumber,
    manufacturer,
    expirationDate,
    doseNumber,
    reason,
    reaction,
    facility,
    note,
    restricted,
    documents,
    documentReferenceId,
  } = extractedDataById;

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.IMMUNIZATION_NAME_KEY]: immunization || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status || '',
    [SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY]: recordedOn || '',
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy || '',
    [SELF_REPORTED_VALUE_KEYS.BODY_SITE_KEY]: bodySite || '',
    [SELF_REPORTED_VALUE_KEYS.ROUTE_KEY]: route || '',
    [SELF_REPORTED_VALUE_KEYS.DOSE_KEY]: dosage || '',
    [SELF_REPORTED_VALUE_KEYS.NDC_CODE_KEY]: ndcCode || '',
    [SELF_REPORTED_VALUE_KEYS.VACCINE_LOT_NUMBER_KEY]: lotNumber || '',
    [SELF_REPORTED_VALUE_KEYS.VACCINE_MANUFACTURER_KEY]: manufacturer || '',
    [SELF_REPORTED_VALUE_KEYS.VACCINE_DOSE_NUMBER_KEY]: (doseNumber && doseNumber.toString()) || '',
    [SELF_REPORTED_VALUE_KEYS.VACCINE_EXPIRATION_DATE_KEY]: expirationDate || null,
    [SELF_REPORTED_VALUE_KEYS.REASONS_KEY]: reason || '',
    [SELF_REPORTED_VALUE_KEYS.REACTION_KEY]: reaction || '',
    [SELF_REPORTED_VALUE_KEYS.FACILITY_KEY]: facility || '',
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Administered on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Administered by',
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Body site',
      value: bodySite || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Route',
      value: (route && findImmunizationRouteLabel(route)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Dosage',
      value: dosage || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'NDC code',
      value: ndcCode || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Vaccine lot no',
      value: lotNumber || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Vaccine manufacturer',
      value: manufacturer || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Vaccine dose no.',
      value: doseNumber || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Reason',
      value: reason || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Reaction',
      value: reaction || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Vaccine expiration date',
      value: (expirationDate && getCommaSeparatedDate(expirationDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Facility',
      value: facility || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
  ];

  return {
    editData: editData,
    modalData: modalData,
  };
};

export const labResultArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const { id, recordedOn, testName, status, categoryCode, dataSource, loincCode } =
      extractDiagnosticReportData(arrayItem);

    return {
      id,
      recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
      testName: testName || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.LAB_RESULTS)]:
        loincCode && testName ? `${loincCode} - ${testName}` : testName || loincCode,
      status: status || DEFAULT_NULL_REPLACEMENT,
      category: categoryCode || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

const ifLabResultsValue = (val) => {
  if (!val && val !== 0) {
    return false;
  }
  const invalidStrings = ['-', '<', '<=', '>', '>='];
  if (invalidStrings.some((v) => val.includes(v))) {
    return false;
  } else {
    return true;
  }
};

const ifLabResultsRange = (val) => {
  if (!val) {
    return false;
  }
  const validStrings = ['-', '<', '<=', '>', '>='];
  if (validStrings.some((v) => val.includes(v))) {
    return true;
  } else {
    return false;
  }
};

export const extractDiagnosticReportData = (item) => {
  if (!item) {
    return {};
  }
  const resultsDisplay =
    item.result && item.result.length
      ? getJsonInfoFromReference(item.result[0].display)
      : {
          value: null,
          unit: null,
          interpretation: null,
          referenceRange: null,
          'test-result': null,
        };
  return {
    id: item.id,
    recordedOn: item.effectiveDateTime || (item.effectivePeriod && item.effectivePeriod.start),
    testName: extractValueFromCoding(item.code),
    status: item.status,
    recordedBy:
      item.performer && item.performer.length && extractValueFromReference(item.performer[0]),
    facility:
      item?.encounter?.location?.location?.display ||
      item?.encounter?.location?.location?.name ||
      item?.encounter?.display ||
      DEFAULT_NULL_REPLACEMENT,
    restricted:
      item.meta && item.meta.security && item.meta.security.length
        ? extractRestricted(item.meta.security)
        : false,
    dataSource: findDataSource(item.identifier),
    issuedDate: item.issued,
    issuerOfTheReport:
      item.resultsInterpreter &&
      item.resultsInterpreter.length &&
      extractValueFromReference(item.resultsInterpreter[0]),
    categoryCode: item.category && item.category.length && extractValueFromCoding(item.category[0]),
    loincCode: extractCode(item.code),
    note: item.conclusion,
    testResults: resultsDisplay && resultsDisplay.interpretation,
    value:
      (resultsDisplay && resultsDisplay.value) ||
      (resultsDisplay &&
        ifLabResultsValue(resultsDisplay['test-result']) &&
        resultsDisplay['test-result']),
    units: resultsDisplay && resultsDisplay.unit,
    referenceRange:
      (resultsDisplay &&
        resultsDisplay.referenceRange &&
        resultsDisplay.referenceRange !== '[]' &&
        resultsDisplay.referenceRange) ||
      (resultsDisplay &&
        ifLabResultsRange(resultsDisplay['test-result']) &&
        resultsDisplay['test-result']),
    documents: item.docArn,
    documentReferenceId: item.documentReferenceId,
  };
};

export const diagnosticReportDetailsFormatter = (dataObject, hideDataSource = false) => {
  const {
    id,
    recordedBy,
    recordedOn,
    facility,
    testName,
    status,
    restricted,
    value,
    units,
    referenceRange,
    testResults,
    categoryCode,
    loincCode,
    issuedDate,
    issuerOfTheReport,
    note,
    dataSource,
    documents,
    documentReferenceId,
  } = extractDiagnosticReportData(dataObject);

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY]: recordedOn || null,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy || '',
    [SELF_REPORTED_VALUE_KEYS.FACILITY_KEY]: facility || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status || '',
    [SELF_REPORTED_VALUE_KEYS.DIAGNOSTIC_REPORT_NAME_KEY]: testName || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    [SELF_REPORTED_VALUE_KEYS.VALUE_KEY]: (value && removeBrackets(value)) || '',
    [SELF_REPORTED_VALUE_KEYS.UNITS_KEY]: units || '',
    [SELF_REPORTED_VALUE_KEYS.REFERENCE_RANGE_KEY]:
      (referenceRange && removeBrackets(referenceRange)) || '',
    [SELF_REPORTED_VALUE_KEYS.TEST_RESULTS_KEY]:
      (testResults && removeBrackets(testResults)) ||
      calculateLabResultLabel(
        referenceRange && removeBrackets(referenceRange),
        value && removeBrackets(value),
      ) ||
      '',
    [SELF_REPORTED_VALUE_KEYS.CATEGORY_CODE_KEY]: categoryCode || '',
    [SELF_REPORTED_VALUE_KEYS.LOINC_CODE_KEY]: loincCode || '',
    [SELF_REPORTED_VALUE_KEYS.ISSUED_DATE_KEY]: issuedDate || '',
    [SELF_REPORTED_VALUE_KEYS.ISSUED_TIME_KEY]: issuedDate || '', // issuedTime is not stored in db. So that value is issuedDate
    [SELF_REPORTED_VALUE_KEYS.ISSUER_OF_REPORT_KEY]: issuerOfTheReport || '',
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note || '',
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded on',
      value: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded by',
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Reference range',
      value: (referenceRange && removeBrackets(referenceRange)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Facility',
      value: facility || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Lab result',
      value:
        (testResults && removeBrackets(testResults)) ||
        calculateLabResultLabel(
          referenceRange && removeBrackets(referenceRange),
          value && removeBrackets(value),
        ) ||
        DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Issued time',
      value: (issuedDate && getCommaSeparatedDateWithTime(issuedDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Issuer of the report',
      value: issuerOfTheReport || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Category code',
      value: categoryCode || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'LOINC code',
      value: loincCode || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
      width: 'fullwidth',
    },
  ];

  return {
    modalData,
    editData,
  };
};

export const proceduresArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const { id, startDate, procedure, status, dataSource, outcome, code } =
      extractProcedureData(arrayItem);

    return {
      id,
      startDate: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
      recordedOn: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
      procedure: procedure || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.PROCEDURE)]:
        code && procedure ? `${code} - ${procedure}` : procedure || code,
      status: status || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
      outcome: outcome || DEFAULT_NULL_REPLACEMENT,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const extractProcedureData = (procedureObj) => {
  return {
    id: procedureObj.id,
    startDate:
      procedureObj.performedDateTime ||
      (procedureObj.performedPeriod && procedureObj.performedPeriod.start) ||
      procedureObj.performedString,
    endDate:
      procedureObj.performedDateTime ||
      (procedureObj.performedPeriod && procedureObj.performedPeriod.end) ||
      procedureObj.performedString,
    procedure: extractOnlyNameFromCode(procedureObj.code),
    status: procedureObj.status,
    dataSource: findDataSource(procedureObj.identifier),
    recordedBy:
      (procedureObj.recorder && extractValueFromReference(procedureObj.recorder)) ||
      (procedureObj.performer &&
        procedureObj.performer.length &&
        procedureObj.performer[0].actor &&
        procedureObj.performer[0].actor.display),
    facility: extractValueFromReference(procedureObj.location),
    category: extractValueFromCoding(procedureObj.category),
    code: extractCode(procedureObj.code),
    note: extractNote(procedureObj.note),
    outcome: extractValueFromCoding(procedureObj.outcome),
    complication: extractValueFromCoding(
      procedureObj.complication && procedureObj.complication.length && procedureObj.complication[0],
    ),
    followUp: extractValueFromCoding(
      procedureObj.followUp && procedureObj.followUp.length && procedureObj.followUp[0],
    ),
    reasonCode: extractValueFromCoding(
      procedureObj.reasonCode && procedureObj.reasonCode.length && procedureObj.reasonCode[0],
    ),
    bodySite: extractValueFromCoding(
      procedureObj.bodySite && procedureObj.bodySite.length && procedureObj.bodySite[0],
    ),
    restricted:
      procedureObj.meta && procedureObj.meta.security && procedureObj.meta.security.length
        ? extractRestricted(procedureObj.meta.security)
        : false,
    documents: procedureObj.docArn,
    documentReferenceId: procedureObj.documentReferenceId,
  };
};

export const procedureDetailsDataFormatter = (result, hideDataSource = false) => {
  const {
    id,
    startDate,
    endDate,
    status,
    dataSource,
    facility,
    category,
    code,
    outcome,
    followUp,
    bodySite,
    reasonCode,
    complication,
    procedure,
    recordedBy,
    note,
    restricted,
    documents,
    documentReferenceId,
  } = extractProcedureData(result);

  const editData = {
    id,
    [SELF_REPORTED_VALUE_KEYS.PROCEDURE_NAME_KEY]: procedure || '',
    [SELF_REPORTED_VALUE_KEYS.FACILITY_KEY]: facility || '',
    [SELF_REPORTED_VALUE_KEYS.OUTCOME_KEY]: outcome || '',
    [SELF_REPORTED_VALUE_KEYS.STATUS_KEY]: status || '',
    [SELF_REPORTED_VALUE_KEYS.CODE_KEY]: code || '',
    [SELF_REPORTED_VALUE_KEYS.FOLLOW_UP_KEY]: followUp || '',
    [SELF_REPORTED_VALUE_KEYS.START_DATE_KEY]: startDate || null,
    [SELF_REPORTED_VALUE_KEYS.END_DATE_KEY]: endDate || null,
    [SELF_REPORTED_VALUE_KEYS.RECORDED_BY_KEY]: recordedBy || '',
    [SELF_REPORTED_VALUE_KEYS.CATEGORY_KEY]: category || '',
    [SELF_REPORTED_VALUE_KEYS.REASON_CODE_KEY]: reasonCode || '',
    [SELF_REPORTED_VALUE_KEYS.BODY_SITE_KEY]: bodySite || '',
    [SELF_REPORTED_VALUE_KEYS.COMPLICATIONS_KEY]: complication || '',
    [SELF_REPORTED_VALUE_KEYS.NOTE_KEY]: note || '',
    [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: restricted,
    documentReferenceId: documentReferenceId,
    [SELF_REPORTED_VALUE_KEYS.DOCUMENT_KEY]: documents,
  };

  const modalData = [
    {
      column_name: D_SOURCE,
      value: dataSource || DEFAULT_NULL_REPLACEMENT,
      hide: hideDataSource,
    },
    {
      column_name: 'Status',
      value: status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Start date',
      value: (startDate && getCommaSeparatedDate(startDate)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'End date',
      value: (endDate && getCommaSeparatedDate(endDate)) || DEFAULT_NULL_REPLACEMENT,
    },

    {
      column_name: 'Facility',
      value: (facility && removeBrackets(facility)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Code',
      value: code || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Outcome',
      value: outcome || DEFAULT_NULL_REPLACEMENT,
      show_status_icon: true,
    },
    {
      column_name: 'Complications',
      value: complication || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Category',
      value: category || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Follow up',
      value: followUp || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Reason code',
      value: reasonCode || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Recorded by',
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Documents',
      value: documents && documents.length > 0 ? documents : DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Body site',
      value: bodySite || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
    {
      column_name: 'Note',
      value: note || DEFAULT_NULL_REPLACEMENT,
      width: 'fullWidth',
    },
  ];

  return {
    modalData: modalData,
    editData: editData,
  };
};

export const getValueFromSourceList = (obj, headerObj) => {
  const { filterKey, filterVal, key, source, resultKey } = headerObj;
  if (key) {
    objectPath.ensureExists(obj, key, DEFAULT_NULL_REPLACEMENT);
    const valueString = objectPath.get(obj, key);
    if (valueString === DUMMY_DATE_STUB){
      return '-';
    }
    if (typeof valueString === 'string' || typeof valueString === 'number') {
      return removeBrackets(valueString) || '-';
    }
  }
  objectPath.ensureExists(obj, source, DEFAULT_NULL_REPLACEMENT);
  const sourceList = objectPath.get(obj, source);
  if (!sourceList?.length) {
    return '-';
  } else {
    // const res =
    //   Array.isArray(sourceList) && sourceList?.find((obj) => obj?.[filterKey] === filterVal);
    // return res?.[resultKey] || '-';
    const res = sourceList !== DEFAULT_NULL_REPLACEMENT ? sourceList?.find((obj) => obj?.[filterKey] === filterVal) : null;
    if (res?.[resultKey] === DUMMY_DATE_STUB) {
      return '-';
    }
    return res?.[resultKey] || DEFAULT_NULL_REPLACEMENT;
  }
};
export const getValueFromTransformation = (obj, headerObj) => {
  const codeValue = objectPath.get(obj, headerObj?.dataCol);
  const labelValue =
    headerObj?.options?.filter((item) => {
      return item.value === codeValue && item;
    }) || codeValue;
  return labelValue?.[0]?.label || codeValue || DEFAULT_NULL_REPLACEMENT;
};
export const getValueFromOptions = (obj, headerObj, selfReportingTemplate) => {
  const template = selfReportingTemplate?.formElementList?.filter((item) => {
    return item.label === headerObj.label && item;
  });
  const codeValue = getValueFromSourceList(obj, headerObj);
  const result = template?.[0]?.options?.filter((item) => {
    return item.value === codeValue && item;
  });
  return result?.[0]?.label || codeValue || DEFAULT_NULL_REPLACEMENT;
};

export const getNameWithCodeField = (obj, headerObj) => {
  objectPath.ensureExists(obj, headerObj?.dataColForCode, DEFAULT_NULL_REPLACEMENT);
  objectPath.ensureExists(obj, headerObj?.dataColForName, DEFAULT_NULL_REPLACEMENT);
  const code = String(objectPath.get(obj, headerObj?.dataColForCode));
  const name = objectPath.get(obj, headerObj?.dataColForName);
  const result =
    code && code !== '-' && name && name !== '-'
      ? `${code} - ${capitalizeFirstLetter(name)}`
      : code && code !== '-'
      ? code
      : capitalizeFirstLetter(name);
  return result;
};

const getListValuesFromSource = (resourceObj, headerObj) => {
  const { filterKey, filterVal, source, resultKey, type } = headerObj;
  objectPath.ensureExists(resourceObj, source, []);
  const sourceList = objectPath.get(resourceObj, source);
  if (!sourceList?.length) {
    return null;
  }
  const list = [];
  for (const listItem of sourceList) {
    objectPath.ensureExists(listItem, filterKey, []);
    const subList = objectPath.get(listItem, filterKey);
    if (subList instanceof Array) {
      for (const subListItem of subList) {
        list.push({ [filterVal]: subListItem?.[resultKey] });
      }
    } else if (type === 'filter') {
      if (listItem?.[filterKey] === filterVal) {
        return listItem?.[resultKey];
      }
    } else {
      list.push({ [filterVal]: subList });
    }
  }
  const responseList = list?.filter((obj) => obj?.[filterVal] && obj);
  return responseList;
};

const getObjectListFromSource = (resourceObj, headerObj) => {
  const { source, resultKey } = headerObj;
  objectPath.ensureExists(resourceObj, source, []);
  const sourceList = objectPath.get(resourceObj, source);
  if (!sourceList?.length) {
    return [];
  }
  const duplicate = [];
  const list = [];
  for (const listItem of sourceList) {
    objectPath.ensureExists(listItem, resultKey, []);
    const item = listItem?.[resultKey];

    if (!duplicate.includes(item)) {
      duplicate.push(item);
      list.push(item);
    }
  }
  // const list = [];
  // for (const listItem of sourceList) {
  //   objectPath.ensureExists(listItem, resultKey, []);
  //   list.push(listItem?.[resultKey]);
  // }
  return list;
};

const getObjectListFromSubSource = (resourceObj, headerObj) => {
  const { source, resultKey1, resultKey2 } = headerObj;
  objectPath.ensureExists(resourceObj, source, []);
  const sourceList = objectPath.get(resourceObj, source);
  if (!sourceList?.length) {
    return [];
  }
  const list = [];
  for (const listItem of sourceList) {
    const subList = objectPath.get(listItem, resultKey1) ?? [];
    for (const subListItem of subList) {
      objectPath.ensureExists(subListItem, resultKey2, []);
      list.push(subListItem?.[resultKey2]);
    }
  }
  return list;
};
const getListFromArray = (resourceObj, headerObj) => {
  objectPath.ensureExists(resourceObj, headerObj?.arrayName, DEFAULT_NULL_REPLACEMENT);
  const array = objectPath.get(resourceObj, headerObj?.arrayName);
  const list = [];
  if (Array.isArray(array) && array?.length) {
    for (const listItem of array) {
      objectPath.ensureExists(listItem, headerObj?.objectPath, DEFAULT_NULL_REPLACEMENT);
      const value = objectPath.get(listItem, headerObj?.objectPath);
      list.push(value);
    }
    return list;
  } else {
    return [];
  }
};

const getListFromArrayExtension = (obj, headerObj) => {
  const { filterKey, filterVal, source, resultKey } = headerObj;

  const list = [];
  objectPath.ensureExists(obj, source, DEFAULT_NULL_REPLACEMENT);
  const sourceList = objectPath.get(obj, source);

  if (!sourceList || sourceList.length === 0) {
    return '-';
  }

  const res = sourceList.find((obj) => obj?.[filterKey] === filterVal);
  if (res) {
    const values = res[resultKey].split(',').map((value) => value.replace(/["\[\]]/g, '').trim());
    list.push(...values);
  } 

  return list;
};

export const getStatusValue = (obj, headerObj) => {
  const { key } = headerObj;
  objectPath.ensureExists(obj, key, DEFAULT_NULL_REPLACEMENT);
  const value = objectPath.get(obj, key);
  return value ? convertStrToTitleCase(value) : '-';
};

export const getMultipleObjFromSource = (obj, headerObj) => {
  const { filterKey, filterVals, key, source, resultKey } = headerObj;
  const result = {};
  if (key) {
    objectPath.ensureExists(obj, key, DEFAULT_NULL_REPLACEMENT);
    const valueString = objectPath.get(obj, key);
    if (typeof valueString === 'string') {
      return removeBrackets(valueString) || '-';
    }
  }
  objectPath.ensureExists(obj, source, DEFAULT_NULL_REPLACEMENT);
  const sourceList = objectPath.get(obj, source);
  if (!sourceList?.length) {
    return '-';
  } else {
    for (const listItem of sourceList) {
      if (filterVals?.includes(listItem?.[filterKey])) {
        result[listItem[filterKey]] = listItem?.[resultKey];
      }
    }
  }
  //this line has to be removed and mapping should be done at BE
  if (result?.status === 'Enter in error') {
    result.status = 'Entered in error';
  }
  ////////////////////////////////////////////////////////////
  return result;
};

export const renderStatusViewFromDiffLocation = (obj, headerObj) => {
  const { filterKey, filterVal, source1, source2, resultKey } = headerObj;
  const result = {};
  objectPath.ensureExists(obj, source2, DEFAULT_NULL_REPLACEMENT);
  const status_color = objectPath.get(obj, source2);
  objectPath.ensureExists(obj, source1, DEFAULT_NULL_REPLACEMENT);
  const status = objectPath.get(obj, source1);
  if (!status ) {
    return '-';
  } else {
  const statusColor = status_color.find((obj)=>obj?.[filterKey]=== filterVal)
  if (statusColor)
  {
    result.statusColor= statusColor[resultKey];
    result.status=status;
  }
  }
  return result;
};


export const getValueNameWithCodeExtension = (obj, headerObj) => {
  const { filterKey, filterVal1, filterVal2, key, source, resultKey } = headerObj;
  objectPath.ensureExists(obj, source, DEFAULT_NULL_REPLACEMENT);
  const sourceList = objectPath.get(obj, source);
  if (!sourceList?.length) {
    return '-';
  } else {
    const name = sourceList?.find((obj) => obj?.[filterKey] === filterVal1)?.valueString;
    const code = sourceList?.find((obj) => obj?.[filterKey] === filterVal2)?.valueString;
    const result =
      code && code !== '-' && name && name !== '-'
        ? `${code} - ${capitalizeFirstLetter(name)}`
        : code && code !== '-'
        ? code
        : capitalizeFirstLetter(name);
    return result;
  }
};

const formateReusableResource = (resourceObj, headerObj, hideDataSource, selfReportingTemplate) => {
  const { label, type, width, filterVals, requestKeyForSelfReporting } = headerObj;
  switch (type) {
    case 'pill':
      if (hideDataSource) {
        return null;
      }
      return {
        column_name: label || DEFAULT_NULL_REPLACEMENT,
        value: getValueFromSourceList(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'requireTransformation':
      return {
        column_name: label || DEFAULT_NULL_REPLACEMENT,
        value: getValueFromOptions(resourceObj, headerObj, selfReportingTemplate),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type: 'string',
      };
    case 'filter':
    case 'string':
    case 'date':
    case 'time':
      return {
        column_name: label,
        value: getValueFromSourceList(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'objectList':
      return {
        column_name: label,
        value: getObjectListFromSource(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'arrayFromExtension':
      return {
        column_name: label,
        value: getListFromArrayExtension(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'objectSubList':
      return {
        column_name: label,
        value: getObjectListFromSubSource(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'arrayList':
      return {
        column_name: label,
        value: getListFromArray(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'dot':
      const status_obj = getMultipleObjFromSource(resourceObj, headerObj);
      return {
        column_name: label || DEFAULT_NULL_REPLACEMENT,
        value: status_obj?.[filterVals?.[1]],
        status_color: status_obj?.[filterVals?.[0]] || null,
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
      case 'dotWithStatusfromdiffloc':
      const statusAndStatusColor = renderStatusViewFromDiffLocation(resourceObj, headerObj);
      return {
        column_name: label || DEFAULT_NULL_REPLACEMENT,
        value: statusAndStatusColor?.status,
        status_color: statusAndStatusColor?.statusColor  || null,
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'document':
      objectPath.ensureExists(resourceObj, headerObj.key, []);
      return {
        column_name: label,
        value: objectPath.get(resourceObj, headerObj.key) || [],
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'nameWithCode':
      return {
        column_name: label,
        value: getNameWithCodeField(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'nameWithCodeExtension':
      return {
        column_name: label,
        value: getValueNameWithCodeExtension(resourceObj, headerObj),
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
    case 'list':
      return {
        column_name: label,
        value: getListValuesFromSource(resourceObj, headerObj) || [],
        requestKeyForSelfReporting: requestKeyForSelfReporting || '',
        width: width || '',
        hide: headerObj?.hide,
        type,
      };
  }
};

export const viewDetailsDataFormatter = (
  resourceObj,
  detailsTemplate,
  selfReportingTemplate = [],
  customCardForViewDetails,
  header,
  hideDataSource = false,
) => {
  const modalData = detailsTemplate
    ?.map((headerObj) =>
      formateReusableResource(resourceObj, headerObj, hideDataSource, selfReportingTemplate),
    )
    ?.filter((obj) => obj && obj);
  objectPath.ensureExists(resourceObj, 'meta.security.0.code', '');
  objectPath.ensureExists(resourceObj, 'meta.security.0.display', '');
  const markAsRestricted =
    objectPath.get(resourceObj, 'meta.security.0.code')?.toLocaleLowerCase() === 'r' ||
    objectPath.get(resourceObj, 'meta.security.0.display')?.toLocaleLowerCase() === 'restricted'
      ? true
      : false;
  objectPath.ensureExists(resourceObj, header?.key1, DEFAULT_NULL_REPLACEMENT);
  objectPath.ensureExists(resourceObj, header?.key2, DEFAULT_NULL_REPLACEMENT);
  const modelTitle =
    header?.filterKey && header?.filterVal && getValueFromSourceList(resourceObj, header);
  let title = objectPath.get(resourceObj, header?.key1);
  title =
    title && title !== '-'
      ? capitalizeFirstLetter(title)
      : modelTitle
      ? capitalizeFirstLetter(modelTitle)
      : '-';

  const { sourceObj, filterKey, requiredPropsForViewDetailsCustomCards } =
    customCardForViewDetails || {};
  const requiredPropsForCustomCardInViewDetails =
    resourceObj?.[sourceObj]?.filter((extObj) => {
      if (requiredPropsForViewDetailsCustomCards?.includes(extObj?.[filterKey])) {
        return extObj;
      }
    }) || [];

  const selfReportedData = {
    documentReferenceId: resourceObj?.documentReferenceId,
    docArn: resourceObj?.docArn,
    documents: resourceObj?.docArn,
    markAsRestricted: markAsRestricted,
    id: resourceObj?.id,
    title,
  };
  for (const obj of modalData) {
    if (obj?.requestKeyForSelfReporting === 'note') {
      selfReportedData[obj?.requestKeyForSelfReporting] = obj?.value
        ?.toString()
        ?.replaceAll(',', ' ');
    } else {
      selfReportedData[obj?.requestKeyForSelfReporting] = obj?.value;
    }
  }

  objectPath.ensureExists(resourceObj, 'code.coding.0.code', null);
  return {
    modalData: modalData || [],
    requiredPropsForCustomCardInViewDetails,
    title,
    dataSource: modalData?.find((obj) => obj?.column_name === 'Data source')?.value || '',
    code:
      objectPath.get(resourceObj, 'code.coding.0.code') ||
      objectPath.get(resourceObj, 'vaccineCode.coding.0.code') ||
      DEFAULT_NULL_REPLACEMENT,
    markAsRestricted: markAsRestricted,
    editData: selfReportedData,
  };
};

export const socialHistoryArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const {
      id,
      recordedOn,
      status,
      recordedBy,
      frequency,
      dataSource,
      code,
      name,
      identifier,
      markAsRestrictedInModal,
    } = extractSocialHistory(arrayItem);

    return {
      recordedOn: (recordedOn && getCommaSeparatedDate(recordedOn)) || DEFAULT_NULL_REPLACEMENT,
      id,
      status: status || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.RECORDED_BY]: recordedBy || DEFAULT_NULL_REPLACEMENT,
      frequency: frequency || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.CODE_WITH_NAME(TABLE_HEADINGS.NAME)]:
        name && code ? `${code} - ${name}` : code || name || DEFAULT_NULL_REPLACEMENT,
      flyoutTitle: name || code || DEFAULT_NULL_REPLACEMENT,
      code: code || DEFAULT_NULL_REPLACEMENT,
      identifier,
      markAsRestrictedInModal,
    };
  });
  return formattedRes?.filter((item) => item && item);
};

export const extractSocialHistory = (socialHistoryObject) => {
  return {
    recordedOn: socialHistoryObject.effectiveDateTime || socialHistoryObject.issued,
    id: socialHistoryObject.id,
    status: socialHistoryObject.status,
    recordedBy:
      (socialHistoryObject.performer &&
        socialHistoryObject.performer.length &&
        socialHistoryObject.performer[0].display) ||
      DEFAULT_NULL_REPLACEMENT,
    frequency:
      socialHistoryObject.valueQuantity && socialHistoryObject.valueQuantity.value
        ? socialHistoryObject.valueQuantity.value + ' ' + socialHistoryObject.valueQuantity.unit
        : socialHistoryObject.valueCodeableConcept &&
          socialHistoryObject.valueCodeableConcept.coding &&
          socialHistoryObject.valueCodeableConcept.coding[0] &&
          socialHistoryObject.valueCodeableConcept.coding[0].display
        ? socialHistoryObject.valueCodeableConcept.coding[0].display
        : socialHistoryObject.valueCodeableConcept && socialHistoryObject.valueCodeableConcept.text
        ? socialHistoryObject.valueCodeableConcept.text
        : socialHistoryObject.valueString
        ? socialHistoryObject.valueString
        : socialHistoryObject.valueBoolean
        ? socialHistoryObject.valueBoolean
        : socialHistoryObject.valueInteger
        ? socialHistoryObject.valueInteger
        : socialHistoryObject.valueRange
        ? socialHistoryObject.valueRange
        : socialHistoryObject.valueRatio
        ? socialHistoryObject.valueRatio
        : socialHistoryObject.valueSampledData
        ? socialHistoryObject.valueSampledData
        : socialHistoryObject.valueTime
        ? socialHistoryObject.valueTime
        : socialHistoryObject.valueDateTime
        ? socialHistoryObject.valueDateTime
        : socialHistoryObject.valuePeriod
        ? socialHistoryObject.valuePeriod
        : DEFAULT_NULL_REPLACEMENT,
    dataSource: socialHistoryObject.identifier && findDataSource(socialHistoryObject.identifier),
    name: extractValueFromCoding(socialHistoryObject.code),
    code: extractOnlyCodeFromCodeObj(socialHistoryObject.code),
    identifier: socialHistoryObject.identifier,
    markAsRestrictedInModal: socialHistoryObject?.meta?.security?.length
      ? extractRestricted(socialHistoryObject?.meta.security)
      : false,
  };
};

export const socialHistoryViewDetailedFormat = (viewDetails, partner = false) => {
  const modalData = [
    {
      column_name: TABLE_HEADINGS.DATA_SOURCE,
      value: viewDetails.dataSource,
      hide: partner,
    },
    {
      column_name: TABLE_HEADINGS.STATUS,
      value: viewDetails.status,
    },
    {
      column_name: TABLE_HEADINGS.RECORDED_ON,
      value: viewDetails.recordedOn,
    },
    {
      column_name: TABLE_HEADINGS.RECORDED_BY,
      value: viewDetails.recordedBy,
    },
    {
      column_name: TABLE_HEADINGS.FREQUENCY,
      value: viewDetails.frequency,
    },
    {
      column_name: TABLE_HEADINGS.CODE,
      value: viewDetails.code,
    },
  ];
  return {
    modalData,
    markAsRestrictedInModal: viewDetails.markAsRestrictedInModal,
  };
};

export function extractValueWithCode(obj, separator = ' ') {
  objectPath.ensureExists(obj, 'coding.0.code', DEFAULT_NULL_REPLACEMENT);
  objectPath.ensureExists(obj, 'coding.0.display', DEFAULT_NULL_REPLACEMENT);
  objectPath.ensureExists(obj, 'text', DEFAULT_NULL_REPLACEMENT);

  const code = String(objectPath.get(obj, 'coding.0.code') ?? '');
  const display = String(objectPath.get(obj, 'coding.0.display') ?? '');
  const text = String(objectPath.get(obj, 'text') ?? '');
  let valueText = display || text;
  if (code) {
    valueText = `${code}${separator}${valueText}`;
  }
  return valueText ?? DEFAULT_NULL_REPLACEMENT;
}

export const extractVisitsData = (encounterObj) => {
  const visitTypeData =
    encounterObj?.class && (encounterObj.class.display || encounterObj.class.code);
  let encounterTypeData;
  encounterObj.type
    ? encounterObj.type.forEach(function (item, index) {
        item.coding &&
          item.coding.forEach(function (codingItem, codingIndex) {
            codingItem.system &&
            codingItem.system === 'http://terminology.hl7.org/CodeSystem/encounter-type'
              ? (encounterTypeData = `${codingItem.code ?? ''}${
                  codingItem.display && codingItem.code ? ' : ' : ''
                }${codingItem.display ?? ''}`)
              : null;
          });
      })
    : null;
  const resProvider = encounterObj?.participant?.[0]?.individual?.display;
  let parsedData;
  let provider;
  if (['provider', 'participant'].some((item) => resProvider?.includes(item))) {
    parsedData = JSON.parse(resProvider);
    provider = parsedData?.provider || parsedData?.participant || '';
  } else {
    provider = resProvider || '';
  }
  return {
    id: encounterObj.id,
    [TABLE_KEYS.START_DATE]:
      encounterObj.period &&
      encounterObj.period.start &&
      getCommaSeparatedDate(encounterObj.period.start),
    [TABLE_KEYS.END_DATE]:
      encounterObj.period &&
      encounterObj.period.end &&
      getCommaSeparatedDate(encounterObj.period.end),
    status: encounterObj.status,
    facility: encounterObj?.serviceProvider?.display,
    visitType: visitTypeData,
    provider: provider,
    dataSource:
      encounterObj.identifier &&
      encounterObj.identifier.length &&
      findDataSource(encounterObj.identifier),
    providerSpeciality: resProvider && extractValueFromRefDisplayJson(parsedData, 'speciality'),
    serviceType: extractValueWithCode(encounterObj?.serviceType),

    code: encounterObj.coding && encounterObj.coding.code,
    reasonCode: reasonCodeFun(
      encounterObj && encounterObj.reasonCode && encounterObj.reasonCode[0],
    ),
    encounterType: encounterTypeData,
    markAsRestrictedInModal: encounterObj?.meta?.security?.length
      ? extractRestricted(encounterObj?.meta.security)
      : false,

    recordedBy: encounterObj?.participant?.[0]?.individual?.Practioner?.name || provider,
  };
};

export const visitsArrayConverter = (array) => {
  const formattedRes = array.map((arrayItem) => {
    const { id, startDate, facility, visitType, status, provider, dataSource, code } =
      extractVisitsData(arrayItem);
    return {
      id,
      [TABLE_KEYS.START_DATE]: startDate || DEFAULT_NULL_REPLACEMENT,
      facility: (facility && capitalizeFirstLetter(facility)) || DEFAULT_NULL_REPLACEMENT,
      [TABLE_KEYS.TYPE_OF_VISITS]: visitType || DEFAULT_NULL_REPLACEMENT,
      status: status && status === 'onleave' ? 'On Leave' : status || DEFAULT_NULL_REPLACEMENT,
      provider: provider || DEFAULT_NULL_REPLACEMENT,
      dataSource: dataSource || DEFAULT_NULL_REPLACEMENT,
    };
  });

  return formattedRes?.filter((item) => item && item);
};

export const visitsDetailsFormatter = (encounterObj, hideDataSource = false) => {
  const rawData = extractVisitsData(encounterObj);
  const {
    dataSource,
    status,
    startDate,
    endDate,
    facility,
    visitType,
    provider,
    providerSpeciality,
    serviceType,
    reasonCode,
    encounterType,
    recordedBy,
  } = rawData;

  const modalData = [
    {
      column_name: TABLE_HEADINGS.DATA_SOURCE,
      value: dataSource,
      hide: hideDataSource,
    },
    {
      column_name: TABLE_HEADINGS.STATUS,
      value: status && status === 'onleave' ? 'On Leave' : status || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: TABLE_HEADINGS.START_DATE,
      value: startDate || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: TABLE_HEADINGS.END_DATE,
      value: endDate || DEFAULT_NULL_REPLACEMENT,
    },
    // {
    //   column_name: TABLE_HEADINGS.RECORDED_ON,
    //   value: startDate || DEFAULT_NULL_REPLACEMENT,
    // },
    {
      column_name: TABLE_HEADINGS.RECORDED_BY,
      value: recordedBy || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Visit class',
      value: (visitType && capitalizeFirstLetter(visitType)) || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: TABLE_HEADINGS.PROVIDER,
      value: provider || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Last visited on',
      value: startDate || DEFAULT_NULL_REPLACEMENT,
    },

    {
      column_name: 'Provider speciality',
      value: providerSpeciality || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Service type',
      value: serviceType || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Encounter type',
      value: encounterType || DEFAULT_NULL_REPLACEMENT,
    },
    {
      column_name: 'Description',
      value: reasonCode,
      width: 'fullWidth',
    },
  ];

  return {
    modalData,
    rawData,
  };
};

export const extractNote = (item) =>
  item && item.text ? item.text : Array.isArray(item) && item.length ? item[0].text : null;

export const setLastActiveTime = (timeStamp = Math.floor(Date.now())) => {
  localStorage.setItem(lastActiveTimeKey, timeStamp);
};

export const getBreadCrumbTitle = (resourceType) => {
  if (!resourceType) {
    return '';
  }
  if (resourceType === 'labs') {
    return 'Lab results';
  }
  return `${resourceType?.[0]?.toUpperCase()}${resourceType?.slice(1)}`;
};

export const removeTimeFromDate = (date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    if (isNaN(date)) {
      // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return '';
    } else {
      // date object is valid
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
  } else {
    // not a date object
    return '';
  }
};

export const removeTimeFromUTCDate = (date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    if (isNaN(date)) {
      // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return '';
    } else {
      // date object is valid
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }
  } else {
    // not a date object
    return '';
  }
};

export const extractRestricted = (array) => {
  // const restrictredObbj = array.find((val) => val.code === 'R');
  if (array && array.length && array[0].code === 'U') {
    return false;
  } else if (
    array &&
    array.length &&
    (array[0].code === 'R' || array[0].display === 'Restricted')
  ) {
    return true;
  }
  return false;
};

export const getFileNameAndExtension = (fullPath) => {
  if (fullPath) {
    const startIndex =
      fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
    let fileName = fullPath.substring(startIndex);
    if (fileName.indexOf('\\') === 0 || fileName.indexOf('/') === 0) {
      fileName = fileName.substring(1);
    }
    const fileNameSplits = fileName.split('.');
    const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
    return {
      fileName: fileName,
      fileNameWithoutExtension: fileNameWithoutExtension,
      extension: fileNameSplits[fileNameSplits.length - 1],
    };
  }
};

const checkForNonZeroValidity = (value) => {
  return value !== null && value !== undefined;
};

export const calculateLabResultLabel = (range, value) => {
  try {
    if (!range || !checkForNonZeroValidity(value) || isNaN(value)) {
      return '';
    }
    const formattedValue = Number(value);

    const rangeSeparators = ['-', '<', '<=', '>', '>='];
    let rangeSplit = undefined;
    let min, max;
    rangeSeparators.forEach((val) => {
      if (range.includes(val)) {
        rangeSplit = range.split(val);
        if (val === '<' || val === '<=') {
          max = rangeSplit && rangeSplit[1] && Number(rangeSplit[1].trim());
          min = Number.MIN_SAFE_INTEGER;
        } else if (val === '>' || val === '>=') {
          min = rangeSplit && rangeSplit[1] && Number(rangeSplit[1].trim());
          max = Number.MAX_SAFE_INTEGER;
        } else if (val === '-') {
          min = (rangeSplit && rangeSplit[0] && Number(rangeSplit[0].trim())) || 0;
          max = rangeSplit && rangeSplit[1] && Number(rangeSplit[1].trim());
        } else {
          return '';
        }
      }
    });
    if (isNaN(min) || isNaN(max)) {
      return '';
    }
    if ((min || min === 0) && (max || max === 0)) {
      if (formattedValue < min) {
        return 'Low';
      } else if (formattedValue >= min && formattedValue <= max) {
        return 'Normal';
      } else if (formattedValue > max) {
        return 'High';
      }
    } else {
      return '';
    }
  } catch (err) {
    return '';
  }
};

export const getUSATime = (date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

export const getLocalDateWithoutTime = (date = new Date()) => {
  // console.log(date, date.getFullYear(), date.getMonth(), date.getDate());
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const convertToUTCDate = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const resetTimeWithCurrentTime = (date) => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const dateWithoutTime = removeTimeFromDate(date);
  const finalTs =
    dateWithoutTime.getTime() + hours * 3600 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  return new Date(finalTs);
};

// replace date value in ISO string with date in IST
export const createDOBString = (date = new Date()) => {
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  const isoString = date.toISOString();
  const splitValues = isoString.split('-');
  let str3 = splitValues[2].split('');
  str3.splice(0, 2);
  str3 = str3.join('');
  return `${splitValues[0].toString()}-${splitValues[1].toString()}-${day}${str3}`;
};

// method to remove the timezoneoffset and pass the UTC date to B.E.
// FHIR removes the timezone even if we pass it and causes date issues when we recieve the data from B.E
export const createDOBUTCString = (date = new Date()) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const time = new Date(date).getTime();
  return new Date(time - timezoneOffset * 60 * 1000).toISOString();
};

export const calculateDateFilterTimeStamp = (filterVal, custom_date = '') => {
  let finalDate = new Date();
  switch (filterVal.toLowerCase()) {
    case 'all':
      finalDate = 0;
      return 0;
    case '1 month':
    case '1m':
      finalDate = new Date(finalDate.setMonth(finalDate.getMonth() - 1));
      break;
    case '3 months':
    case '3m':
      finalDate = new Date(finalDate.setMonth(finalDate.getMonth() - 3));
      break;
    case '6 months':
    case '6m':
      finalDate = new Date(finalDate.setMonth(finalDate.getMonth() - 6));
      break;
    case 'custom_date':
      finalDate = new Date(custom_date);
      break;
    default:
      break;
  }
  return finalDate.getTime();
};

export const getStatusFilterParamList = (filterVal, dropdownList, completeList = []) => {
  if (!filterVal || !dropdownList || filterVal === 'all') {
    return '';
  }
  if (filterVal === 'others') {
    const otherList = completeList.filter((item) => {
      return !dropdownList.find((obj) => obj && obj.value.toLowerCase() === item.toLowerCase());
    });
    if (!otherList.includes('unknown')) {
      otherList.push('unknown');
    }
    return otherList.toString();
  }
  const filterMapping = dropdownList.find(
    (obj) => obj.value.toLowerCase() === filterVal.toLowerCase(),
  ).mappingList;
  if (filterMapping) {
    return filterMapping.toString();
  }
  return filterVal;
};

export const getStatusFilterParamListForReusableComponents = (filterVal, dropdownList) => {
  if (!filterVal || !dropdownList || filterVal === 'all') {
    return '';
  }
  const filterMapping = dropdownList.find(
    (obj) => obj.value.toLowerCase() === filterVal.toLowerCase(),
  );
  if (filterMapping?.mappingList) {
    return filterMapping?.mappingList;
  }
  return [filterVal];
};

export const getConsentStatusMapped = (status) => {
  if (status === null || status === undefined) {
    return 'Not initiated';
  }
  const statusObj = status.toLowerCase();
  if (['proposed', 'PROPOSED'].includes(statusObj)) {
    return 'Pending';
  }
  if (['rejected', 'REJECTED'].includes(statusObj)) {
    return 'Cancelled';
  }
  if (['entered-in-error'].includes(statusObj)) {
    return 'Unknown';
  }
  if (['inactive', 'INACTIVE', 'Expired', 'EXPIRED'].includes(statusObj)) {
    return 'Expired';
  }
  if (['TERMINATED', 'Terminated'].includes(statusObj)) {
    return 'Terminated';
  }
  return status;
};

export const extractConsentData = (consentObj, from = '') => {
  return {
    id: consentObj && consentObj.consentId,
    grantorId:
      (consentObj && consentObj.grantor && consentObj.grantor.id) || DEFAULT_NULL_REPLACEMENT,
    granteeId:
      (consentObj && consentObj.grantee && consentObj.grantee.id) || DEFAULT_NULL_REPLACEMENT,
    email:
      from !== 'consentReceived'
        ? (consentObj && consentObj.grantee && consentObj.grantee.email) || DEFAULT_NULL_REPLACEMENT
        : (consentObj && consentObj.grantor && consentObj.grantor.email) ||
          DEFAULT_NULL_REPLACEMENT,

    dob:
      (consentObj &&
        consentObj.grantor &&
        consentObj.grantor.dob &&
        getCommaSeparatedDate(consentObj.grantor.dob)) ||
      DEFAULT_NULL_REPLACEMENT,
    phoneNumber:
      from !== 'consentReceived'
        ? (consentObj && consentObj.grantee && consentObj.grantee.phoneNumber) ||
          DEFAULT_NULL_REPLACEMENT
        : (consentObj && consentObj.grantor && consentObj.grantor.phoneNumber) ||
          DEFAULT_NULL_REPLACEMENT,
    receivedOn:
      (consentObj && consentObj.grantedDate && getCommaSeparatedDate(consentObj.grantedDate)) ||
      DEFAULT_NULL_REPLACEMENT,
    status:
      (consentObj &&
        consentObj.status &&
        getConsentStatusMapped(consentObj && consentObj.status)) ||
      'Not initiated',
    expiresOn:
      (consentObj &&
        consentObj.grantExpiryDate &&
        getCommaSeparatedDate(consentObj.grantExpiryDate)) ||
      DEFAULT_NULL_REPLACEMENT,
    typeOfConsent: (consentObj && consentObj.scope) || DEFAULT_NULL_REPLACEMENT,

    Name:
      from !== 'consentReceived'
        ? (consentObj && consentObj.grantee && consentObj.grantee.name) || DEFAULT_NULL_REPLACEMENT
        : (consentObj && consentObj.grantor && consentObj.grantor.name) || DEFAULT_NULL_REPLACEMENT,
    imgUrl:
      from !== 'consentReceived'
        ? (consentObj && consentObj.grantee && consentObj.grantee.avatarUrl) || UserCircle
        : (consentObj && consentObj.grantor && consentObj.grantor.avatarUrl) || UserCircle,
    grantedCategoriesList:
      from === 'consentReceived' ? consentObj && consentObj.mphContentClassIds : [],
    demographicDetails: (consentObj && consentObj.grantor) || DEFAULT_NULL_REPLACEMENT,
    declineMessage: consentObj && consentObj.declineMessage,
    initiator: consentObj?.initiator,
    legalGuardianOrHealthcarePOA:
      consentObj?.legalGuardianOrHealthcarePOA || DEFAULT_NULL_REPLACEMENT,
  };
};

export const consentArrayConverter = (array, consentFrom = '') => {
  const formattedRes = array?.map((arrayItem) => {
    const {
      id,
      receivedOn,
      status,
      expiresOn,
      typeOfConsent,
      Name,
      imgUrl,
      email,
      phoneNumber,
      grantorId,
      granteeId,
      role,
      relationship,
      legalminor,
      isDependent,
      dependentFhirId,
      dob,
      grantedCategoriesList,
      demographicDetails,
      declineMessage,
      initiator,
      legalGuardianOrHealthcarePOA,
    } = extractConsentData(arrayItem, consentFrom);
    return {
      id,
      receivedOn,
      name: { Name, imgUrl },
      status,
      expiresOn,
      typeOfConsent,
      email,
      phoneNumber,
      grantorId,
      granteeId,
      role,
      relationship,
      legalminor,
      isDependent,
      dependentFhirId,
      dob,
      grantedCategoriesList,
      demographicDetails,
      declineMessage,
      initiator,
      legalGuardianOrHealthcarePOA,
    };
  });
  return formattedRes?.filter((consent) => consent !== null);
};

export const extractRelatedPersonData = (relatedPersonObj) => {
  return {
    id: Math.ceil(Math.random() * 100000) || DEFAULT_NULL_REPLACEMENT,
    consentId:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.consentId) ||
      DEFAULT_NULL_REPLACEMENT,
    receivedOn:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.grantedDate &&
        getCommaSeparatedDate(relatedPersonObj.consentSummary.grantedDate)) ||
      DEFAULT_NULL_REPLACEMENT,
    Name:
      (relatedPersonObj && relatedPersonObj.relatedPerson && relatedPersonObj.relatedPerson.name) ||
      DEFAULT_NULL_REPLACEMENT,
    imgUrl:
      (relatedPersonObj &&
        relatedPersonObj.relatedPerson &&
        relatedPersonObj.relatedPerson.avatarUrl) ||
      UserCircle,
    relationship:
      (relatedPersonObj &&
        relatedPersonObj.relatedPerson &&
        relatedPersonObj.relatedPerson.relationship) ||
      DEFAULT_NULL_REPLACEMENT,
    legalminor: (relatedPersonObj && relatedPersonObj.legalMinor) || DEFAULT_NULL_REPLACEMENT,
    isDependent: relatedPersonObj?.isDependent,
    dependentFhirId: relatedPersonObj?.dependentFhirId,
    dob:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.grantor &&
        relatedPersonObj.consentSummary.grantor.dob &&
        relatedPersonObj.consentSummary.grantor.dob) ||
      relatedPersonObj?.relatedPerson?.dob ||
      DEFAULT_NULL_REPLACEMENT,
    typeOfConsent:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.scope) ||
      DEFAULT_NULL_REPLACEMENT,
    status:
      (relatedPersonObj && getConsentStatusMapped(relatedPersonObj.status)) ||
      DEFAULT_NULL_REPLACEMENT,
    grantedCategoriesList:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.mphContentClassIds) ||
      [],
    demographicDetails:
      (relatedPersonObj &&
        relatedPersonObj.consentSummary &&
        relatedPersonObj.consentSummary.grantor) ||
      DEFAULT_NULL_REPLACEMENT,
    email:
      (relatedPersonObj &&
        relatedPersonObj.relatedPerson &&
        relatedPersonObj.relatedPerson.email) ||
      DEFAULT_NULL_REPLACEMENT,
    phoneNumber: relatedPersonObj?.consentSummary?.grantor?.phoneNumber || DEFAULT_NULL_REPLACEMENT,
    grantorId: (relatedPersonObj && relatedPersonObj.grantorId) || DEFAULT_NULL_REPLACEMENT,
    granteeId: (relatedPersonObj && relatedPersonObj.initiatorId) || DEFAULT_NULL_REPLACEMENT,
    legalGuardianOrHealthcarePOA:
      relatedPersonObj?.consentSummary?.legalGuardianOrHealthcarePOA || DEFAULT_NULL_REPLACEMENT,
    workflowReinitiateFlag: relatedPersonObj?.workflowReinitiateFlag || false,
  };
};

export const relatedPersonRecordFormatter = (array) => {
  const formattedRes =
    array &&
    array.map((arrayItem, index) => {
      const {
        id,
        consentId,
        receivedOn,
        status,
        typeOfConsent,
        Name,
        imgUrl,
        email,
        phoneNumber,
        grantorId,
        granteeId,
        relationship,
        legalminor,
        isDependent,
        dependentFhirId,
        dob,
        grantedCategoriesList,
        demographicDetails,
        legalGuardianOrHealthcarePOA,
        workflowReinitiateFlag,
      } = extractRelatedPersonData(arrayItem, index);
      return {
        id,
        consentId,
        receivedOn,
        name: { Name, imgUrl },
        status,
        typeOfConsent,
        email,
        phoneNumber,
        grantorId,
        granteeId,
        relationship,
        legalminor,
        dependentFhirId,
        isDependent,
        dob,
        grantedCategoriesList,
        demographicDetails,
        legalGuardianOrHealthcarePOA,
        workflowReinitiateFlag,
      };
    });
  return formattedRes;
};

export const getConsentUserDetailsFromLocalStorage = () => {
  let consentUserName = '';
  const consentUser = JSON.parse(localStorage.getItem('consentUserDetails'));
  if (consentUser) {
    consentUserName = (consentUser.name && consentUser.name.Name) || DEFAULT_NULL_REPLACEMENT;
  }
  return consentUserName;
};

export function getPartnerInfoConsolidated(partnerUrlParam) {
  let partnerInfo = localStorage.getItem('partnerInfo');
  partnerInfo = partnerInfo && JSON.parse(partnerInfo);
  if (partnerInfo) {
    partnerInfo.partnerUrlParam = partnerUrlParam;
  }
  return partnerInfo;
}

export function getBreadCrumbs(
  pageTitle,
  screenMode,
  partnerInfo,
  consentUserName,
  isOneTimeUser = false,
) {
  const breadCrumbs = [];
  let tabIndex = localStorage.getItem('tabIndex');
  tabIndex = Number(tabIndex);

  const firstCrumb = {
    label: 'Records',
    route: `${isOneTimeUser ? '/oneTimeDashboard' : '/records'}`,
    state: {
      tabIndex,
    },
  };

  if (screenMode.isCircle) {
    firstCrumb.label = 'Circles';
    firstCrumb.route = '/circles';
    firstCrumb.state = { tabIndex };
  }

  breadCrumbs.push(firstCrumb);

  if (screenMode?.involvesConsent) {
    const consentModeUrl = `/${screenMode?.isCircle ? 'circles' : 'records'}/individual`;
    breadCrumbs.push({
      label: 'Consents',
      route: consentModeUrl,
      state: { tabIndex },
    });
    breadCrumbs.push({
      label: consentUserName,
      route: consentModeUrl,
      state: { tabIndex },
    });
  } else if (screenMode?.isCircle) {
    breadCrumbs.push({
      label: partnerInfo?.partnerName,
      route: `/circles/${partnerInfo?.partnerUrlParam}/${partnerInfo?.circleId}`,
      state: {
        partnerName: partnerInfo?.partnerName,
        partnerIconUrl: partnerInfo?.partnerIconUrl,
        partnerSourceUrl: partnerInfo?.partnerSourceUrl,
        tabIndex,
      },
    });
  }

  breadCrumbs.push({ label: pageTitle });

  return breadCrumbs;
}

export const getEAMErrorObject = (graphQLError) => {
  const error = getMessageFromGraphqlError(graphQLError);
  const splitErrorMessage = error && error.split(' - ');
  const errorObject = splitErrorMessage && JSON.parse(splitErrorMessage[1]);
  return errorObject || null;
};

export const isJsonString = (item) => {
  try {
    // eslint-disable-next-line no-param-reassign
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === 'object' && item !== null) {
    return true;
  }
  return false;
};

export const formatAddressString = (address) => {
  if (address) {
    const addressArray = address.split(',').filter((item) => item !== '');
    const filterNullValues = addressArray.filter((value) => value.trim() !== 'null');
    return filterNullValues?.join();
  }
  return null;
};

// Please start using date-fns and date-fns-tz library for any datetime related conversion
// Date conversions using date-fns and date-fns-tz library
export const getCommaSeparatedDate = (date) => {
  if (DATE_TO_BE_REPLACED_FOR_RECORDED_ON.includes(date)) {
    return DEFAULT_NULL_REPLACEMENT;
  }
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(new Date(date), timeZone, 'MMM d, y');
};
export const getCommaSeparatedDateWithFullMonth = (date) => {
  if (DATE_TO_BE_REPLACED_FOR_RECORDED_ON.includes(date)) {
    return DEFAULT_NULL_REPLACEMENT;
  }
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(new Date(date), timeZone, 'MMMM d, y');
};

export const carePlanCategory = (planDetails) => {
  const codingObj = planDetails?.category?.[0]?.coding?.[0];
  if (codingObj?.code && codingObj?.display) {
    return `${codingObj?.code}-${codingObj?.display}`;
  } else if (codingObj?.code) {
    return codingObj?.code;
  } else if (codingObj?.display) {
    return codingObj?.display;
  } else {
    return DEFAULT_NULL_REPLACEMENT;
  }
};

export const getCommaSeparatedDateWithTime = (date) =>
  format(new Date(date), 'MMM d, y, hh:mm aaa');

export const checkOneTimeUser = () =>
  JSON.parse(localStorage.getItem('consentUserDetails'))?.oneTimeUser;

export function getAnalyticsEventsForRouteName(routeName) {
  const analyticsEvents = routeName && CONSOLIDATED_EVENTS?.[routeName];
  if (!analyticsEvents) {
    throw new Error('Didnt find matching events');
  }
  return analyticsEvents;
}

export const handleCirclesAnalyticalEvents = (logAnalyticsEvent, analyticsEvents, title) => {
  switch (title) {
    case 'Conditions':
      logAnalyticsEvent(analyticsEvents.CIR_CONDITIONS_CLICKED);
      break;
    case 'Procedures':
      logAnalyticsEvent(analyticsEvents.CIR_PROCEDURES_CLICKED);
      break;
    case 'Lab results':
      logAnalyticsEvent(analyticsEvents.CIR_LAB_RESULTS_CLICKED);
      break;
    case 'Medications':
      logAnalyticsEvent(analyticsEvents.CIR_MEDICATIONS_CLICKED);
      break;
    case 'Allergies':
      logAnalyticsEvent(analyticsEvents.CIR_ALLERGIES_CLICKED);
      break;
    case 'Immunizations':
      logAnalyticsEvent(analyticsEvents.CIR_IMMUNIZATIONS_CLICKED);
      break;
    case 'Vitals':
      logAnalyticsEvent(analyticsEvents.CIR_VITALS_CLICKED);
      break;
    case 'Visits':
      logAnalyticsEvent(analyticsEvents.CIR_VISITS_CLICKED);
      break;
    case 'Social history':
      logAnalyticsEvent(analyticsEvents.CIR_SOCIAL_HISTORY_CLICKED);
      break;
    case 'Family history':
      logAnalyticsEvent(analyticsEvents.CIR_FAMILY_HISTORY_CLICKED);
      break;
    case 'Coverage':
      logAnalyticsEvent(analyticsEvents.CIR_COVERAGE_CLICKED);
      break;
    case 'Claims':
      logAnalyticsEvent(analyticsEvents.CIR_CLAIMS_CLICKED);
      break;
    case 'Bills':
      logAnalyticsEvent(analyticsEvents.CIR_BILLS_CLICKED);
      break;
    case 'Care plans':
      logAnalyticsEvent(analyticsEvents.CIR_CARE_PLANS_CLICKED);
      break;
    case 'Care teams':
      logAnalyticsEvent(analyticsEvents.CIR_CARE_TEAMS_CLICKED);
      break;
  }
};

export const handleAddSelfReportedButtonalyticalEvents = (logAnalyticsEvent, SERVICE) => {
  const SELF_REPORTED_CLICKED_V1 = {
    eventName: `SELF_REPORTED_CLICKED_V1_${SERVICE?.toUpperCase()}`,
    eventGroup: [],
    // eventGroup: 'ADD_SELF_REPORTED_BUTTON_CLICKED_V1',
  };
  logAnalyticsEvent(SELF_REPORTED_CLICKED_V1);
};

/**
 *
 * @param {Date} date ( Fri Apr 24 2020 16:09:28 GMT+0530/1703980799000 )
 * @returns  24 April, 2020
 */
export const formatUTCDate = (date) => formatInTimeZone(new Date(date), 'UTC', 'MMMM dd, yyyy');

// moved func here from Login.jsx
export function redirectAfterLogin(sessionState, refreshToken, accessToken) {

  const DEMO_CALLBACK_URL = "https://demo.mpoweredhealth.com/callback"
    if (window.location.hostname.startsWith('demo.mpowered')) {
        window.location.assign(
          DEMO_CALLBACK_URL +
            '?link=home#session_state=' +
            sessionState +
            '&id_token=' +
            refreshToken +
            '&access_token=' +
            accessToken +
            '&token_type=Bearer&expires_in=600',
        );
      } else {
        window.location.assign(
          process.env.SMILE_CALLBACK_URL +
            '?link=home#session_state=' +
            sessionState +
            '&id_token=' +
            refreshToken +
            '&access_token=' +
            accessToken +
            '&token_type=Bearer&expires_in=600',
        );
      }
    

}

export function isDependentProfileActiveFromCache() {
  return localStorage.getItem('dependentFhirId');
}

export function accountType(date, isDependent) {
  if (!isDependent) {
    return ACCOUNT_TYPE.PRIMARY;
  } else if (!date) {
    return ACCOUNT_TYPE.ADULT;
  }
  const today = new Date();
  const d = new Date(date);
  const year = today.getFullYear() - d.getFullYear();
  const month = today.getMonth() - d.getMonth();
  const dt = today.getDate() - d.getDate();
  if (year < 18) {
    return ACCOUNT_TYPE.MINOR;
  } else if (year > 18) {
    return ACCOUNT_TYPE.ADULT;
  } else if (month < 0) {
    return ACCOUNT_TYPE.MINOR;
  } else if (month > 0) {
    return ACCOUNT_TYPE.ADULT;
  } else if (dt < 0) {
    return ACCOUNT_TYPE.MINOR;
  } else {
    return ACCOUNT_TYPE.ADULT;
  }
}

export function findActiveProfile(isDependent, dependentAccounts) {
  return isDependent
    ? dependentAccounts?.find(
        (account) => account.dependentFhirId === isDependentProfileActiveFromCache(),
      )
    : {};
}

export const detectDeviceName = () => {
  let deviceType = '';
  if (isAndroid) {
    deviceType = DEVICE_TYPE.ANDROID;
  } else if (IOS) {
    deviceType = DEVICE_TYPE.IOS;
  } else {
    deviceType = DEVICE_TYPE.WEB;
  }
  return deviceType;
};

export const detectBrowserVersion = () => {
  return browserVersion;
};

export const getUserId = () => {
  return localStorage.getItem('userId');
};

export const removeDuplicateObject = (arr) => {
  const uniqueObject = {};
  const uniqueArray = [];
  for (const i in arr) {
    const objectValue = arr[i].description;
    uniqueObject[objectValue] = arr[i];
  }
  for (const i in uniqueObject) {
    uniqueArray.push(uniqueObject[i]);
  }
  return uniqueArray;
};

export const arrayOfObjectIntoObject = (arrayOfObjects) => {
  let object = { recordList: JSON.stringify([]), count: 0 };
  arrayOfObjects?.map((obj, index) => {
    const mergingObject = {
      recordList: [...JSON.parse(object?.recordList), ...JSON.parse(obj?.recordList)],
      count: object?.count + obj?.count,
    };
    object = { ...mergingObject, recordList: JSON.stringify(mergingObject?.recordList) };
    return null;
  });
  return object;
};

export const arrayOfObjectIntoSingleObject = (arrayOfObjects) => {
  const obj = {};
  for (let i = 0; i < arrayOfObjects.length; i++) {
    Object.assign(obj, arrayOfObjects[i]);
  }
  return obj;
};

export const getLabels = (arr) => {
  const labels = arr?.map((obj) => {
    if (obj.labelKey === 'condition') {
      return { conditionLabel: obj.label };
    } else if (obj.labelKey === 'date') {
      return { dateLabel: obj.label };
    } else if (obj.labelKey === 'reasonForHistory') {
      return { reasonForHistoryLabel: obj.label };
    } else {
      return { noteLabel: obj.label };
    }
  });
  return arrayOfObjectIntoSingleObject(labels);
};

export const firstLetterLowerCase = (resourcType, SERVICE_TYPE, multiAPICalls) =>
  multiAPICalls ? resourcType.slice(0, 1).toLowerCase() + resourcType.slice(1) : SERVICE_TYPE;

const objectExtracting = (obj, label) => {
  if (!label?.parseAssociatedResponseObject) {
    let val = null;

    objectPath.ensureExists(obj, label?.objectPath1, null);
    val = objectPath.get(obj, label?.objectPath1);
    if (!val) {
      objectPath.ensureExists(obj, label?.objectPath2, null);
      val = objectPath.get(obj, label?.objectPath2);
    }
    if (!val) {
      objectPath.ensureExists(obj, label?.objectPath3, null);
      val = objectPath.get(obj, label?.objectPath3);
    }

    return { value: val };
  }
  if(label?.desciptionPath === '-' ){
    const value = objectPath.get(obj, label?.objectPath);
    return { value: value };
  }
  objectPath.ensureExists(obj, label?.objectPath, null);
  const string = objectPath.get(obj, label?.objectPath);
  const object = JSON.parse(string);
  if (object) {
    objectPath.ensureExists(obj, label?.desciptionPath, null);
    const value = objectPath.get(object, label?.desciptionPath);
    objectPath.ensureExists(obj, label?.statusPath, null);
    const status = objectPath.get(object, label?.statusPath);
    return { value: value, status: status };
  } else {
    return null;
  }
};

export const dataExtracting = (resourceObj, label) => {
  objectPath.ensureExists(resourceObj, label?.arrayName, null);
  const arr = objectPath.get(resourceObj, label?.arrayName);
  const array = Array.isArray(arr)
    ? arr?.map((obj, index) => {
        return objectExtracting(obj, label);
      })
    : null;
  return array;
};

export const removeDuplicateValueFromArray = (arr) => {
  return arr?.filter((item, index) => arr?.indexOf(item) === index);
};

const statusColorFun = (status) => {
  return STATUS_COLOR_LIST?.map((item) => {
    if (item?.key?.toLowerCase() === status?.toLowerCase()) {
      return item?.color;
    }
    return null;
  }).filter((item) => item !== null)?.[0];
};

export const formatForTableGrid = (data, resourceType, statusFilters ) => {
  let recordsData = [];
  
  switch (resourceType) {
    case 'support_history':
      recordsData = data?.map((row, index) => {
        return {
          id: index,
          title: row?.title || 'Support history',
          extension: [
            {
              url: 'requestId',
              valueString: row?.requestId || `${index + 1}`,
            },
            { url: 'status', valueString: row?.status },
            { url: 'status_color', valueString: row?.status_color || statusColorFun(row?.status) },
            {
              url: 'resolvedBy',
              valueString: row?.resolvedBy,
            },
            {
              url: 'createdOn',
              valueString: row?.createdOn,
            },
            {
              url: 'category',
              valueString: row?.category,
            },
          ],
        };
      });
      break;
    case 'prior_authorization':
      recordsData = data?.map((row, index) => {
        return {
          id: index,
          title: row?.name || DEFAULT_NULL_REPLACEMENT,
          extension: [
            {
              url: 'name',
              valueString: row?.name,
            },
            {
              url: 'status',
              valueString: row?.status,
            },
            {
              url: 'status_color',
              valueString: row?.status_color || statusColorFun(row?.status),
            },
            {
              url: 'priority',
              valueString: row?.priority,
            },
            {
              url: 'requestDate',
              valueString: row?.requestDate,
            },
            {
              url: 'dueDate',
              valueString: row?.dueDate,
            },
          ],
        };
      });
      break;
    default:
      return recordsData;
  }

  if (!statusFilters || statusFilters.length === 0 || statusFilters.some(filter => filter.trim() === '' || filter.trim().toLowerCase() === 'all')) {
    return recordsData;
  }

  return recordsData.filter((record) => {
    const status = record.extension.find((ext) => ext.url === 'status')?.valueString.toLowerCase();
    if (statusFilters.includes('draft' || 'on-hold')) {
      return status === 'pending';
    } else {
      return statusFilters.includes(status);
    }
  });
};

export const prior_authorization_records = [
  {
    name: 'Excision of lesion of artery',
    status: 'Approved',
    priority: 'Routine',
    requestDate: 'Nov 07, 2011',
    dueDate: 'Jul 12, 2012',
  },
  {
    name: 'Echography of kidney',
    status: 'Pending',
    priority: 'Urgent',
    requestDate: 'Sep 22, 2021',
    dueDate: 'Sep 09, 2021',
  },
  {
    name: 'Anesthesia for procedure on thoracic esophagus',
    status: 'Rejected',
    priority: 'Asap',
    requestDate: 'April 05, 2016',
    dueDate: 'April 05, 2016',
  },
  {
    name: 'Echography of kidney',
    status: 'Pending',
    priority: 'Urgent',
    requestDate: 'Sep 07, 1997',
    dueDate: 'Sep 07, 1997',
  },
  {
    name: 'Triad knee repair',
    status: 'In Progress',
    priority: 'Routine',
    requestDate: 'April 05, 2016',
    dueDate: 'Sep 07, 2021',
  },
  {
    name: 'Excision of lesion of artery',
    status: 'Rejected',
    priority: 'Urgent',
    requestDate: 'Jan 10, 2020',
    dueDate: 'Jan 10, 2020',
  },
  {
    name: 'Excision of brain',
    status: 'Approved',
    priority: 'Asap',
    requestDate: 'Sep 09, 2021',
    dueDate: 'Sep 09, 2021',
  },
  {
    name: 'Echography of kidney',
    status: 'Approved',
    priority: 'Routine',
    requestDate: 'Dec 20, 2011',
    dueDate: 'Dec 20, 2011',
  },
  {
    name: 'Excision of brain',
    status: 'Approved',
    priority: 'Routine',
    requestDate: 'Feb 23, 2001',
    dueDate: 'Feb 23, 2001',
  },
  {
    name: 'Excision of brain',
    status: 'Approved',
    priority: 'Urgent',
    requestDate: 'Jun 21, 2021',
    dueDate: 'Jun 21, 2021',
  },
];

export const prior_authorization_view_details = [
  {
    name: 'Excision of lesion of artery',
    cptCode: 189009,
    requestingProvider: 'HARP SCOTT',
    status: 'Approved',
    requestPriority: 'Routine',
    requestDate: 'Nov 07, 2011',
    dueDate: 'Jul 12, 2012',
    serviceStartDate: 'Nov 07, 2011',
    serviceEndDate: 'Nov 07, 2011',
    diagnosis: 'Major depressive disorder',
    units: 1,
    priorAuthId: 'P101',
    document: '',
    notes: 'Approved ',
  },
  {
    name: 'Echography of kidney',
    cptCode: 306005,
    requestingProvider: 'HUSAIN SOHAI',
    status: 'Pending',
    requestPriority: 'Urgent',
    requestDate: 'Sep 22, 2021',
    dueDate: 'Sep 09, 2021',
    serviceStartDate: 'Sep 22, 2021',
    serviceEndDate: 'Sep 22, 2021',
    diagnosis: 'Injury of intercostal artery',
    units: 1,
    priorAuthId: 'P102',
    document: '',
    notes: 'Pending',
  },
  {
    name: 'Anesthesia for procedure on thoracic esophagus',
    cptCode: 435001,
    requestingProvider: 'SMITH DIANE',
    status: 'Rejected',
    requestPriority: 'Asap',
    requestDate: 'April 05, 2016',
    dueDate: 'April 05, 2016',
    serviceStartDate: 'April 05, 2016',
    serviceEndDate: 'April 05, 2016',
    diagnosis: 'Disease due to Filoviridae',
    units: 1,
    priorAuthId: 'P103',
    document: '',
    notes: 'Rejected ',
  },
  {
    name: 'Echography of kidney',
    cptCode: 306005,
    requestingProvider: 'ABRAHAM SHERIN',
    status: 'Pending',
    requestPriority: 'Urgent',
    requestDate: 'Sep 07, 1997',
    dueDate: 'Sep 07, 1997',
    serviceStartDate: 'Sep 07, 1997',
    serviceEndDate: 'Sep 07, 1997',
    diagnosis: 'Occipital headache',
    units: 1,
    priorAuthId: 'P104',
    document: '',
    notes: 'Pending',
  },
  {
    name: 'Triad knee repair',
    cptCode: 807005,
    requestingProvider: 'JANSEN BRIGITTA',
    status: 'In Progress',
    requestPriority: 'Routine',
    requestDate: 'April 05, 2021',
    dueDate: 'Sep 07, 2021',
    serviceStartDate: 'April 05, 2021',
    serviceEndDate: 'April 05, 2021',
    diagnosis: 'Absent tendon reflex',
    units: 1,
    priorAuthId: 'P105',
    document: '',
    notes: 'In Progress ',
  },
  {
    name: 'Excision of lesion of artery',
    cptCode: 189009,
    requestingProvider: 'HARP SCOTT',
    status: 'Rejected',
    requestPriority: 'Urgent',
    requestDate: 'Jan 10, 2020',
    dueDate: 'Jan 10, 2020',
    serviceStartDate: 'Jan 10, 2020',
    serviceEndDate: 'Jan 10, 2020',
    diagnosis: 'Tooth chattering',
    units: 1,
    priorAuthId: 'P106',
    document: '',
    notes: 'Rejected',
  },
  {
    name: 'Excision of brain',
    cptCode: 807005,
    requestingProvider: 'HUSAIN SOHAIL',
    status: 'Approved',
    requestPriority: 'Asap',
    requestDate: 'Sep 09, 2021',
    dueDate: 'Sep 09, 2021',
    serviceStartDate: 'Sep 09, 2021',
    serviceEndDate: 'Sep 09, 2021',
    diagnosis: 'Sycosis',
    units: 1,
    priorAuthId: 'P107',
    document: '',
    notes: 'Approved ',
  },
  {
    name: 'Echography of kidney',
    cptCode: 306005,
    requestingProvider: 'SMITH DIANE',
    status: 'Approved',
    requestPriority: 'Routine',
    requestDate: 'Dec 20, 2011',
    dueDate: 'Dec 20, 2011',
    serviceStartDate: 'Dec 20, 2011',
    serviceEndDate: 'Dec 20, 2011',
    diagnosis: 'Clinical finding',
    units: 1,
    priorAuthId: 'P108',
    document: '',
    notes: 'Approved',
  },
  {
    name: 'Excision of brain',
    cptCode: 807005,
    requestingProvider: 'ABRAHAM SHERIN',
    status: 'Approved',
    requestPriority: 'Routine',
    requestDate: 'Feb 23, 2001',
    dueDate: 'Feb 23, 2001',
    serviceStartDate: 'Feb 23, 2001',
    serviceEndDate: 'Feb 23, 2001',
    diagnosis: 'Clinical finding',
    units: 1,
    priorAuthId: 'P109',
    document: '',
    notes: 'Approved ',
  },
  {
    name: 'Excision of brain',
    cptCode: 189009,
    requestingProvider: 'JANSEN BRIGITTA',
    status: 'Approved',
    requestPriority: 'Urgent',
    requestDate: 'Jun 21, 2021',
    dueDate: 'Jun 21, 2021',
    serviceStartDate: 'Jun 21, 2021',
    serviceEndDate: 'Jun 21, 2021',
    diagnosis: 'Clinical finding',
    units: 1,
    priorAuthId: 'P110',
    document: '',
    notes: 'Approved',
  },
];

export const appointments = [
  {
    extension: [
      {
        url: 'recorded_on',
        valueString: 'Jan 6, 2024',
      },
      {
        url: 'time',
        valueString: '12:00 PM',
      },
      {
        url: 'provider',
        valueString: 'John Smith',
      },
      {
        url: 'type_of_visit',
        valueString: 'Virtual Visit',
      },
      { url: 'status', valueString: 'Booked' },
      { url: 'status_color', valueString: ' #007D32' },
      {
        url: 'facility',
        valueString: 'Absolute Dental Clinic',
      },
      {
        headerName: '',
        type: 'button',
        dataCol: 'View details',
      },
    ],
  },
  {
    extension: [
      {
        url: 'recorded_on',
        valueString: 'Jun 5, 2023',
      },
      {
        url: 'time',
        valueString: '5:30 PM',
      },
      {
        url: 'provider',
        valueString: 'Harp Scott',
      },
      {
        url: 'type_of_visit',
        valueString: 'Virtual Visit',
      },
      { url: 'status', valueString: 'Fulfilled' },
      { url: 'status_color', valueString: '#007D32' },
      {
        url: 'facility',
        valueString: 'Baptist Hospital',
      },
      {
        headerName: '',
        type: 'button',
        dataCol: 'View details',
      },
    ],
  },
  {
    extension: [
      {
        url: 'recorded_on',
        valueString: 'Sept 9, 2023',
      },
      {
        url: 'time',
        valueString: '2:00 PM',
      },
      {
        url: 'provider',
        valueString: 'Thomas Warren',
      },
      {
        url: 'type_of_visit',
        valueString: 'Virtual Visit',
      },
      { url: 'status', valueString: 'Proposed' },
      { url: 'status_color', valueString: '#FB8900' },
      {
        url: 'facility',
        valueString: 'Fit Smiles Oral Care',
      },
      {
        headerName: '',
        type: 'button',
        dataCol: 'View details',
      },
    ],
  },
  {
    extension: [
      {
        url: 'recorded_on',
        valueString: 'Jun 3, 2023',
      },
      {
        url: 'time',
        valueString: '11:30 AM',
      },
      {
        url: 'provider',
        valueString: 'Scott Allen',
      },
      {
        url: 'type_of_visit',
        valueString: 'Virtual Visit',
      },
      { url: 'status', valueString: 'Proposed' },
      { url: 'status_color', valueString: '#FB8900' },
      {
        url: 'facility',
        valueString: 'UCI Health',
      },
      {
        headerName: '',
        type: 'button',
        dataCol: 'View details',
      },
    ],
  },
];

export const isPropertyPresentInArrayOfObjects = (property, list) => {
  return list.some((item) => item.name === property);
};

export const getEquivalentObjectFromContext = (property, list) => {
  return list.find((obj) => obj.name === property);
};

export const getTodaysDate = () => {
  const tempDate = new Date();
  let currentDate = tempDate.getFullYear() + '-';
  if (tempDate.getMonth() + 1 < 10) {
    currentDate = currentDate + '0' + (tempDate.getMonth() + 1);
  } else {
    currentDate = currentDate + (tempDate.getMonth() + 1);
  }
  currentDate = currentDate + '-';
  if (tempDate.getDate() < 10) {
    currentDate = currentDate + '0' + tempDate.getDate();
  } else {
    currentDate = currentDate + tempDate.getDate();
  }
  return currentDate;
};

export const CONFIRMATION_MODAL = Object.freeze({
  VISIT_RESCHEDULE_SUCCESS_TEXT: 'Visit rescheduled successfully!',
  BUTTON_TEXT: {
    YES: 'Yes',
    NO: 'No',
    VIEW_DETAILS: 'View Details',
    CLOSE: 'Close',
  },
});

export const dateParse_appointment = (date) => {
  if (!date) {
    return '-';
  }
  const resultDate = new Date(date);

  let dateInString = '';

  if (resultDate instanceof Date && !isNaN(resultDate.getTime())) {
    dateInString = replace(DATE_FORMAT_APPOINTMENT, '<MMM>', months[resultDate.getMonth()]);
    dateInString = replace(dateInString, '<DD>', resultDate.getDate());
    dateInString = replace(dateInString, '<YYYY>', resultDate.getFullYear());
  }
  return dateInString;
};

export const APPOINTMENT_SCHEDULE_DETAILS = Object.freeze({
  HEADING: 'Visit rescheduled successfully!',
  CANCEL: 'Cancel Visit',
  QUESTION: 'Are you sure you want to cancel this visit?',
  DESCRIPTION:
    ' Your refund will be initiated just after the cancellation if you have made any payment. This may take upto 2 days to reflect refund balance in your account.',
});

export const formatViewDetails = (d, resourceType) => {
  const data = Array.isArray(d) ? d : [d];
  let formatted = [];

  switch (resourceType) {
    case 'support_history':
      formatted = data?.map((cols) => {
        return [
          {
            column_name: 'Status',
            value: cols?.status,
            status_color: cols?.status_color || statusColorFun(cols?.status),
            type: 'dot',
          },
          {
            column_name: 'Document name',
            value: [cols?.documentName || DEFAULT_NULL_REPLACEMENT],
            width: 'towthird',
            type: 'string',
            labelType: 'labelWithIcon',
            documentURL: cols.documentURL,
          },
          {
            column_name: 'Request type',
            value: 'SUPPORT' || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'User text',
            value: cols?.description || DEFAULT_NULL_REPLACEMENT,
            width: 'towthird',
            type: 'string',
          },

          {
            column_name: 'Resolved by',
            value: cols?.resolvedBy || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Comment',
            value: cols?.comments || DEFAULT_NULL_REPLACEMENT,
            width: 'towthird',
            type: 'string',
          },
          {
            column_name: 'Category',
            value: cols?.category || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
        ];
      });
      break;
    case 'prior_authorization':
      formatted = data?.map((cols) => {
        return [
          {
            column_name: 'Service(CPT code)',
            value: cols?.cptCode || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Requesting provider',
            value: cols?.requestingProvider || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Request priority',
            value: cols?.requestPriority || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Request date',
            value: cols?.requestDate || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Due date',
            value: cols?.dueDate || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Status',
            value: cols?.status,
            status_color: cols?.status_color || statusColorFun(cols?.status),
            width: '',
            type: 'dot',
          },
          {
            column_name: 'Service start date',
            value: cols?.serviceStartDate || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Service end date',
            value: cols?.serviceEndDate || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Diagnosis',
            value: cols?.diagnosis || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Units',
            value: cols?.units || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Prior auth ID',
            value: cols?.priorAuthId || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Document',
            value: cols?.document || DEFAULT_NULL_REPLACEMENT,
            width: '',
            type: 'string',
          },
          {
            column_name: 'Notes',
            value: cols?.notes || DEFAULT_NULL_REPLACEMENT,
            width: 'fullwidth',
            type: 'string',
          },
        ];
      });
      break;
    default:
      return formatted;
  }
  return formatted?.length ? formatted?.[0] : formatted;
};

const date = new Date();

export const dateList = [
  {
    message: 'A new document has been attached.',
    category: 'Today',
    createdAt: date.setHours(date.getHours() - 1),
    messageId: '',
    clickAction: '',
    alertType: '',
  },
  {
    message: 'Your service request is resolved.',
    category: 'Today',
    createdAt: date.setHours(date.getHours() - 1),
    messageId: '',
    clickAction: '',
    alertType: '',
  },
  {
    message: 'Your HRA assessment is due in 15 days.',
    category: 'Yesterday',
    createdAt: new Date(date.setDate(date.getDate() - 1)),
    messageId: '',
    clickAction: '',
    alertType: '',
  },
  {
    message: 'Your premium invoice is due in 15 days.',
    category: '3 days ago',
    createdAt: new Date(date.setDate(date.getDate() - 3)),
    messageId: '',
    clickAction: '',
    alertType: '',
  },
  {
    message: 'Your account details are updated.',
    category: '5 days ago',
    createdAt: new Date(date.setDate(date.getDate() - 5)),
    messageId: '',
    alertType: '',
  },
];
