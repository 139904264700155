import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState, useEffect, useContext } from 'react';
// import IconContainerTemplate from '../../components/templates/IconContainerTemplate';
import { Navigate } from 'react-router-dom';
import alert1 from '../../assets/images/bill_pay.svg';
import alert3 from '../../assets/images/calendar.svg';
import ShopNowImage from '../../assets/images/coverageImg.png';
import ReferFriendImage from '../../assets/images/ReferFriendImage.png';
import ShareRecordsIcon from '../../assets/images/ShareRecordsIcon.png';
import alert2 from '../../assets/images/ribbon.svg';
import NoDataCards from '../../components/organisms/NoDataCards';
import withUseWidth from '../../hooks/withUseWidth';
import AwardPointService from '../../service/AwardPoints';
import UserService from '../../service/User';
// import AwardPointsModal from '../../components/organisms/Modal';
import gql from 'graphql-tag';
import Loader from '../../components/atoms/Loader';
import { AlertCountContext } from '../../components/RouterWrapper';
import AlertService from '../../service/Alerts';
import ConsentService from '../../service/ConsentService';
import {
  CONSENT_TYPES,
  // MODAL,
  Home_DASHBOARD,
  NON_ACTIVE_SERVICE_POP_UP,
  OK,
} from '../../utils/constants';
import {
  fireHanselEvent,
  getAllHomeServices,
  getAnalyticsEventsForRouteName,
  getIndexOfObjInNestesObjList,
  getProcessedServices,
} from '../../utils/helper';
// import TermsPolicyAcceptanceModal from '../../components/organisms/TermsPolicyAcceptanceModal';
import { Grid } from '@mui/material';
import AddPartner from '../../assets/images/Addpartner1.svg';
import PolicyDialog from '../../components/molecules/PolicyDialog';
import ConfirmationDialog from '../../components/organisms/Modal';
import withRouter from '../../components/withRoute';
import RecentServices from '../../service/RecentServices';
import RecentlyVisitedCard from './RecentlyVisitedCard';
import { ROUTE_NAMES } from '../../constants/routeNames';
import ProfileAvatar from '../../assets/images/defaultAvatarBlack.svg';
import { useNavigate } from 'react-router-dom';
import useAnalyticsLogger from '../../hooks/useAnalyticsLogger';
import useClickFooter from '../../hooks/useClickFooter';
import { UserInfoContext } from '../../components/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { updateThroughAutoLogin } from '../../store/features/signupSlice';

//DO-NOT-REMOVE
// import { isIOS } from '../../utils/helper';

export const GET_FIRST_LOGIN_STATUS = gql`
  query {
    isUserVerified(field: "FIRST_LOGIN") {
      verificationField
      status
    }
  }
`;

export const UPDATE_LOGIN_STATUS = gql`
  mutation {
    markFirstLogin {
      verificationField
      status
    }
  }
`;

export const GET_ALERTS_COUNT = gql`
  query {
    notificationCount {
      notificationCountList {
        alertType
        count
      }
    }
  }
`;
export const GET_UNREAD_ALERTS = gql`
  query {
    unreadAlerts {
      alertList {
        alertType
        messageId
      }
    }
  }
`;

const MARK_ALL_ALERTS = gql`
  mutation markNotificationRead($messageIdList: [String]) {
    markNotificationRead(messageIdList: $messageIdList) {
      alertList {
        notificationStatus
      }
    }
  }
`;

const PROFILE_QUERY = gql`
  query {
    fetchProfileDetails {
      basicProfileDetails {
        firstName
        lastName
        userId
        preferredName
        dob
        gender
        bloodGroup
        avatarUrl
      }
      contactProfileDetails {
        addressList {
          city
          state
          zipCode
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

const GET_TRANSACTION_CODE = gql`
  query {
    getTransactionCodes
  }
`;

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    width: '100%',
  },
  content: {
    width: '100%',
    padding: '32px',
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      marginLeft: '0%',
      padding: '32px',
    },
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#f5f5f5',
  },
  heading: {
    marginBottom: '16px',
    marginTop: '32px',
    fontSize: '18px',
    letterSpacing: '0px',
    lineHeight: '24px',
    fontWeight: 600,
    fontFamily: 'Inter',
    color: '#344054',
  },
  helper: {
    marginBottom: '16px',
    lineHeight: '18px',
    ...theme.customComponent.body1Regular,
  },
  subHeading: {
    lineHeight: '32px',
    fontSize: '24px',
    color: 'black',
    ...theme.customComponent.h4Black,
  },
  line: {
    borderRadius: '5px 0 0',
    marginBottom: '20px',
    borderWidth: '0.4px',
    color: 'transparent',
  },
  card: {},
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
  },
  iconContainer: {
    marginBottom: '25px',
  },
  alertContainer: {
    marginLeft: '9px',
    marginBottom: '25px',
    marginTop: '29px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  alertText: {
    maxWidth: 255,
    marginLeft: '15px',
  },
  iconImage: {
    height: '40px',
    width: '40px',
    backgroundColor: '#003A64',
    borderRadius: 24,
  },
  thumbnailImage: {
    height: '40px',
    width: '40px',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 14,
    paddingBottom: '22px',
  },
  servicesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '24px',
  },
  addPartnerImageClass: {
    alignSelf: 'flex-end',
  },
  addPartnerTextClass: {
    // marginTop: 24,
    // marginBottom: 8,
    // paddingRight: 24,
  },
  addPartnerBtnClass: {
    marginTop: '16px',
    fontWeight: 500,
  },
  referAFriendImageClass: {
    alignSelf: 'flex-end',
    width: '78.5px',
    height: '74px',
    marginLeft: '-6px',
  },
  getSecondOpinionImageClass: {
    alignItems: 'flex-end',
  },
  shopForCoverageImageClass: {
    alignSelf: 'center',
    width: '70px',
    height: '70px',
  },
  textClass: {},
  buttonClass: {},
  welcomeCardWrapper: {
    marginBottom: '32px',
  },
  welcomeHeading: {
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '40px',
    color: '#344054',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0px',
  },
  welcomeCardContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '16px',
    gap: '8px',
    width: 'max-content',
    boxSizing: 'border-box',
    marginTop: '32px',
    minWidth: '324px',
  },
  welcomeCardImgWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeCardImg: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  welcomeCardNameWrapper: {},
  welcomeCardUserName: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '36px',
    color: '#344054',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0px',
  },
  welcomeCardUserStatus: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0px',
  },
  initial: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    backgroundColor: '#036FCB',
    color: '#FFFFFF',
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const HomeDashboard = ({ classes, ...props }) => {
  const [firstName, setFirstName] = useState('');
  const [newUser, setNewUser] = useState(true);
  const [secondOpinionDashboardRedirect, setSecondOpinionDashboardRedirect] = useState(false);
  const [scanBillRedirect, setScanBillRedirect] = useState(false);
  const [referRedirect, setReferRedirect] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [billSummaryRedirect, setBillSummaryRedirect] = useState(false);
  const [onBoarding, setOnBoarding] = useState(false);
  const [isFirstLoginStatusLoading, setIsFirstLoginStatusLoading] = useState(false);
  const [alertMessageIdsObj, setAlertMessageIdsObj] = useState([]);
  const [newPrivacyPolicy, setNewPrivacyPolicy] = useState('');
  const [newTermsAndConditionsPolicy, setNewTermsAndConditionsPolicy] = useState('');
  const [modalData, setModalData] = useState('');
  const [showAcceptanceModal, setShowAcceptanceModal] = useState(false);
  const [isRecentServicesLoading, setIsRecentServicesLoading] = useState(false);
  const [recentServicesList, setRecentServicesList] = useState([]);
  const [recentServicesListWithAlerts, setRecentServicesListWithAlerts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentConsentType, setCurrentConsentType] = useState('');
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { alertsCount, setAlertsCount } = useContext(AlertCountContext);

  const dispatch = useDispatch();
  const { throughAutoLogin } = useSelector((state) => state.signup);

  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.HOME);
  const { logAnalyticsEvent } = useAnalyticsLogger();
  useClickFooter(props.onFooterItemClick);

  const fetchRecentOpenedServices = async () => {
    try {
      setIsRecentServicesLoading(true);
      const result = await RecentServices.fetchRecentlyUsedService();
      const filteredList = result.filter(
        (obj) => obj.service && obj.service.serviceType !== 'second_opinion',
      );
      const homeDashBoardServices = getAllHomeServices(filteredList);
      setRecentServicesList(getProcessedServices(homeDashBoardServices));
    } catch (error) {
      console.error('Error in fetching recent services: ', error);
    } finally {
      setIsRecentServicesLoading(false);
    }
  };

  useEffect(() => {
    fetchAlertsCount();
  }, [recentServicesList]);

  useEffect(() => {
    setIsFirstLoginStatusLoading(true);
    fetchTermsConditionStatus();
    if (throughAutoLogin) {
      logAnalyticsEvent(analyticsEvents.AUTOLOGIN_SUCCESS_AFTER_SIGNUP);
      dispatch(updateThroughAutoLogin(false));
    }
    try {
      fetchFirstLoginStatus();
      fetchRecentOpenedServices();
      if (onBoarding) {
        Hansel.getUser().putAttribute('partner_count', '0');
      }
      fireHanselEvent('MPH_HOME_PAGE_LOAD', {});
      getPromoCode();
    } catch (e) {
      console.log('Error in fetching RecentOpenedServices/UserInfo', e);
    }
  }, []);

  const getPromoCode = () => {
    AwardPointService.getTransactionCodes(GET_TRANSACTION_CODE)
      .then((response) => {
        Hansel.getUser().putAttribute('promo_code', response ? response : String(null));
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const seggregateAlertMessageIds = () => {
    AlertService.getUnreadAlertsCount(GET_UNREAD_ALERTS)
      .then((res) => {
        const alertMessageIdsObj = {
          second_opinion: [],
          bill_pay: [],
          award_point: [],
        };

        const unreadAlertList = res.unreadAlerts.alertList;
        unreadAlertList &&
          unreadAlertList.forEach((alertObj) => {
            if (alertMessageIdsObj[alertObj.alertType]) {
              alertMessageIdsObj[alertObj.alertType].push(alertObj.messageId);
            }
          });
        setAlertMessageIdsObj(alertMessageIdsObj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAlertsCount = () => {
    const updatedServiceListWithNotifiationCount = recentServicesList;
    setIsRecentServicesLoading(true);
    AlertService.getUnreadAlertsCount(GET_ALERTS_COUNT)
      .then((res) => {
        setIsRecentServicesLoading(false);
        const alertsCountList = res.notificationCount.notificationCountList;
        let totalCount = 0;
        alertsCountList.forEach((obj) => {
          totalCount += obj.count;
          const serviceListindex = getIndexOfObjInNestesObjList(
            recentServicesList,
            'serviceType',
            obj.alertType,
            'service',
          );
          if (serviceListindex !== -1) {
            updatedServiceListWithNotifiationCount[serviceListindex].notifications = obj.count;
          }
        });
        setRecentServicesListWithAlerts(updatedServiceListWithNotifiationCount);
        setAlertsCount(totalCount);
        seggregateAlertMessageIds();
      })
      .catch(() => {
        setIsRecentServicesLoading(false);
        setAlertsCount(0);
      });
  };
  const updateLoginStatus = () => {
    UserService.markLoginStatus(UPDATE_LOGIN_STATUS);
  };

  const fetchFirstLoginStatus = () => {
    UserService.isPhoneVerified(GET_FIRST_LOGIN_STATUS)
      .then((result) => {
        if (result) {
          updateLoginStatus();
        }
        setIsFirstLoginStatusLoading(false);
        Hansel.getUser().putAttribute('first_login', result);
      })
      .catch((error) => {
        setIsFirstLoginStatusLoading(false);
      });
  };

  const fetchTermsConditionStatus = async () => {
    try {
      const result = await ConsentService.fetchAllConsents();
      if (result.length > 0) {
        const newPrivacyPolicyObj = result.find(
          (obj) => obj['consentType'] === CONSENT_TYPES.PRIVACY_POLICY,
        );
        const newTermsAndConditionsObj = result.find(
          (obj) => obj['consentType'] === CONSENT_TYPES.TERMS_CONDITION,
        );
        if (newPrivacyPolicyObj && newTermsAndConditionsObj) {
          setNewPrivacyPolicy(newPrivacyPolicyObj.htmlContent);
          setNewTermsAndConditionsPolicy(newTermsAndConditionsObj.htmlContent);
          setShowAcceptanceModal(true);
          setModalData(newPrivacyPolicyObj.htmlContent);
          setCurrentConsentType(CONSENT_TYPES.PRIVACY_POLICY);
        } else if (newPrivacyPolicyObj) {
          setNewPrivacyPolicy(newPrivacyPolicyObj.htmlContent);
          setShowAcceptanceModal(true);
          setModalData(newPrivacyPolicyObj.htmlContent);
          setCurrentConsentType(CONSENT_TYPES.PRIVACY_POLICY);
        } else if (newTermsAndConditionsObj) {
          setNewTermsAndConditionsPolicy(newTermsAndConditionsObj.htmlContent);
          setShowAcceptanceModal(true);
          setModalData(newTermsAndConditionsObj.htmlContent);
          setCurrentConsentType(CONSENT_TYPES.TERMS_CONDITION);
        }
      }
    } catch (error) {
      console.log('Error in fetching terms and condition status: ', error);
    }
  };

  const handlePolicyAccept = async () => {
    try {
      await ConsentService.acceptConsent(currentConsentType);
      if (currentConsentType === CONSENT_TYPES.PRIVACY_POLICY && newTermsAndConditionsPolicy) {
        setModalData(newTermsAndConditionsPolicy);
        setShowAcceptanceModal(true);
        setCurrentConsentType(CONSENT_TYPES.TERMS_CONDITION);
      } else {
        setModalData('');
        setShowAcceptanceModal(false);
      }
    } catch (error) {
      console.warn('error in accepting new terms and condition: ', error);
    }
  };

  const handleTermsPolicyCancel = () => {
    setShowAcceptanceModal(false);
    navigate('/logout');
  };

  /* 
  @DO-NOT-REMOVE
  handleUploadBtnClick = (event) => {
      if (isIOS()) {
          const inputEl = document.getElementById('ios-input-camera');
          if (inputEl) {
              inputEl.click();
          }
      } else {
          this.setState({ scanBillRedirect: true });
      }
  };
  */

  const handleRequestSecondOpinionBtnClick = () => {
    navigate('/proceedtosecondopinion');
  };

  const handleBuyCoverageBtnClick = () => {
    logAnalyticsEvent(analyticsEvents.SHOP_FOR_COVERAGE_BTN_CLKD);
    navigate('/records/coverageSummary/shopNow');
  };

  const handleCirclesBtnClick = () => {
    logAnalyticsEvent(analyticsEvents.HOME_ACCESS_RECORD_BTN_CLKD);
    navigate('/circles/showPartner');
  };

  const handleReferBtnClick = () => {
    logAnalyticsEvent(analyticsEvents.REFER_FRIEND_BTN_CLKD);
    setReferRedirect(true);
  };

  const markAlertsAsRead = (alertMessageIdList) => {
    AlertService.markAlertsAsRead(MARK_ALL_ALERTS, alertMessageIdList).then((res) => {
      setAlertsCount(alertsCount - alertMessageIdList.length);
    });
  };

  const handleIconClick = (link, serviceType) => {
    const msgIdList = alertMessageIdsObj[serviceType];

    if (msgIdList && msgIdList.length) {
      markAlertsAsRead(msgIdList);
    }
    navigate(link);
  };

  const handleModalBtnClick = () => {
    setBillSummaryRedirect(true);
  };

  const handleOnCloseOnBoarding = () => {
    setOnBoarding(false);
  };

  const onIconClick = (link, serviceType) => {
    if (link) {
      handleIconClick(link, serviceType);
    } else {
      setIsOpen(true);
    }
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleShareRecords = () => {
    navigate('/records/consents?category=given', { state: { shareRecords: true } });
  };

  const renderPrimaryProfileCard = () => (
    <div className={classes.welcomeCardContentWrapper}>
      <div className={classes.welcomeCardImgWrapper}>
        {userInfo?.avatarUrl ? (
          <img
            src={userInfo?.avatarUrl}
            alt="user_img"
            className={classes.welcomeCardImg}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ProfileAvatar;
            }}
          />
        ) : (
          <Typography className={classes.initial}>
            {userInfo?.firstName?.[0]?.toUpperCase()}
          </Typography>
        )}
      </div>
      <div className={classes.welcomeCardNameWrapper}>
        <Typography className={classes.welcomeCardUserName}>
          {`${userInfo?.firstName} ${userInfo?.lastName}'s profile`}
        </Typography>
        <Typography className={classes.welcomeCardUserStatus}>{userInfo?.relationship}</Typography>
      </div>
    </div>
  );

  const renderDependentProfileCard = () => (
    <div className={classes.welcomeCardContentWrapper}>
      <div className={classes.welcomeCardImgWrapper}>
        {userInfo?.activeProfile?.basicProfileDetails?.avatarUrl ? (
          <img
            src={userInfo?.activeProfile?.basicProfileDetails?.avatarUrl}
            alt="user_img"
            className={classes.welcomeCardImg}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ProfileAvatar;
            }}
          />
        ) : (
          <Typography className={classes.initial}>
            {userInfo?.activeProfile?.basicProfileDetails?.firstName?.[0]?.[0]?.toUpperCase()}
          </Typography>
        )}
      </div>
      <div className={classes.welcomeCardNameWrapper}>
        <Typography className={classes.welcomeCardUserName}>
          {`${userInfo?.activeProfile?.basicProfileDetails?.firstName} ${userInfo?.activeProfile?.basicProfileDetails?.lastName}'s profile`}
        </Typography>
        <Typography className={classes.welcomeCardUserStatus}>
          {userInfo?.activeProfile?.relationship}
        </Typography>
      </div>
    </div>
  );

  if (isFirstLoginStatusLoading || isRecentServicesLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.container}>
      <PolicyDialog
        open={showAcceptanceModal}
        onAgree={() => handlePolicyAccept()}
        content={modalData}
        consentType={currentConsentType}
        rawHTML
      />
      {billSummaryRedirect && (
        <Navigate
          to={{
            pathname: '/bill',
          }}
        />
      )}
      {referRedirect && (
        <Navigate
          path="/home"
          to={{
            pathname: '/utilities/refer',
          }}
        />
      )}
      {/* {
                        (localStorage.getItem('loginCount') <= 2) &&
                        <AwardPointsModal
                            heading={MODAL.GREETING.replace('(firstname)', firstName)}
                            helperImage={reward}
                            messageList={`${MODAL.SUBTEXT_ONE},1000 ${MODAL.SUBTEXT_TWO}`.split(',')}
                            closeIcon
                            isOpen={openModal}
                            onClose={this.handleModalClose}
                            buttonText={newUser ? MODAL.BTN_TITLE_ONE : MODAL.BTN_TITLE_TWO}
                            onConfirm={this.handleModalBtnClick}
                            btnSize='large'
                        />
                    } */}
      <div className={classes.root}>
        {
          //DO-NOT-REMOVE
        }
        {/* { && (
                        <Redirect to={{
                            pathname: '/scanbill',
                            state: { activeFooterId: 0 }
                        }} />
                    )} */}
        <div className={classes.content}>
          {newUser ? (
            <React.Fragment>
              {/* <Typography variant="h3"
                                        className={classes.heading} >
                                        {Home_DASHBOARD.GREETING.replace('(firstname)', firstName)}
                                    </Typography> */}
            </React.Fragment>
          ) : (
            <Typography
              variant="h3"
              className={classes.heading}
              style={newUser ? null : { marginBottom: '41px' }}
            >
              {Home_DASHBOARD.HEADING}
            </Typography>
          )}
          {userInfo && (
            <div className={classes.welcomeCardWrapper}>
              <Typography className={classes.welcomeHeading}>Welcome!</Typography>
              {userInfo?.dependentProfiles?.length
                ? userInfo?.isDependent
                  ? renderDependentProfileCard()
                  : renderPrimaryProfileCard()
                : null}
            </div>
          )}
          <Typography variant="h3" className={classes.heading}>
            {Home_DASHBOARD.HEADING}
          </Typography>
          {recentServicesListWithAlerts.length ? (
            <Grid container className={classes.servicesContainer}>
              {recentServicesListWithAlerts &&
                recentServicesListWithAlerts.map((value, index) => {
                  const { serviceType, icon, thumbnail, borderColor, serviceName, screenPathWeb } =
                    value.service;
                  return (
                    <Grid item key={index}>
                      <RecentlyVisitedCard
                        icon={thumbnail}
                        link={screenPathWeb}
                        caption={serviceName}
                        analyticsEvents={analyticsEvents}
                        logAnalyticsEvent={props.logAnalyticsEvent}
                        // bgColor={INITIALS_BG_COLOR[index]}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            ''
          )}
          {newUser ? (
            <React.Fragment>
              <Typography variant="h3" className={classes.heading}>
                {Home_DASHBOARD.RECOMMANDED}
              </Typography>
              <div className={classes.cardContainer}>
                <div className={classes.card}>
                  <NoDataCards
                    text={Home_DASHBOARD.ACTION_CARD[4].TITLE}
                    btnTitle={Home_DASHBOARD.ACTION_CARD[4].BTN_TXT}
                    onClick={handleCirclesBtnClick}
                    onMouseEnter={() => {
                      fireHanselEvent('MOUSE_OVER_ACCESS_YOUR_RECORDS', {});
                    }}
                    src={AddPartner}
                    // variant="contained"
                    imgClass={classes.addPartnerImageClass}
                    textClass={classes.addPartnerTextClass}
                    btnClass={classes.addPartnerBtnClass}
                  />
                </div>

                {!userInfo?.isDependent && (
                  <div className={classes.card}>
                    <NoDataCards
                      text={Home_DASHBOARD.ACTION_CARD[3].TITLE}
                      btnTitle={Home_DASHBOARD.ACTION_CARD[3].BTN_TXT}
                      onClick={handleReferBtnClick}
                      onMouseEnter={() => {
                        fireHanselEvent('MOUSE_OVER_REFER_FRIEND', {});
                      }}
                      src={ReferFriendImage}
                      imgClass={classes.referAFriendImageClass}
                      textClass={classes.textClass}
                      buttonClass={classes.buttonClass}
                    />
                  </div>
                )}

                {!userInfo?.isDependent && (
                  <div className={classes.card}>
                    <NoDataCards
                      text={Home_DASHBOARD.ACTION_CARD[5].TITLE}
                      btnTitle={Home_DASHBOARD.ACTION_CARD[5].BTN_TXT}
                      onClick={handleShareRecords}
                      src={ShareRecordsIcon}
                      imgClass={classes.addPartnerImageClass}
                      textClass={classes.textClass}
                      buttonClass={classes.buttonClass}
                    />
                  </div>
                )}
                <div className={classes.card}>
                  <NoDataCards
                    text={Home_DASHBOARD.ACTION_CARD[0].TITLE}
                    btnTitle={Home_DASHBOARD.ACTION_CARD[0].BTN_TXT}
                    onClick={handleBuyCoverageBtnClick}
                    onMouseEnter={() => {
                      fireHanselEvent('MOUSE_OVER_SHOP_NOW_CARD', {});
                    }}
                    src={ShopNowImage}
                    imgClass={classes.shopForCoverageImageClass}
                    textClass={classes.textClass}
                    buttonClass={classes.buttonClass}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="h3" className={classes.heading}>
                {Home_DASHBOARD.YOUR_ALERTS}{' '}
              </Typography>
              <div className={classes.alertContainer}>
                <img src={alert1} />
                <Typography variant="h4" className={classes.alertText}>
                  Bill is <b>due</b> by <b>9 May, 2019</b>
                </Typography>
              </div>
              <div className={classes.alertContainer}>
                <img src={alert2} />
                <Typography variant="h4" className={classes.alertText}>
                  Congrats! You earned <b>445 MPowered award points</b>
                </Typography>
              </div>
              <div className={classes.alertContainer}>
                <img src={alert3} />
                <Typography variant="h4" className={classes.alertText}>
                  You have an appointment with <b>Dr.Bill James</b> on <b>15 May,2019</b>
                </Typography>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <ConfirmationDialog
        heading={NON_ACTIVE_SERVICE_POP_UP.HEADING}
        subheading={NON_ACTIVE_SERVICE_POP_UP.SUBHEADING}
        buttonText={OK}
        isOpen={isOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogClose}
      />
    </div>
  );
};

export default withRouter(withUseWidth(withStyles(styles, { withTheme: true })(HomeDashboard)));
