import React from 'react';
import { Box, Typography } from '@mui/material';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { DrawerBoxComponent } from './DrawerBoxComponent';
import SecondaryBtnComponent from './SecondaryBtnComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

export const AppointmentVisitDetailsSummary = ({ showEditButton }) => {
  return (
    <DrawerBoxComponent>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pr: 2,
          }}
        >
          <Typography variant="h3" sx={{ pr: 2 }}>
            Visit Details
          </Typography>
          <Typography variant="p" display="inline">
            Virtual visit
          </Typography>
          <Typography variant="h5" display="inline">
            <StatusIcon
              htmlColor="black"
              sx={{
                height: 10,
                width: 10,
                mr: 0.5,
                ml: 2,
              }}
            />
            {''}
          </Typography>
        </Box>
        {showEditButton ? <SecondaryBtnComponent /> : null}
      </Box>
    </DrawerBoxComponent>
  );
};
