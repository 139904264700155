import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { clsx } from 'clsx';
import { formatDateInMMDDYYYYWithComma } from '../../../../../utils/helper';
import CustomStaticDatePicker from '../../../../../components/molecules/CustomStaticDatePicker';
import { Typography } from '@mui/material';

const useStyle = makeStyles((theme) => ({
  container: {
    minWidth: 160,
    marginRight: '12px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    paddingRight: '12px',
    borderRadius: 8,
    backgroundColor: '#fff',
    // boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      paddingRight: '6px',
    },
  },
  dateWrapper: {
    position: 'absolute',
    top: (props) => `${props.options?.length * 40}px`,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: (props) => `${(props.options?.length + 2) * 40}px`,
    },
    left: '100%',
    zIndex: 999,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  filterType: {
    paddingRight: '8px',
    minWidth: 'max-content',
    fontSize: '14px',
    fontWeight: 600,
    color: '#737373',
  },
  selectedItem: {
    padding: 0,
    margin: 0,
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    fontSize: '13px',
    fontWeight: 600,
    letterSpacing: 0.6,
    fontFamily: 'Inter',
    flex: 1,
    color: 'rgba(0,0,0,0.9)',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
      paddingRight: '12px',
    },
  },
  arrow: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: '#737373',
    '&:svg': {
      fontSize: '18px',
      fontWeight: 500,
    },
  },
  menuListWrapper: {
    position: 'absolute',
    width: '80%',
    background: '#FFF',
    zIndex: 999,
    borderRadius: '6px',
    border: 'solid 1px #dfd9d9',
    right: 0,
    transition: 'all 3s',
    top: '32px',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
  },
  menuItem: {
    padding: '10px 16px',
    boxSizing: 'border-box',
    fontSize: '13px',
    fontWeight: 700,
    letterSpacing: '0px',
    fontFamily: 'Inter',
    cursor: 'pointer',
    color: '#343434',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
}));

const CustomDateFilter = (props) => {
  const { handleChange, filterItem, filterContainerClass } = props;
  const classes = useStyle(props);
  const { options, label, defaultActiveIndex, width } = filterItem;

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(options[defaultActiveIndex]);
  const [customDate, setCustomDate] = useState(new Date());

  const menuItemRef = useRef(null);
  const dateRef = useRef(null);

  const handleOpen = () => {
    dateRef.current.style.display = 'none';
    setOpen((prev) => !prev);
  };
  const handleSelect = (item) => {
    if (item === 'custom_date') {
      return;
    }
    setSelectedItem(item);
    handleChange({
      target: { value: item?.value, name: 'dateFilter' },
    });
    setOpen(false);
    dateRef.current.style.display = 'none';
  };
  const handleDateSelect = (selectedDate) => {
    handleChange({ target: { value: selectedDate?.$d, name: 'dateFilter' } });
    setCustomDate(selectedDate);
    setOpen(false);
    setSelectedItem({
      label: formatDateInMMDDYYYYWithComma(selectedDate?.$d),
      value: selectedDate,
    });
  };

  const handleMouseEnterInCustomDate = () => {
    dateRef.current.style.display = 'block';
  };
  const handleMouseEnterInOtherItems = () => {
    dateRef.current.style.display = 'none';
  };

  return (
    <div className={[classes.container, filterContainerClass].join(' ')} style={{ width: width }}>
      <div className={classes.wrapper} onClick={handleOpen}>
        <div className={classes.filterType}>{label || ''}</div>
        <div className={classes.selectedItem}>{selectedItem?.label}</div>
        <div className={classes.arrow}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</div>
      </div>
      {open && (
        <div className={classes.menuListWrapper}>
          {options?.map((item, i) => (
            <Typography
              className={clsx([classes.menuItem])}
              key={i}
              onClick={() => handleSelect(item)}
              onMouseOver={handleMouseEnterInOtherItems}
              style={
                selectedItem?.value === item.value
                  ? { backgroundColor: 'rgba(3, 111, 203, 0.2)' }
                  : {}
              }
            >
              {item.label}
            </Typography>
          ))}
          <div
            className={clsx([classes.menuItem])}
            key={i}
            onClick={() => handleSelect('custom_date')}
            onMouseEnter={handleMouseEnterInCustomDate}
            ref={menuItemRef}
          >
            <DateRangeIcon style={{ color: '#1F1F1F', fontSize: 14, marginRight: '8px' }} />
            Custom
          </div>
        </div>
      )}
      <CustomStaticDatePicker
        rootClass={classes.dateWrapper}
        customDate={customDate}
        handleDateSelect={handleDateSelect}
        ref={dateRef}
      />
    </div>
  );
};

export default CustomDateFilter;
